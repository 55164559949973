import React,{useRef,useState,useEffect} from 'react'
import { NavLink,withRouter} from "react-router-dom";
import axios from 'axios';
import {API} from '../../../common';
import Validator from "validatorjs";
import TextField from '@material-ui/core/TextField';
import UseFormStyles from './UseFormStyles';
import Paper from '@material-ui/core/Paper';
import headLogo from '../common/assets/ITW-logo-small.png'
import '../common/CSS/style.css'

const CreateDataEntryOperator = (props) => {

    const [errors, setErrors] = useState([]);
    const inputLabel = useRef(null);

    const classes = UseFormStyles();
    const [values, setValues] = useState({
      first_name:'',
      last_name:'',
      mobile:'',
      email:'',
      password:'',
      confirm_password:'',
    });
    useEffect(() => {
      setValues(props.data);
    }, [props.data]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    
  
  const validateForm = () => {
    
    let temp = { ...errors }

      let data = {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile:values.mobile,
        email:values.email,
        password:values.password,
        confirm_password:values.confirm_password
      };
    
      let rules;

      if (props.data.id)
      {
        rules = {
          first_name: "required",
          last_name: "required",
          mobile:"required|size:10",
          email:"required|email",
          
        };

      }else {
        rules = {
          first_name: "required",
          last_name: "required",
          mobile:"required|size:10",
          email:"required|email",
          password:"required",
          confirm_password:"required|same:password",
        };
      }
      
      
      let validation = new Validator(data, rules, {
        "required.first_name": "First name is required.",
        "required.last_name": "Last name is required.",
        "required.mobile": "Mobile number is required.",
        "required.email": "Email is required.",
        "required.password": "Password is required.",
        "required.confirm_password": "Confirm password is required.",

      });

      if (validation.passes()) {
        return true;
      }
      if (validation.fails()) {
        temp.first_name = validation.errors.first("first_name");
        temp.last_name = validation.errors.first("last_name");
        temp.mobile = validation.errors.first("mobile");
        temp.email = validation.errors.first("email");
        temp.password = validation.errors.first("password");
        temp.confirm_password = validation.errors.first("confirm_password");
        
        setErrors({
              ...temp
        })

        //alert(temp.first_name);
      }

  }


    const formSubmit = async (e) => {
  
       
       if(validateForm())
       {

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token"),
          },
        };
        const apiAdd = API + "users/data_entry_add";
        const apiUpdate = API + "users/data_entry_update";

          if (props.data.id) 
          {

            const updateDataEntryOperator =  async() => {

                  var postData = {
                    "id"  : values.id,
                    "first_name":values.first_name,
                    "last_name":values.last_name,
                    "mobile":values.mobile,
                    "email":values.email,
                    "password":values.password,
                    "confirm_password":values.confirm_password,
                    "country_id":"1",
                 };
                 await axios.post(apiUpdate,postData,{axiosConfig}).then(response => response.data)
                .then((responseData)=>{

                    if(responseData.Status===false)
                    {
                        alert(responseData.Message);
                    }else {
                        alert(responseData.Message);
                        props.history.push({
                            pathname: "/data-entry/list",
                          });
                    }
                });
            }
            updateDataEntryOperator();

          } else{

              const insertDataEntryOperator =  async() => {
                var postData = {
                    "first_name":values.first_name,
                    "last_name":values.last_name,
                    "mobile":values.mobile,
                    "email":values.email,
                    "password":values.password,
                    "confirm_password":values.confirm_password,
                    "country_id":"1",
                    "user_type":"data_entry",
                };
                await axios.post(apiAdd,postData,{axiosConfig}).then(response => response.data)
                .then((responseData)=>{
                    //setShowLoading(false); 
                    //setRows( responseData.results) ;
                    if(responseData.Status===false)
                    {
                        alert(responseData.Message);

                    }else {
                        alert(responseData.Message);
                        props.history.push({
                            pathname: "/data-entry/list",
                          });
                    }
                });
            }
            insertDataEntryOperator();


          }

       }
       
      
    }

  var name = "";
  if (typeof props.data.id === "undefined" || props.data.id === null) {
    name = " Add Data Entry Operator";
  } else {
    name = " Edit Data Entry Operator";
  }

    return (
        <>
        <div className={classes.root}>
          <div className="headLogo">
            <h4>{name}</h4>
            <img src={headLogo} alt="headLogo" />
          </div>
        <Paper className={classes.paper}>
        <form className={classes.container} noValidate autoComplete="off" >
        <h2>{name}</h2>

           <TextField
                id="outlined-first_name"
                label="First Name*"
                className={classes.textField}
                value={values.first_name || ''}
                onChange={handleChange('first_name')}
                margin="normal"
                variant="outlined"
                error={!!errors.first_name}
                helperText={errors.first_name}
            />

            <TextField
                id="outlined-last_name"
                label="Last Name*"
                className={classes.textField}
                value={values.last_name || ''}
                onChange={handleChange('last_name')}
                margin="normal"
                variant="outlined"
                error={!!errors.last_name}
                helperText={errors.last_name}
            />

             <TextField
                id="outlined-mobile"
                label="Mobile Number*"
                value={values.mobile || ''}
                onChange={handleChange('mobile')}
                type="number"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                error={!!errors.mobile}
                helperText={errors.mobile}
            />

            <TextField
                id="outlined-email"
                label="Email*"
                className={classes.textField}
                value={values.email || ''}
                onChange={handleChange('email')}
                margin="normal"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
            />

            {/* <TextField
              id="outlined-select-currency-native"
              select
              label="Select Company"
              className={classes.formControl}
              value={values.organization_id || ''}
              onChange={handleChange('organization_id')}
              SelectProps={{
                native: true,
              }}
              error={!!errors.organization_id}
              helperText={errors.organization_id}
              variant="outlined"
            >
            <option value=""></option>
              {organizations.map((org,index)=> (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
          </TextField> */}

            <TextField
                id="outlined-password-input"
                label="Password*"          
                className={classes.textField}
                type="password"
                autoComplete="password"
                onChange={handleChange('password')}
                margin="normal"
                variant="outlined"
                // value={values.password || ''}
                error={!!errors.password}
                helperText={errors.password}
            />  
            <TextField
                id="outlined-password-input"
                label="Confirm Password*"
                className={classes.textField}
                type="password"
                autoComplete="confirm_password"
                onChange={handleChange('confirm_password')}
                margin="normal"
                variant="outlined"
                // value={values.confirm_password || ''}
                error={!!errors.confirm_password}
                helperText={errors.confirm_password}
            /> 

            <div className={classes.actionBtn}>
              <button 
                // disabled={!validateForm()}
                type="button"
                onClick={() => formSubmit()}
                className="btn btn-primary"
                variant="contained"
              >
                Submit
              </button>
            
              <NavLink className={classes.cancelBtn} to="/data-entry/list">
                <button 
                  className="btn btn-outline-primary"
                  variant="contained"
                >
                  Cancel
                </button>
              </NavLink>
            </div>
        </form>
        </Paper>
        </div>
        </>
    )
}

export default withRouter(CreateDataEntryOperator)
