import React,{useState,useEffect} from "react";
import UseStyles from "./UseStyles";
import axios from 'axios';
import {API} from '../../../common';
import { NavLink } from "react-router-dom";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const SearchForm = (props) => {
  const classes = UseStyles();

  const [search, setSearch] = useState({
    name : '',
  })
  
  const [painPoints, setPainPoints] = useState([]);

  useEffect(() => {
    const painPointsPostData = [];
    const painPointsApiUrl = API + "painpoint";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchPainPoints = async () => {
      await axios
        .post(painPointsApiUrl, painPointsPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
            setPainPoints(responseData.Results);
        });
    };
    fetchPainPoints();
  }, []);

  const searchHandleChange = name => event => {
    setSearch({
      ...search,
      [name] : event.target.value
    })
  }

  const formSubmit = async () => {
    props.onSearch(search)
  }

  const Reset = () => {
    window.location.reload();
  }

  return (
    <>

      <Container className="px-0 pt-0">
        <form className="employee-form-grid pt-0" noValidate autoComplete="off">

            <div style={{display:'flex', justifyContent:'flex-start'}}>          
            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Pain Points"
              className={classes.textField}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              onChange={searchHandleChange("name")}
              margin="dense"
            >
              <option value=""></option>
              {painPoints.map((painpoints, index) => (
                <option key={painpoints.id} value={painpoints.name}>
                  {painpoints.name}
                </option>
              ))}
            </TextField>

                <span style={{margin:'8px 0px'}}>
                    <button 
                        type="button"
                        className="btn btn-primary"
                        style={{margin:'0px 10px'}}
                        onClick={() => formSubmit()}
                    >
                    Search
                    </button>

                    <button
                        type="reset"
                        className="btn btn-outline-primary"
                        variant="contained"
                        style={{margin:'0px 10px'}}
                        onClick={() => Reset()}
                    >
                    Reset
                    </button>
                </span>
            </div>


        </form>
      </Container>

    </>
  );
};

export default SearchForm;
