import { makeStyles } from '@material-ui/core/styles';
const UseStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding:'15px 0px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width:'250px',
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    button: {
        margin: '15px',
        backgroundColor:'#910039',
        color: '#FFFFFF',
        padding: '8px 20px',
        border: 'none',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    icon: {
        minWidth: '30px',
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '250px',
    },
    paddingTop: {
        paddingTop:'15px'
    },
    actionBtn : {
        display:'flex',
        justifyContent:'space-evenly',
        padding:'10px 15px'
    },
    cancelBtn : {
        marginLeft:'10px'
    },
}));

export default UseStyles;