import React,{useEffect,useState,useRef} from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import {API} from '../../../common';
import UseStyles from './UseStyles';
import TextField from '@material-ui/core/TextField'
import '../common/CSS/style.css'
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const SearchForm = (props) => {

    const classes = UseStyles();

    const [fasteners, setFasteners] = useState([]);
    const [applicationArea, setApplicationArea] = useState([]);

    const [search, setSearch] = React.useState({
      sub_category: '',
      // fastener_id: '',
      // application_id: ''
    });
    
    useEffect(() => {
      const fastenerPostData = [];
      const fastenerApiUrl = API + "fastenercategories";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const fetchFasterner = async () => {
        await axios
          .post(fastenerApiUrl, fastenerPostData, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            setFasteners(responseData.Results);
          });
      };
      fetchFasterner();
    }, []);

    useEffect(() => {
      const applicationAreaPostData = [];
      const applicationAreaApiUrl = API + "fastenercategories";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const fetchFasterner = async () => {
        await axios
          .post(applicationAreaApiUrl, applicationAreaPostData, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            setFasteners(responseData.Results);
          });
      };
      fetchFasterner();
    }, []);
      

    const searchHandleChange = name => event => {
      setSearch({ ...search, [name]: event.target.value });
    };
  
    const formSubmit = async () => {
      props.onSearch(search)
    }


      return(
        <>
          <div className={classes.container}>

            {/* <TextField
              id="outlined-select-currency-native"
              select
              label="Select Fastener"
              className={classes.textField}
              SelectProps={{
                native: true,
                
              }}
              variant="outlined"
              value={search.fastener_id}
              margin="dense"
              onChange={searchHandleChange("fastener_id")}
            >
              <option value=""></option>
              {fasteners.map((fastener, index) => (
                <option key={fastener.id} value={fastener.id}>
                  {fastener.name}
                </option>
              ))}
            </TextField> */}
            
            {/* <TextField
              id="outlined-select-currency-native"
              select
              label="Select Application Area"
              className={classes.textField}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              value={search.application_id}
              onChange={searchHandleChange("application_id")}
            >
              <option value=""></option>
              {applicationArea.map((applicationareas, index) => (
                <option key={applicationareas.id} value={applicationareas.id}>
                  {applicationareas.name}
                </option>
              ))}
            </TextField> */}

            <TextField
              id="sub_category"
              label="Sub Category Name"
              className={classes.textField}
              value={search.sub_category}
              onChange={searchHandleChange('sub_category')}
              variant="outlined"
              margin="dense"
            />

            <div className="align-self-center">
                <button 
                  onClick={() => formSubmit()}
                  className="btn btn-primary"
                  variant="contained"
                >
                  Search
                </button>

              
            </div>
          </div> 
        </>
      ); 
  }
  export default SearchForm;
