import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import EditDataEntryOperatorForm from './form';
import { RiseLoader } from "react-spinners";

const EditDataEntryOperator = (props) => {


    const [formData, setFormData] = useState([]);
    console.log(props)
    useEffect(() => {
        const id = props.match.params.id;
        const ApiUrl = API+"users/"+id;
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        const fetchUser = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData)=>{
            setFormData( responseData.Results) ;
        });
        setShowLoading(false);
      };
      fetchUser();
    },[]);

    const [showLoading, setShowLoading] = useState(true);
   // console.log(formData)
    return (
        <>
        
         {showLoading && (
            <RiseLoader sizeUnit={"px"} size={20} color={"#5578eb"} />
          )}
          { formData && (
            <EditDataEntryOperatorForm data={formData}/>
            )
          }
         
        </>
    )
}

export default EditDataEntryOperator
