import React, {useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import UseStyles from "./UseStyles";
import axios from 'axios'
import { API } from '../../../common'

const ChangeAdminPassForm = () => {

    const classes = UseStyles();
    const[form, setForm] = useState([]);
    const [adminId, setAdminId] = useState(1)

    const handleChange = name => event => {
        setForm({ ...form, [name]: event.target.value });
        console.log(form)
    };

    useEffect(() => {
        const apiUrlUsers = API+"users/user_password_index";
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: localStorage.getItem("token")
            }
        };
        const fetchData = async() => {
            var postData = {"user_type":'admin'};
            await axios.post(apiUrlUsers,postData,{axiosConfig}).then(response => response.data)
            .then((responseData)=>{ 
                setAdminId(responseData.Results.id) ;
            });
        };
        fetchData();
    }, [adminId])

    const changeAdminPass = async (e) => {
        
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: localStorage.getItem("token"),
            },
        };
        const apiAdminPassUpdate= API+"users/change_password";

        if (adminId) 
        {
            const updateAdminPass =  async() => {
                var postData = {
                    "user_id"  : adminId,
                    "current_password": form.old_password,
                    "new_password": form.new_password,
                    "confirm_password": form.confirm_password,
                };
                await axios.post(apiAdminPassUpdate,postData,{axiosConfig}).then(response => response.data)
                .then((responseData)=>{
                    console.log(responseData)
                    if(responseData.Status===false)
                    {
                        alert(responseData.Message);
                    } else  {
                        alert(responseData.Message);
                        window.location.reload();
                    }
                });
            }
            updateAdminPass();
        } 
    }

    return (
        <>
        <form>
              <Paper className={classes.root}>
                    <h4 className="mb-6"><b>Change Your Password</b></h4>
                    <Grid container spacing={3}>
                        <Grid item xs={6} lg={3}>
                            <input
                                type="text"
                                className="form-control"
                                id="old_password"
                                name="old_password"
                                type="password"
                                margin="normal"
                                placeholder="Old Password"
                                value={form.old_password}
                                onChange={handleChange('old_password')}
                            />
                        </Grid>
                        
                        <Grid item xs={6} lg={3}>
                            <input
                                type="text"
                                className="form-control"
                                id="new_password"
                                name="new_password"
                                type="password"
                                margin="normal"
                                placeholder="New Password"
                                value={form.new_password}
                                onChange={handleChange('new_password')}
                            />
                        </Grid>
                        
                        <Grid item xs={6} lg={3}>
                            <input
                                type="text"
                                className="form-control"
                                id="confirm_password"
                                name="confirm_password"
                                type="password"
                                margin="normal"
                                placeholder="Confirm Password"
                                value={form.confirm_password}
                                onChange={handleChange('confirm_password')}
                            />
                        </Grid>
                        
                        <Grid item xs={6} lg={3}>
                            <button 
                                type="button" 
                                className="btn btn-primary"
                                onClick={() => changeAdminPass()}
                            >
                            Change Password
                            </button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>    
        </>
    )
}

export default ChangeAdminPassForm
