import React from 'react';
import Banner from "./Banner";
import QualityControlBanner from "../common/assets/QualityControl-Banner@2x.jpg";
import CapabilitesSection from "./CapabilitesSection";
import GenericImg from "../common/assets/generic img.jpg";
import Navbar from './Navbar';
import Footer from './Footer';
const QualityControl = () => {

  return (
    <>
    <Navbar/>
      <Banner 
            imgsrc={QualityControlBanner}
            heading="Quality Control"
        />
        <section className="Capabilities py-5 mt-5">
          <div className="container">
            <div className="row">
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="3D measurement – CMM from Wenzel"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Catia V5 R21 to R 292D measurement - Digital height gauge (Trimos) etc."
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Drawing Cut Sections/Profile Dimension - Profile Projector (Mitutoyo)"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Gap & Flush - Slip gauge Box Grade 1 (Mitutoyo) Filler Gauge / Tapper Scale etc."
                />
                  
            </div>
          </div>
        </section>
      <Footer/>  
    </>
  )
}
export default QualityControl;
