import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headRows = [
  {
    id: "type",
    align: "center",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "names",
    numeric: false,
    disablePadding: false,
    align: "center",
    label: "Name",
  },
  {
    id: "active",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "action",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Sr.No</TableCell>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "center" : "left"}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};

export default EnhancedTableHead;
