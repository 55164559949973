import React from 'react';
import Banner from "./Banner";
import EngineeringBanner from "../common/assets/Engineering-banner-2x.jpg";
import CADStations from "../common/assets/24 CAD stations@2x.jpg";
import GenericImg from "../common/assets/generic img.jpg";

import CapabilitesSection from "./CapabilitesSection";
import Navbar from './Navbar';
import Footer from './Footer';
const Engineering = () => {

  return (
    <>
    <Navbar/>
      <Banner 
            imgsrc={EngineeringBanner}
            heading="Engineering"
        />
        <section className="Capabilities py-5 mt-5">
          <div className="container">
            <div className="row">
                <CapabilitesSection 
                  imgsrc={CADStations}
                  heading="24 CAD stations"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Catia V5 R21 to R 29"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="IDEAS C3p 10.1M"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Unigraphics NX 12"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Solid edge – 5 Sheets"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Work explorer for review"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="3D PDF"
                />
                  
            </div>
          </div>
        </section>
    <Footer/> 
    </>
  )
}
export default Engineering;
