import React from 'react';
import Banner from "./Banner";
import ManufacturingBanner from "../common/assets/Manufacturing-Banner@2x.jpg";
import CapabilitesSection from "./CapabilitesSection";
import GenericImg from "../common/assets/generic img.jpg";
import Navbar from './Navbar';
import Footer from './Footer';
const Manufacturing = () => {

  return (
    <>
    <Navbar/>
      <Banner 
            imgsrc={ManufacturingBanner}
            heading="Manufacturing"
        />
        <section className="Capabilities py-5 mt-5">
          <div className="container">
            <div className="row">
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="85 Injection molding Machine (from 100 to 650T)"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Gas injection molding"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="IDEAInsert Molding/with integrating assembly"
                />
                <CapabilitesSection 
                  imgsrc={GenericImg}
                  heading="Painting and Chrome with Sub Supplier"
                />
                  
            </div>
          </div>
        </section>
    <Footer/>   
    </>
  )
}
export default Manufacturing;
