import { defaults } from 'chart.js';
import React,{useEffect,useState} from 'react';
import {Line} from 'react-chartjs-2';
import {API} from '../../../common';
import UseStyles from './UseStyles';
import axios from 'axios';
import DatePicker from "react-datepicker";
import moment from 'moment';
function CustomerLineChart (){
    const classes = UseStyles();
    const [title,setTitle] = useState([])
    const [userData,setUserData]= useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    var  currentDate = new Date();
    var  lastDate = new Date(currentDate);
    lastDate.setDate(lastDate.getDate()- 7);
    useEffect(()=>{
        const fetchData = async() =>{
            const token = localStorage.getItem("token");
            const apiUrl = API+"users/graph_data_user_day";
            let axiosConfig = {
                headers : {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization:token[1]
                }
            };
            var postData = {
                currentDate,
                lastDate
            };
            const result = await axios.post(apiUrl,postData,{axiosConfig})
            .then(response => response.data)
            .then((responseData)=>{
                console.log(responseData)
                let response_title=[];
                let response_data=[];
                for (const item of responseData.Results){
                    response_title.push(item.date)
                    response_data.push(item.count)
                }
                setTitle(response_title);
                setUserData(response_data);
            });
        };
        fetchData();
    },[]);

    const formSubmit = async () => {
        const token = localStorage.getItem("token");
        const apiUrl = API+"users/graph_data_user";
        let axiosConfig = {
            headers : {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization:token[1]
            }
        };
        let postData = {
            "startDate": startDate,
            "endDate":   endDate,
            
          }
          await axios.post(apiUrl, postData, {axiosConfig}).then(response => response.data)
          .then((responseData) => {
              console.log(responseData)
              let response_title=[];
                let response_data=[];
                for (const item of responseData.Results){
                    response_title.push(item.date)
                    response_data.push(item.count)
                }
                setTitle(response_title);
                setUserData(response_data);
            if(responseData.Status === false)
            {
              alert(responseData.Message);
            }
            else {
              alert(responseData.Message);
            }
          })

    }

    const Reset = ()=>{
        window.location.reload();
    };
        const data = {
            labels: title,
            datasets: [
            {
                label: 'New User SignUp',
                data: userData ,
                interpolation: "spline",
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
                lineTension: 0.4,
                radius: 5
            }
        ]
        }

        const options = {
            plugins: {
                title: {
                    display: true,
                    text: 'New SignUp User',
                    padding:{
                        top:20,
                        bottom:10
                    },
                    font:{
                        size:16
                    }
                },
            },
            scales : {
                y:{
                    min: 0,
                    title: {
                        display: true,
                        text:'Customer Count',
                        
                        padding:{
                            right:10
                        },
                            font: {
                                size: 12,
                                
                            },
                    }
                },
                x:{
                    title: {
                        display: true,
                        text: 'Customer SignUp Date',
                        padding:{
                            top:10,
                            bottom:10
                        }
                    }
                }
            },
        }

    return(
        <>
        <div className="container p-0">
        <div class="row justify-content-center">

                <DatePicker
                
                className=" mr-1"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                isClearable
                placeholderText="Select Start Dt"
                /> 
                <DatePicker
                
                className=""
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                endDate={endDate}
                minDate={startDate}
                isClearable
                placeholderText="Select End Dt"
                />

                <button 
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary py-1 mx-4"
                    variant="contained"
                >
                    Apply
                </button>
                <button 
                    type="button"
                    onClick={() => Reset()}
                    className="btn btn-outline-primary py-1"
                    variant="contained"
                >
                    Clear
                </button>
            </div>
        </div>
            <Line
            height={"210"}
            data={data}
            options = {options}
            />
        </>
    );

}
export default CustomerLineChart