import React,{useEffect,useState,useRef} from 'react'

import axios from 'axios';
import {API} from '../../../common';
import UseStyles from './UseStyles';
import TextField from '@material-ui/core/TextField'
import '../common/CSS/style.css'

const SearchForm = (props) => {

    const classes = UseStyles();

    const [fasteners, setFasteners] = useState([]);

    const [search, setSearch] = React.useState({
      fastener_id: '',
      application_area: '',
    });
    
    useEffect(() => {
      const fastenerPostData = [];
      const fastenerApiUrl = API + "fastenercategories";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const fetchFasterner = async () => {
        await axios
          .post(fastenerApiUrl, fastenerPostData, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            setFasteners(responseData.Results);
          });
      };
      fetchFasterner();
    }, []);

    const searchHandleChange = name => event => {
      setSearch({ ...search, [name]: event.target.value });
    };
  
    const formSubmit = async () => {
      props.onSearch(search)
    }


      return(
        <>
          <div className={classes.container}>

            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Fastener"
              className={classes.textField}
              SelectProps={{
                native: true,
                
              }}
              variant="outlined"
              margin="dense"
              value={search.fastener_id}
              onChange={searchHandleChange("fastener_id")}
            >
              <option value=""></option>
              {fasteners.map((fastener, index) => (
                <option key={fastener.id} value={fastener.id}>
                  {fastener.name}
                </option>
              ))}
            </TextField>

            <TextField
              id="application_area"
              label="Application Area Name"
              className={classes.textField}
              value={search.application_area}
              onChange={searchHandleChange('application_area')}
              variant="outlined"
              margin="dense"
            />

            <div className="align-self-center">
                <button 
                  onClick={() => formSubmit()}
                  className="btn btn-primary"
                  variant="contained"
                >
                  Search
                </button>

              
            </div>
          </div> 
        </>
      ); 
  }
  export default SearchForm;
