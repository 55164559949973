import React,{useState} from 'react'
import Dropzone from "react-dropzone";
import axios from "axios";
import {API} from '../../../common';

const Upload = () => {

    const [formdata, setformdata] = useState({});
    
    const onDropfile = (acceptedFiles) => {
        //console.log(acceptedFiles);
        let file = acceptedFiles[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function() {

            setformdata({ ...formdata, ["video"]: file });
          };
      
          reader.onerror = function() {
            console.log(reader.error);
          };

          
    }   

    const onDropfilePDF = (acceptedFiles) => {
        //console.log(acceptedFiles);
        let file = acceptedFiles[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function() {

            setformdata({ ...formdata, ["drawing"]: file });
          };
      
          reader.onerror = function() {
            console.log(reader.error);
          };

          
    } 

    //console.log(formdata);
    const formSubmit = async () => {

        let form_data = new FormData();
        form_data.append("video", formdata.video);
        form_data.append("drawing", formdata.drawing);

        let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: localStorage.getItem("token"),
            },
          };
          const apiadd = API + "products/upload";

          axios
          .post(apiadd, form_data, axiosConfig)
          .then((res) => {

          })
    }
    return (
        <div>
        <form  encType="multipart/form-data">
            <Dropzone onDrop={acceptedFiles => 
                onDropfile(acceptedFiles)
            }>
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                    </section>
                )}
            </Dropzone>

            <Dropzone onDrop={acceptedFiles => 
                onDropfilePDF(acceptedFiles)
            }>
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                    </section>
                )}
            </Dropzone>

            <input
                type="button"
                onClick={() => formSubmit()}
                value="Save"
                className="btn btn-primary font-weight-bold"
            />

            </form> 
        </div>
    )
}

export default Upload
