import React, {useState, useEffect} from 'react'
import UseStyles from "./UseStyles";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import headLogo from '../common/assets/ITW-logo-small.png'
import axios from 'axios'
import { API } from '../../../common'
import ChangeAdminPassForm from './ChangeAdminPass';
// import ChangeDataEntryPassForm from './ChangeDataEntryPass';
import TermsConditionsForm from './TermsCondition';
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const Create = () => {
    const classes = UseStyles();

    const [state, setState] = useState({
        termsCondition:'',
        policy:''
    })   
    const [showLoading, setShowLoading] = useState(true);


    useEffect(() => {
        const id = 1;
        const ApiUrl = API+"policy";
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        const fetchPolicy = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData) => {  
            setShowLoading(false);
            setState({
                policy: responseData.Results.policy
            })
            
        });
      };
      fetchPolicy();
    },[]);


    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const policySubmit = async (e) => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: localStorage.getItem("token"),
            },
        };

        const apiPolicyUpdate= API+"policy/policy_update";
            
                const updatePolicy =  async() => {
                var postData = {
                    "id"  : 1,
                    "policy": state.policy
                };
                await axios.post(apiPolicyUpdate,postData,{axiosConfig}).then(response => response.data)
                .then((responseData)=>{
                    if(responseData.Status===false)
                    {
                        alert(responseData.Message);
                    } else {
                        alert(responseData.Message);
                    }
                });
            }               
            updatePolicy();

    }
    
    return (
        <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
    
        <>
            <div className="headLogo">
                <h4>Settings</h4>
                <img src={headLogo} alt="headLogo" />
            </div>

            <ChangeAdminPassForm />
            {/* <ChangeDataEntryPassForm /> */}
            <TermsConditionsForm />

            

            <div className={classes.settingsDiv}>
                <Paper className={classes.root}>
                    <h4><b>Update Policy</b></h4>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows="4"
                        placeholder="Policy"                        
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={state.policy || ''}
                        onChange={handleChange('policy')}
                    />

                    <button 
                        type="button" 
                        className="btn btn-primary"
                        onClick={() => policySubmit()}
                    >
                        Update Policy
                    </button>
                </Paper>
            </div>
        </>
        </LoadingOverlay>

    )
}

export default Create
