import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../common";
import UseStyles from "./UseStyles";
import headLogo from "../common/assets/ITW-logo-small.png";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { NavLink } from "react-router-dom";

export default function ContactUsDetail(props) {
  const classes = UseStyles();
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [imagePath, setImagePath] = useState();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    const id = props.match.params.id;
    console.log(id);
    const ApiUrl = API + "contactus/" + id;
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchContactDetails = async () => {
      await axios
        .get(ApiUrl, id, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setFormData(responseData.Results);
          setUserData(responseData.Results.User);
          setImagePath(responseData.ImgPath);
        });
    };
    fetchContactDetails();
  }, []);

  const Image = imagePath + formData.image;

  return (
    <>
      <div className="headLogo">
        <h4>Contact Us Details</h4>
        <img src={headLogo} alt="headLogo" />
      </div>
      {formData && (
        <Paper className={classes.root}>
          <List
            component="nav"
            className={classes.root}
            aria-label="Mailbox folders"
          >
            <ListItem button>
              <ListItemText>
                <span>
                  <h5>
                    <b>User Name :</b>
                  </h5>
                </span>
                {userData.first_name} {userData.last_name}
              </ListItemText>
            </ListItem>
            <Divider />

            <ListItem button divider>
              <ListItemText>
                <span>
                  <h5>
                    <b>User Email :</b>
                  </h5>
                </span>
                {userData.email}
              </ListItemText>
            </ListItem>

            <ListItem button>
              <ListItemText>
                <span>
                  <h5>
                    <b>Pain Points Name :</b>
                  </h5>
                </span>
                {formData.pain_points_name}
              </ListItemText>
            </ListItem>
            <Divider />

            <ListItem button divider>
              <ListItemText>
                <span>
                  <h5>
                    <b>Other Pain Points :</b>
                  </h5>
                </span>
                {formData.other_pain_points}
              </ListItemText>
            </ListItem>

            <ListItem button>
              <ListItemText>
                <span>
                  <h5>
                    <b>Requirements :</b>
                  </h5>
                </span>
                {formData.requirements}
              </ListItemText>
            </ListItem>
            <Divider light />

            <ListItem button>
              <ListItemText>
                <span>
                  <h5>
                    <b>Image :</b>
                  </h5>
                </span>
                {formData.image ? <img src={Image} width="100" /> : ""}
              </ListItemText>
            </ListItem>
            <NavLink className={classes.cancelBtn} to="/contact-us/list">
              <button className="btnCreate" variant="contained">
                Cancel
              </button>
            </NavLink>
          </List>
        </Paper>
      )}
    </>
  );
}
