import React, { useState, useEffect, useRef } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import headLogo from "../common/assets/ITW-logo-small.png";
import UseStyles from "./UseStyles";
import axios from "axios";
import { API } from "../../../common";
import Dropzone from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import Validator from "validatorjs";
import "../common/CSS/style.css";
import ReactPlayer from "react-player";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Cropper from "cropperjs";
import ImageCropper from "./imagecropper";
import "./imagecropper.css";

const CreateProductVideo = (props) => {
  const imageElement = useRef();
  const classes = UseStyles();
  const [formData, setFormData] = useState([]);
  const [productPath, setProductPath] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [productVideo, setProductVideo] = useState();
  const [productThumbnail, setProductThumbnail] = useState();

  useEffect(() => {
    setFormData(props.data);
    let new_specification =
      props.data.ProductSpecifications?.length > 0
        ? props.data.ProductSpecifications[0]
        : "";
    setProductVideo(props.data.video);
    setProductThumbnail(props.data.thumbnail);
  }, [props.data]);

  useEffect(() => {
    setProductPath(props.imagepath);
  }, [props.imagepath]);
  //console.log(props)

  const [videoName, SetVideoName] = useState("");
  const [files, setFiles] = useState([]);

  const onDropfileVideo = (acceptedFiles) => {
    let file = acceptedFiles[0];
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    SetVideoName(file.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    //console.log(file);
    reader.onload = function() {
      setFormData({ ...formData, ["video"]: file });
    };

    reader.onerror = function() {
      console.log(reader.error);
    };
  };

  const [thumbnail, SetThumbnail] = useState("");
  const [imageDestination, SetImageDestination] = useState("");

  const onDropThumbnail = (acceptedFiles) => {
    let file = acceptedFiles[0];
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    SetThumbnail(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);

    //console.log(file);
    // reader.onload = function() {
    //   setFormData({ ...formData, ["thumbnail"]: file });
    // };

    // reader.onerror = function() {
    //   console.log(reader.error);
    // };
    const cropper = new Cropper(imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination(canvas.toDataURL(file.type));
      },
    });
  };

  ///////////////////// base 64 to blob //////////////////////////////////

  useEffect(() => {
    if (imageDestination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob = converterDataURItoBlob(imageDestination);
      // blob = imageDestination;
      if (blob.size > 0) {
        let crop = blob;
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        // // console.log(blob);
        console.log(crop);
        reader.onload = function() {
          setFormData({ ...formData, ["thumbnail"]: blob });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination]);
  /////////////////////////////////////////////////////////////////

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  const [errors, setErrors] = useState([]);
  const validateForm = () => {
    // console.log(formData)
    //console.log(prodspecification);

    let temp = { ...errors };
    let data;
    if (props.data.id) {
      data = {
        name: formData.name,
      };
    } else {
      data = {
        name: formData.name,
        video: formData.video,
        thumbnail: formData.thumbnail,
      };
    }
    let rules;

    if (props.data.id) {
      rules = {
        name: "required",
      };
    } else {
      rules = {
        name: "required",
        video: "required",
        thumbnail: "required",
      };
    }
    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }

    if (validation.fails()) {
      temp.name = validation.errors.first("name");

      temp.video = validation.errors.first("video");
      temp.thumbnail = validation.errors.first("thumbnail");

      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async () => {
    if (validateForm()) {
      setShowLoading(true);

      let form_data = new FormData();
      form_data.append("name", formData.name);
      form_data.append("video", formData.video);
      form_data.append("thumbnail", formData.thumbnail);

      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };
      const apiAddProduct = API + "products/products_video_add";
      const apiUpdateProduct = API + "products/products_video_update";
      //console.log(formData);

      if (props.data.id) {
        console.log(props.data.id);
        form_data.append("id", props.data.id);

        await axios
          .post(apiUpdateProduct, form_data, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            console.log(responseData);
            setShowLoading(false);
            if (responseData.Status === false) {
              alert(responseData.Message);
            } else {
              alert(responseData.Message);
              props.history.push({
                pathname: "/upload-video/list",
              });
            }
          });
      } else {
        await axios
          .post(apiAddProduct, form_data, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            setShowLoading(false);
            if (responseData.Status === false) {
              alert(responseData.Message);
            } else {
              alert(responseData.Message);
              props.history.push({
                pathname: "/upload-video/list",
              });
            }
          });
      }
    }
  };

  // Full video path
  let video_url = productPath + productVideo;
  let thumbnail_image = productPath + productThumbnail;

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className={classes.root}>
          <div className="headLogo">
            <h4>
              {props.data.id == null
                ? "Add Product Video"
                : "Edit Product Video"}
            </h4>
            <img src={headLogo} alt="headLogo" />
          </div>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              encType="multipart/form-data"
              noValidate
              autoComplete="off"
            >
              <TextField
                id="product-video-name"
                className={classes.textField}
                label="Product Video Name*"
                variant="outlined"
                value={formData.name || ""}
                onChange={handleChange("name")}
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
              />

              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <h5 className="ml-3 mt-3">
                      <b>Add Video</b>
                    </h5>
                    <Dropzone
                      onDrop={(acceptedFiles) => onDropfileVideo(acceptedFiles)}
                      accept="video/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone ml-3">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' Drop Video Here or Or click to select
                              <br />
                              (Only 1 Video file)
                              <br />
                              <span>{videoName}</span>
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errors && errors !== null ? (
                      <small className={classes.errorsmall}>
                        {errors.video}
                      </small>
                    ) : (
                      ""
                    )}

                    {productVideo && productVideo !== null ? (
                      <div className="player-wrapper">
                        <ReactPlayer
                          className="react-player fixed-bottom"
                          url={video_url || ""}
                          width="100%"
                          height="100%"
                          controls={true}
                          className="react-player"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <h5 className="ml-3 mt-3 mb-3">
                      <b>Add Thumbnail</b>
                    </h5>
                    <Dropzone
                      onDrop={(acceptedFiles) => onDropThumbnail(acceptedFiles)}
                      accept="image/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone ml-3">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' Drop thumbnail Here or Or click to select
                              <br />
                              (Only 1 thumbnail file)
                              <br />
                              <span>{thumbnail}</span>
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errors && errors !== null ? (
                      <small className={classes.errorsmall}>
                        {errors.thumbnail}
                      </small>
                    ) : (
                      ""
                    )}
                    {files.map((file) => (
                      <>
                        <div>
                          <div className="img-container">
                            <img
                              className="img-size"
                              ref={imageElement}
                              src={file.preview}
                              alt="Source"
                              crossorigin
                            />
                          </div>
                          <img
                            src={imageDestination}
                            className="img-preview"
                            alt="Destination"
                          />
                        </div>
                      </>
                    ))}
                    {props.data.id !== null &&
                    productThumbnail &&
                    productThumbnail !== null ? (
                      <div className="player-wrapper pl-4">
                        <img src={thumbnail_image} width="250px" />
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <aside className={classes.thumbsContainer}>
                        {thumbs}
                      </aside> */}
                  </Grid>
                </Grid>
              </Grid>
              <div className="container">
                <div className="row mt-3">
                  <button
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    Submit
                  </button>

                  <NavLink
                    className={classes.cancelBtn}
                    to="/upload-video/list"
                  >
                    <button
                      className="btn btn-outline-primary"
                      variant="contained"
                    >
                      Cancel
                    </button>
                  </NavLink>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default withRouter(CreateProductVideo);
