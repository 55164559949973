import React,{useState} from 'react'
import CreatePainPointForm from './form';

const CreatePainPoint = (props) => {

    const [formData, setFormData] = useState({
        name:'',
    });
    

    return (
        <>
            <CreatePainPointForm data={formData}/>
        </>
    )
}

export default CreatePainPoint