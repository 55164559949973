import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import EditPainPointForm from './form';
const EditPainPoint = (props) => {
    const [formData, setFormData] = useState([]);

    useEffect(() => {
      const id = props.match.params.id;
      console.log(id)
      const ApiUrl = API+"painpoint/"+id;
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        console.log(formData)
        const fetchPainPoint = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData)=>{
          console.log(responseData)
            setFormData(responseData.Results) ;
            });
      };
      fetchPainPoint();
    },[]);

    return (
        <>
            { formData && (
            <EditPainPointForm data={formData} />
            )
            }
        </>
    )
}

export default EditPainPoint
