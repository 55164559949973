import React, { useState } from "react";
import CreateCategoryForm from "./form";

const CreateFastener = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    category_image: "",
    fastener_category_id: "",
    application_area__id: "",
  });

  return (
    <>
      <CreateCategoryForm data={formData} />
    </>
  );
};

export default CreateFastener;
