import React from "react";
import ThreeSection from "./ThreeSection";
import Banner from "./Banner";
import ContactBanner from "../common/assets/Contact-banner@2x.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";

const TermsConditions = () => {
  return (
    <>
      <Navbar />
      <Banner
        imgsrc={ContactBanner}
        heading="Terms and Conditions of Use
"
      />

      <section className="py-5 about-section">
        <div className="container">
          <div>
            <p>
              Welcome to the{" "}
              <strong>
                <em>www.ITWIndiaApp.com</em>
              </strong>{" "}
              website (the "<strong>ITW Website</strong>" or the "
              <strong>Website</strong>") operated by{" "}
              <strong>Illinois Tool Works Inc.</strong> ("<strong>ITW</strong>
              "). This Website provides general information about ITW free of
              charge to Website users. All Website uses are subject to the
              following terms and conditions of use ("
              <strong>Terms of Use</strong>").
            </p>
            <p>
              Please read these Terms of Use carefully before accessing or using
              any part of this Website. By accessing or using this Website, you
              agree that you have read, understand, and agree to be bound by
              these Terms of Use, as amended from time to time.{" "}
              <em>
                If you do not agree to these Terms of Use, do not access or use
                any part of this Website.
              </em>
            </p>
            <p>
              ITW may revise and update these Terms of Use at any time by
              posting the amended terms to this Website. Your continued use of
              the Website means that you accept and agree to the revised Terms
              of Use. If you disagree with the Terms of Use (as amended from
              time to time) or are dissatisfied with this Website, your sole and
              exclusive remedy is to discontinue using this Website. The most
              current version of these Terms of Use, which supersedes all
              previous versions, can be reviewed by clicking on the "Terms of
              Use" hyperlink at the bottom of each page of the Website.
            </p>
            <h4>USE OF THE ITW WEBSITE</h4>
            <p>You agree to use the Website only for lawful purposes.</p>
            <ol>
              <li>
                Specifically, you agree not to do any of the following: (1)
                upload to or transmit on the Website any defamatory, indecent,
                obscene, harassing, violent, or otherwise objectionable
                material, or any material that is, or may be, protected by
                copyright, without permission from the copyright owner; (2) use
                the Website to violate the legal rights (including the rights of
                publicity and privacy) of others or to violate the laws of any
                jurisdiction; (3) use the Website to intercept or attempt to
                intercept electronic mail not intended for you; (4) misrepresent
                an affiliation with any person or organization; (5) upload to or
                transmit on the Website any advertisements or solicitations of
                business; (6) restrict or inhibit use of the Website by others;
                (7) upload or otherwise transmit files that contain a virus or
                corrupted data; (8) collect information about others (including
                e-mail addresses) without their consent; (9) download a file or
                software or include in a message any software, files, or links
                that you know, or have reason to believe, cannot be distributed
                legally over the Website or that you have a contractual
                obligation to keep confidential (regardless of availability on
                the Website); (10) post "spam," transmit chain letters, or
                engage in other similar activities; or (11) engage in any other
                conduct that restricts or inhibits anyone's use or enjoyment of
                the Website, or which, as determined by ITW, may harm ITW or
                users of the Website or expose them to liability.
              </li>
              <li>
                Any content and/or opinions uploaded, expressed, or submitted to
                a message board, chatroom, or any other publicly available
                section of the Website (including password-protected areas), and
                all articles and responses to questions, other than the content
                provided by ITW, are solely the opinions and responsibility of
                the person or entity submitting them and do not necessarily
                reflect the opinions of ITW. You understand and acknowledge that
                you are responsible for whatever content you submit, and you,
                not ITW, have full responsibility for such content, including
                its legality, reliability, and appropriateness. By uploading or
                otherwise transmitting material to any area of the Website, you
                warrant that the material is your own, is in the public domain,
                or is otherwise free of proprietary or other restrictions and
                that you have the right to post it to the Website. You grant to
                ITW the right to use all content you upload or otherwise
                transmit to the Website in any manner ITW chooses, including,
                but not limited, to copying, displaying, or publishing it in any
                format whatsoever, modifying it, incorporating it into other
                material, or making a derivative work based on it.
              </li>
              <li>
                ITW reserves the right, but does not assume any responsibility,
                to (1) remove any material posted on the Website which ITW, in
                its sole discretion, deems inconsistent with the foregoing
                commitments, including any material ITW has been notified, or
                has reason to believe, constitutes a copyright infringement; and
                (2) terminate any user's access to all or part of the Website.
                However, ITW can neither review all material before it is posted
                on the Website nor ensure prompt removal of objectionable
                material after it has been posted. Accordingly, ITW assumes no
                liability for any action or inaction regarding transmissions,
                communications, or content provided by third parties. ITW
                reserves the right to take any action it deems necessary to
                protect the personal safety of users of this Website and the
                public; however, ITW has no liability or responsibility to
                anyone for performance or nonperformance of the activities
                described in this paragraph.
              </li>
              <li>
                Your failure to comply with the provisions above may result in
                the termination of your access to the ITW Website and may expose
                you to civil and/or criminal liability.
              </li>
            </ol>
            <h4>COPYRIGHT RESTRICTIONS/USE OF CONTENT</h4>
            <p>
              The entire contents of this Website (including all information,
              software, text, displays, images, and audio) and the design,
              selection, and arrangement thereof, are proprietary to ITW or its
              licensors and are protected by United States and international
              laws regarding copyrights, trademarks, trade secrets, and other
              proprietary rights. You are authorized only to use the content on
              the Website for personal use or legitimate business purposes
              related to your role as a current or prospective shareholder,
              customer, supplier, or distributor of ITW. You may not copy,
              modify, create derivative works of, publicly display or perform,
              republish, store, transmit, or distribute any of the material on
              this Website without the prior written consent of ITW, except to:
              (a) store copies of such materials temporarily in RAM, (b) store
              files that are automatically cached by your web browser for
              display enhancement purposes, and (c) print a reasonable number of
              pages of the Website; provided in each case that you do not alter
              or remove any copyright or other proprietary notices included in
              such materials. Neither the title nor any intellectual property
              rights to any information or material in this Website are
              transferred to you, but remain with ITW or the applicable owner of
              such content. Except as expressly authorized by ITW in writing,
              you may not reproduce, sell or exploit for any commercial purposes
              (i) any part of this Website, (ii) access to this Website, or
              (iii) use of this Website or of any services or materials
              available through this Website.
            </p>
            <h4>TRADEMARKS</h4>
            <p>
              The term "ITW" is a trademark and the ITW logo and all related
              product and service names, designs, and slogans are ITW
              trademarks. You may not use such marks without ITW's prior written
              permission. All other names, brands, and marks are used for
              identification purposes only and may be trademarks or registered
              trademarks of their respective owners.
            </p>
            <h4>DEALINGS WITH MERCHANTS</h4>
            <p>
              If you choose to correspond, participate in a promotion, or engage
              in transactions with any merchant found on or through this
              Website, you acknowledge and agree that ITW is not a party to, and
              will not be responsible for, your interaction with such merchant,
              including its treatment of your information and the terms and
              conditions applicable to any transaction between you and the
              merchant. The terms of your interaction with any merchant are
              solely between you and such merchant. You agree that ITW will have
              no responsibility or liability for any loss or damage of any kind
              that you may suffer as the result of any such interaction or the
              presence of such merchants on this Website.
            </p>
            <h4>LIABILITY OF ITW AND ITS LICENSORS</h4>
            <p>
              ITW does not assume any liability for the materials, information,
              and opinions provided on, or available through, the Website (the "
              <strong>Site Content</strong>"). Reliance on the Site Content is
              solely at your own risk. ITW disclaims any liability for injury or
              damages resulting from the use of any Site Content.
            </p>
            <p>
              THE ITW WEBSITE, THE SITE CONTENT, AND ANY PRODUCTS AND SERVICES
              PROVIDED ON OR AVAILABLE THROUGH THIS WEBSITE ARE PROVIDED ON AN
              "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL FAULTS. NEITHER ITW NOR
              ANY PERSON ASSOCIATED WITH ITW MAKES ANY WARRANTY OR
              REPRESENTATION WITH RESPECT TO THE QUALITY, ACCURACY, OR
              AVAILABILITY OF THE WEBSITE. SPECIFICALLY, BUT WITHOUT LIMITING
              THE FOREGOING, NEITHER ITW NOR ANYONE ASSOCIATED WITH ITW WARRANTS
              OR REPRESENTS THAT THE WEBSITE, THE SITE CONTENT, OR ANY SERVICES
              PROVIDED ON OR THROUGH THIS WEBSITE WILL BE ACCURATE, RELIABLE,
              ERROR-FREE, OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; THAT
              THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS; OR THAT THE WEBSITE WILL
              OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. ITW DISCLAIMS ALL
              WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ANY
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              OR NON-INFRINGEMENT. IN NO EVENT WILL ITW OR ITS LICENSORS OR
              CONTRACTORS BE LIABLE FOR ANY DAMAGES OF ANY KIND, UNDER ANY LEGAL
              THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF, OR
              INABILITY TO USE, THE WEBSITE, THE SITE CONTENT, ANY SERVICES
              PROVIDED ON OR THROUGH THIS WEBSITE OR ANY LINKED SITE, INCLUDING
              ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY,
              LOST PROFITS, OR DAMAGES RESULTING FROM DELAY, INTERRUPTION IN
              SERVICE, VIRUSES, DELETION OF FILES OR ELECTRONIC COMMUNICATIONS,
              OR ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE WEBSITE OR THE
              SITE CONTENT, WHETHER OR NOT THERE IS NEGLIGENCE BY ITW AND
              WHETHER OR NOT ITW HAS BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH
              DAMAGES.
            </p>
            <h4>INDEMNIFICATION</h4>
            <p>
              You agree to indemnify and hold harmless ITW and its officers,
              directors, employees, agents, affiliates, third-party information
              providers, licensors, and others involved in the ITW Website or
              the delivery of products, services, or information over the ITW
              Website (collectively, the "<strong>Indemnified Parties</strong>
              "), from and against any and all liabilities, expenses, damages,
              and costs, including reasonable attorney's fees, arising from any
              violation by you of these Terms of Use or your use of the Website
              or any products, services, or information obtained from this
              Website. You will also indemnify and hold the Indemnified Parties
              harmless from and against any claims brought by third parties
              arising out of your use of the information accessed from this
              Website.
            </p>
            <h4>SUBMISSION OF Personal INFORMATION on the Website</h4>
            <p>
              These Terms of Use hereby incorporate ITW's Website{" "}
              <a href="/privacy-statement/">Privacy Statement</a>, which
              describes ITW's use of personal information collected through the
              Website and the choices and rights available to you with respect
              to such information.
            </p>
            <p>
              This Website is operated by ITW in the United States and any
              personal information submitted through the Website will result in
              the collection and processing of such information in the United
              States. As a result, if you are visiting the Website from a
              country other than the United States, your personal information
              will cross an international boundary. The level of protection for
              your personal information in the United States may not be the same
              as the level of protection in your country.
            </p>
            <h4>
              PROTECTION OF PERSONAL INFORMATION; lEGALLY-rEQUIRED dISCLOSURES
            </h4>
            <p>
              You are prohibited from using any services or facilities provided
              in connection with this Website to compromise security or tamper
              with system resources and/or accounts. The use or distribution of
              tools designed for compromising security (<em>e.g</em>., password
              guessing programs, cracking tools, or network probing tools) is
              strictly prohibited. If you become involved in any violation of
              system security, ITW reserves the right to release your details to
              system administrators at other web sites in order to assist them
              in resolving security incidents. ITW reserves the right to
              investigate suspected violations of these Terms of Use.
            </p>
            <p>
              ITW reserves the right to fully cooperate with any law enforcement
              authorities or court order requesting or directing ITW to disclose
              the identity of anyone posting any e-mail messages, or publishing
              or otherwise making available any materials that are believed to
              violate these Terms of Use. TO THE EXTENT PERMITTED UNDER
              APPLICABLE LAW, BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD
              HARMLESS ITW AND THE INDEMNIFIED PARTIES FROM ANY CLAIMS RESULTING
              FROM ANY ACTION TAKEN BY ITW DURING OR AS A RESULT OF ITS
              INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
              INVESTIGATIONS BY EITHER ITW OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <h4>COMMENTS AND SUBMISSIONS</h4>
            <p>
              ITW welcomes your comments. To the extent permitted under
              applicable law, all comments, suggestions, or other information
              sent by you to this Website will become ITW's property and you
              agree that all intellectual property rights therein are
              transferred to ITW. You understand that any postings to publicly
              available portions of the Website are non-confidential for all
              purposes.
            </p>
            <h4>LINKS TO OTHER SITES</h4>
            <p>
              The Website may contain links to other websites on the Internet.
              ITW is not responsible for and does not endorse the content,
              products, or services of any third-party web sites, including,
              without limitation, sites framed within the ITW Website or
              third-party advertisements, and ITW does not make any
              representations regarding their quality, content, or accuracy.
              Your use of third-party websites is at your own risk and subject
              to the terms and conditions of use for such web sites.
            </p>
            <h4>US GOVERNMENT USERS</h4>
            <p>
              Use, duplication, or disclosure by the United States Government is
              subject to the restrictions set forth in DFARS
              252.227-7013(c)(1)(ii) and FAR 52.227-19 and any other successor
              regulations that may be applicable.
            </p>
            <h4>COMPLIANCE</h4>
            <p>
              The owner of this Website is based in the State of Illinois, USA.
              ITW makes no representation that materials in this Website are
              appropriate or available for use in other locations. If you access
              this Website from other locations, you are responsible for
              complying with local laws.
            </p>
            <h4>CONTACT</h4>
            <p>
              Please contact us (using the "Contact" hyperlink at the bottom of
              each page of the Website) if you become aware of any content that
              may infringe the copyright of a third party or that you believe to
              be in violation of these Terms of Use.
            </p>
            <h4>MISCELLANEOUS</h4>
            <p>
              These Terms of Use shall be governed in all respects by and
              construed in accordance with the laws of the State of Illinois,
              USA, without regard to its conflicts of law principles. These
              Terms of Use, as they may be amended from time to time, completely
              and exclusively state the agreement between you and ITW with
              respect to the Website, and no other terms that may have been
              communicated to you orally or in any other manner shall have any
              force or effect. Any cause of action you may have with respect to
              the Website must be commenced within one (1) year after the claim
              or cause of action arises or such claim or cause of action is
              barred. Exclusive jurisdiction over any cause of action arising
              out of these Terms of Use or your use of the Website shall be in
              the state or federal courts located in Chicago, Illinois. You
              agree to submit to the jurisdiction of such courts.
            </p>
            <p>
              If any part of these Terms of Use is unenforceable, the
              unenforceable part shall be construed to reflect, as nearly as
              possible, the original intentions of the parties. The other
              provisions of these Terms of Use shall remain in full force and
              effect.
            </p>
            <p>
              You agree that no joint venture, partnership, or agency
              relationship exists between you and ITW as a result of these Terms
              of Use or your use of this Website.
            </p>
            <p>
              A printed version of these Terms of Use and of any notice given in
              electronic form shall be admissible in judicial or administrative
              proceedings based upon or relating to these Terms of Use to the
              same extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form.
            </p>
            <p>
              ITW's failure to insist upon or enforce strict performance of any
              provision of these Terms of Use shall not constitute a waiver of
              the provision. Neither a course of dealing or conduct between you
              and ITW nor any trade practices shall be deemed to modify these
              Terms of Use. ITW may revise these Terms of Use at any time by
              updating this posting.
            </p>
            <p>
              <em>These Terms of Use were last updated on February 28, 2020</em>
              .
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsConditions;
