import React from "react";
import brandLogo from "../common/assets/ITW-logo.svg";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
const Navbar = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={brandLogo} />
          </NavLink>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  exact
                  activeClassName="menu_active"
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="menu_active"
                  className="nav-link"
                  to="/about"
                >
                  About
                </NavLink>
              </li>

              <li class="nav-item dropdown">
                <NavLink
                  class="nav-link dropdown-toggle"
                  to="/capabilities"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Capabilities
                </NavLink>

                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="nav-item">
                    <NavLink className="dropdown-item" to="/engineering">
                      Engineering
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="dropdown-item" to="/manufacturing">
                      Manufacturing
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="dropdown-item" to="/qualitycontrol">
                      Quality Control
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <NavLink
                  activeClassName="menu_active"
                  className="nav-link"
                  to="/products"
                >
                  Products
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="menu_active"
                  className="nav-link"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="menu_active"
                  className="nav-link"
                  to="/privacy-statement"
                >
                  Privacy Statement
                </NavLink>
              </li>
              {!isAuthorized ? (
                <li className="nav-item">
                  <NavLink className="nav-link nav-link-login" to="/auth/login">
                    login
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink className="nav-link nav-link-login" to="/dashboard">
                    Dashboard
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
