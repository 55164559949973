import React from 'react';
import HomeBg from "../common/assets/Home-background-2x.jpg";
import ItwBusinessModel from "../common/assets/ITW BUSINESS MODEL.svg";
import BusinessSegment from "../common/assets/BUSINESS SEGMENTS.svg";
import EnterprisesStrategy from "../common/assets/ENTERPRISE STRATEGY.svg";
import section1 from "../common/assets/section1.jpg";
import section2 from "../common/assets/section2.jpg";
import ItwBlog1 from "../common/assets/blog1.jpg";
import ItwBlog2 from "../common/assets/blog2.jpg";
import Banner from "./Banner";
import ThreeSection from "./ThreeSection";
import OurStories from "./OurStories";
import Navbar from './Navbar';
import Footer from './Footer';
const Home = () => {

  return (
    <>
    <Navbar/>
         <div className="container-fluid px-0">
            <div className="row gx-0">
                <div class="col-md-12 mx-auto">
                    <div class="position-relative">
                        <div className="Banner">
                            <img className="img-fluid" src={HomeBg}/> 
                        </div>
                        <div className="row position-absolute gx-0">
                            <div className=" col-auto banner-content ms-auto ps-8">
                                <h2 style={{fontSize:35}}><strong>Discover ITW</strong></h2>
                                <p className="mt-4">A global industrial company built around a
differentiated business model.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="three-section mt-2" >
            <div className="container">
                <div className="row">
                    <ThreeSection 
                    imgsrc={ItwBusinessModel}
                    heading="ITW BUSINESS MODEL"
                    paragraph="The ITW Business Model is our company's defining competitive advantage.the ITW Business Model to generate solid growth."
                    />
                    
                    <ThreeSection 
                    imgsrc={BusinessSegment}
                    heading="BUSINESS SEGMENTS"
                    paragraph="ITW's seven industry-leading segments leverage the ITW Business Model to generate solid growth with best-in-class margins and returns."
                    />
                    <ThreeSection 
                    imgsrc={EnterprisesStrategy}
                    heading="ENTERPRISE STRATEGY"
                    paragraph="Our long-term enterprise strategy is focused on leveraging the performance power of the ITW Business Model."
                    />
                    
                </div>
            </div>
        </section>
        <section className="mt-4">
        <div className="container-fluid">
            <div className="row">
               <div className="col-md-6 px-0">
                   <img className="img-fluid" src={section1}/>
               </div>
               <div className="col-md-5 align-self-center mx-4">
                   <h3 style={{fontSize:26}}><strong>ABOUT ITW</strong></h3>
                   <p className="paragraph-text">Founded in 1912, ITW is a global industrial company built around a 
                   differentiated and proprietary business model. The company's seven industry-leading 
                   segments leverage the ITW Business Model to generate solid growth with best-in-class
                    margins and returns. We are committed to executing our enterprise strategy to deliver
                    differentiated performance over the long term and firmly establishing ITW as one of the best-performing, 
                   highest-quality and most-respected industrial companies.</p>
                   <a href="#" className="btn btn-default"><span>Discover ITW</span></a>
               </div>
            </div>
        </div>
        </section>

        <section className="mt-5 my-5">
        <div className="container-fluid">
            <div className="row justify-content-end">
               <div className="col-md-5 align-self-center">
                   <h3 style={{fontSize:26}}><strong>Ignite Your Full Potential Today</strong></h3>
                   <p className="paragraph-text">Whether you are early in your career or an experienced general manager, there are boundless 
                    career opportunities across our
                    more than 80 global divisions to ignite your full potential. Learn more about working at ITW</p>
                   <a href="#" className="btn btn-default mx-4"><span>Search Jobs</span></a>
                   <a href="#" className="btn btn-default"><span>Working At ITW</span></a>
               </div>
               <div className="col-md-6 px-0">
                   <img className="img-fluid" src={section2}/>
               </div>
            </div>
        </div>
        </section>

        <section className="our-stories mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div class="heading">
                            <h3 className="stories"><strong> Our Stories</strong></h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-around">
                    <OurStories 
                        imgsrc={ItwBlog1}
                        heading="Inclusive, Entrepreneurial, Empowered"
                        paragraph="As a Vice President/General Manager of an ITW business, my job is to 
                                    position our people, our products and our customers to win."
                    />
                    <OurStories 
                        imgsrc={ItwBlog2}
                        heading="Revolutionizing Construction Sites By Focusing On Our Customers"
                        paragraph="Over 25 years ago, ITW’s Paslode division invented the first cordless nailer, often called 
                        a nail gun. This product innovation provided contractors, 
                        builders and remodelers with the ultimate solution in both productivity and convenience."
                    />      
                </div>
                
            </div>
        </section>
    <Footer/>
    </>
  )
}
export default Home;
