import React,{useState} from 'react'
import CreateEmployeeForm from './form';

const CreateCustomer = (props) => {

    const [formData, setFormData] = useState({
        first_name:'',
        last_name:'',
        email:'',
        password:'',
        confirm_password:'',
    });
    

    return (
        <>
          <CreateEmployeeForm data={formData}/>
        </>
    )
}

export default CreateCustomer
