import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import EditProductRoleForm from './form';
const EditProductRole = (props) => {
    const [formData, setFormData] = useState([]);

    useEffect(() => {
      const id = props.match.params.id;
      console.log(id)
      const ApiUrl = API+"productrole/"+id;
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        console.log(formData)
        const fetchProductRole = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData)=>{
          console.log(responseData)
            setFormData(responseData.Results) ;
            });
      };
      fetchProductRole();
    },[]);

    return (
        <>
            { formData && (
            <EditProductRoleForm data={formData} />
            )
            }
        </>
    )
}

export default EditProductRole
