import React from 'react';
import "./UseStyles.css";


const ThreeSection = (props) => {
  const mystyle = {
    marginTop:"50px"
 };
  const mystyle1 = {
     fontSize:"22px",
  };
  const mystyle2 = {
    marginTop:"20px",
    lineHeight:"28px"
 };
  return (
    <>
        <div className="col-md-4 text-center">
            <div className="p-5">
                <img style={mystyle}src={props.imgsrc} />
                <h5 style={mystyle1}>{props.heading}</h5>
                <p style={mystyle2}>{props.paragraph}</p>
            </div>
        </div>
    </>
  )
}
export default ThreeSection;
