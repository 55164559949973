import React, { useState } from "react";
import CreateLandingForm from "./form";

const CreateLanding = (props) => {
  const [formData, setFormData] = useState({
    type: "",
    product_id: "",
    name: "",
    thumbnail: "",
  });

  return (
    <>
      <CreateLandingForm data={formData} />
    </>
  );
};

export default CreateLanding;
