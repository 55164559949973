import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { API } from "../../../common";
import axios from "axios";
import UseStyles from "./UseStyles";
import headLogo from "../common/assets/ITW-logo-small.png";
import "../common/CSS/style.css";
import EnhancedTableHead from './EnhancedTableHead';
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
    }

    function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
    }

    function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
    }


    export default function ProductRoleList(props) {
    const classes = UseStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [showLoading, setShowLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState([]);

    useEffect(() => {
        const apiUrl = API+"productrole";
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        const fetchData = async() => {
          var postData = {search:search};
          await axios.post(apiUrl,postData,{axiosConfig}).then(response => response.data)
          .then((responseData)=>{
            setShowLoading(false);
            setRows( responseData.Results) ;
          });
        };
        fetchData();
      }, [search])

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);



    const editProductRole = (id) => {
        props.history.push({
        pathname: "/product-role/edit/" + id,
        });
    };


    return (
        <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
    >
        <div className={classes.listroot}>
        <div className="headLogo">
            <h4>Product Role</h4>
            <img src={headLogo} alt="headLogo" />
        </div>
        <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
                <div class="card-title">
                    <h3 class="card-label">Product Role List</h3>
                </div>
            
            </div>
            {/* <SearchForm onSearch={handleSearch} /> */}
            <div class="card-body pb-0">
                <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle" size="medium">

                <EnhancedTableHead 
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />

                <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    

                    const labelId = `enhanced-table-checkbox-${index}`;

                    const newPage = page+1;
                    const newIndex = index+1;
                    const srno = newIndex +((newPage-1)*rowsPerPage);


                    return (
                        <TableRow
                        hover
                        key={row.id}
                        >
                        <TableCell >
                        {srno}
                        </TableCell>
                        <TableCell
                            component="th"
                            align="left"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                            {row.name}
                        </TableCell>

                        <TableCell padding="none" align="left">
                        {row.email}
                        </TableCell>
                        <TableCell padding="none" align="left">
                        {row.type}
                        </TableCell>
                        <TableCell align="left">
                            <div className="action-btn justify-content-start">
                            <a
                                className={classes.editBtn}
                                onClick={() => {
                                editProductRole(row.id);
                                }}
                            >
                                <EditIcon className="edit-btn" />
                            </a>
                            </div>
                        </TableCell>
                        </TableRow>
                    );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </div>
            </div>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
                "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>        
        </div>
        </LoadingOverlay>
    );
}
