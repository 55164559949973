import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding:'20px'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin:'10px 0px',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '97%',
    },
    margin:{
        margin:'15px'
    },
    actionBtn : {
        padding:'2px 4px',
        backgroundColor:'#910039',
        color:'#FFFFFF',
        border:'none',
        marginLeft:'5px'
    },
    cancelBtn : {
        marginLeft:'10px'
    },
    datePicker:{
        width:'108px',
        border: 'none',
        borderBottom: '2px dotted #910039'
    }
    }));
    
export default UseStyles;