import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios';
import UseFormStyles from './UseFormStyles';
import Paper from '@material-ui/core/Paper';
import headLogo from '../common/assets/ITW-logo-small.png'
import '../common/CSS/style.css'
import {API} from '../../../common';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Container } from '@material-ui/core';
import Validator from "validatorjs";

const Confirm = () => {
    const classes = UseFormStyles();
    const [approveData, setApproveData] = useState([])
    const [disapproveData, setDisapproveData] = useState([])
    const [questions, setQuestions] = useState([]);
    const [errors, setErrors] = useState([])
    const {id} = useParams()

    const handleChangeApprove = (name) => (event) => {
        setApproveData({ ...approveData, [name]: event.target.value });
    };

    const handleChangeDisapprove = (name) => (event) => {
        setDisapproveData({ ...disapproveData, [name]: event.target.value });
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token"),
        },
    };

    const validateApproveForm = () => {
    
        let temp = { ...errors }    

        let data = {
            department_id: approveData.department_id,
            system_id: approveData.system_id,
            location_id : approveData.location_id
        };
    
        let rules;

        if (id)
        {
            rules = {
                department_id: "required",
                system_id:"required",
                location_id:"required"
            };

        }
        else {
            rules = {
                department_id: "required",
                system_id:"required",
                location_id:"required"
            };
        }
        
        
        let validation = new Validator(data, rules, {
            "required.department_id": "Department is required.",
            "required.system_id": "System is required.",
            "required.location_id": "Location is required.",
        });

        if (validation.passes()) {
            return true;
        }
        if (validation.fails()) {
            temp.department_id = validation.errors.first("department_id");
            temp.system_id = validation.errors.first("system_id");
            temp.location_id = validation.errors.first("location_id");
            setErrors({
                ...temp
            })
        //alert(temp.first_name);
        }
    }


    const validateDisapproveForm = () => {
    
        let temp = { ...errors }    

        let data = {
            reason: disapproveData.reason,
        };
    
        let rules;

        if (id)
        {
            rules = {
                reason: "required",
            };

        }
        else {
            rules = {
                reason: "required",
            };
        }
        
        
        let validation = new Validator(data, rules, {
            "required.reason": "Reason is required."
        });

        if (validation.passes()) {
            return true;
        }
        if (validation.fails()) {
            temp.reason = validation.errors.first("reason");
            setErrors({
                ...temp
            })
        //alert(temp.first_name);
        }
    }

    const apiApproveUpdate = API + "users/user_key_manager_approve";
    const apiDisapproveUpdate = API + "users/user_key_manager_disapprove";

    const approve =  async() => {
        if(validateApproveForm())
        {
            var postData = {
                "id"  : id,
                "question1" : approveData.department_id,
                "question2" : approveData.system_id,
                "question3" : approveData.location_id,
            };
            await axios.post(apiApproveUpdate,postData,{axiosConfig}).then(response => response.data)
            .then((responseData)=>{
                if(responseData.Status===false){
                    alert(responseData.Message);
                }
                else {
                    alert(responseData.Message);
                }
            });
        }
    }

    const disapprove =  async() => {
        if(validateDisapproveForm())
        {
            var postData = {
                "id"  : id,
                "reason" : disapproveData.reason
            };
            await axios.post(apiDisapproveUpdate,postData,{axiosConfig}).then(response => response.data)
            .then((responseData)=>{
                if(responseData.Status===false){
                    alert(responseData.Message);
                }
                else {
                    alert(responseData.Message);
                }
            });
        }
    }
 
    useEffect(() => {
        const approvalQuestionPostData = [];
        const approvalQuestionApiUrl = API + "users/customer_approval_question_list";
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token"),
          },
        };
        const fetchApprovalQuestion = async () => {
          await axios
            .post(approvalQuestionApiUrl, approvalQuestionPostData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
                setQuestions(responseData.Results)
            });
        };
        fetchApprovalQuestion();
    }, []); 

    return (
        <>
        <Container>
            <div className={classes.root}>
                <div className="headLogo">
                    <h4>Customer Approval / Disapproval</h4>
                    <img src={headLogo} alt="headLogo" />
                </div>

                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item xs={6} style={{textAlign:'center'}}>
                                <div style={{margin:'10px 0px'}}>
                                    <h6 style={{float:'left', margin:'8px 10px'}}><b>Department</b></h6>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        label="Select Department*"
                                        className={classes.textField}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        value={approveData.department_id || ''}
                                        onChange={handleChangeApprove("department_id")}
                                        fullWidth
                                        error={!!errors.department_id}
                                        helperText={errors.department_id}
                                    >
                                        <option value=""></option>
                                        {questions.filter(question => question.Department != null).map((question, index) => (
                                            <option key={question.id} value={question.Department}>
                                                {question.Department}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>

                                <div style={{margin:'10px 0px'}}>
                                    <h6 style={{float:'left', margin:'8px 10px'}}><b>System</b></h6>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        label="Select System*"
                                        className={classes.textField}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        value={approveData.system_id || ''}
                                        onChange={handleChangeApprove("system_id")}
                                        fullWidth
                                        error={!!errors.system_id}
                                        helperText={errors.system_id}
                                    >
                                        <option value=""></option>
                                        {questions.filter(question => question.System != null).map((question, index) => (
                                            <option key={question.id} value={question.System}>
                                                {question.System}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>

                                <div style={{margin:'10px 0px'}}>
                                    <h6 style={{float:'left', margin:'8px 10px'}}><b>Location</b></h6>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        label="Select Location*"
                                        className={classes.textField}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        value={approveData.location_id || ''}
                                        onChange={handleChangeApprove("location_id")}
                                        fullWidth
                                        error={!!errors.location_id}
                                        helperText={errors.location_id}
                                    >
                                        <option value=""></option>
                                        {questions.filter(question => question.Location != null).map((question, index) => (
                                            <option key={question.id} value={question.Location}>
                                                {question.Location}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>

                                <button
                                    type="button"
                                    style={{margin:'10px 0px'}}
                                    onClick={() => approve()}
                                    className="btnCreate"
                                    variant="contained"
                                >
                                    Approve
                                </button>
                            </Grid>
                        
                            <Grid item xs={6} style={{textAlign:'center'}}>
                                <div style={{margin:'10px 0px'}}>
                                    <p>Please write the reason of disapprovement in textfield below</p>
                                    <TextField
                                        id="textfield"
                                        className={classes.textField}
                                        type="text"
                                        label="Disapprove Reason ?"
                                        variant="outlined"
                                        onChange={handleChangeDisapprove("reason")}
                                        fullWidth
                                        multiline
                                        error={!!errors.reason}
                                        helperText={errors.reason}
                                    />
                                </div>

                                <button 
                                    type="button"
                                    style={{margin:'10px 0px'}}
                                    onClick={() => disapprove()}
                                    className="btnCreate"
                                    variant="contained"
                                >
                                    Disapprove
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            </Container>
        </>
    )
}

export default Confirm
