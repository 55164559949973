import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UseFormStyles from "./UseFormStyles";
import Paper from "@material-ui/core/Paper";
import headLogo from "../common/assets/ITW-logo-small.png";
import "../common/CSS/style.css";
import { API } from "../../../common";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import { Container } from "@material-ui/core";
import Validator from "validatorjs";

const Confirm = () => {
  const classes = UseFormStyles();
  const [approveData, setApproveData] = useState([]);
  const [disapproveData, setDisapproveData] = useState([]);
  const { id } = useParams();
  const [errors, setErrors] = useState([])


  const handleChangeApprove = (name) => (event) => {
    setApproveData({ ...approveData, [name]: event.target.value });
  };

  const handleChangeDisapprove = (name) => (event) => {
    setDisapproveData({ ...disapproveData, [name]: event.target.value });
  };

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: localStorage.getItem("token"),
    },
  };

  const validateDisapproveForm = () => {
    
    let temp = { ...errors }    

    let data = {
        reason: disapproveData.reason,
    };

    let rules;

    if (id)
    {
        rules = {
            reason: "required",
        };

    }
    else {
        rules = {
            reason: "required",
        };
    }
    
    
    let validation = new Validator(data, rules, {
        "required.reason": "Reason is required."
    });

    if (validation.passes()) {
        return true;
    }
    if (validation.fails()) {
        temp.reason = validation.errors.first("reason");
        setErrors({
            ...temp
        })
    //alert(temp.first_name);
    }
  }

  const apiApproveUpdate = API + "users/user_engineering_manager_approve";
  const apiDisapproveUpdate = API + "users/user_engineering_manager_disapprove";

  
  const approve = async () => {
    var postData = {
      id: id,
    };
    await axios
      .post(apiApproveUpdate, postData, { axiosConfig })
      .then((response) => response.data)
      .then((responseData) => {
        console.log(responseData);
        if (responseData.Status === false) {
          alert(responseData.Message);
        } else {
          alert(responseData.Message);
        }
      });
  };

  const disapprove = async () => {
    if(validateDisapproveForm())
    {
      var postData = {
        id: id,
        reason: disapproveData.reason,
      };
      await axios
        .post(apiDisapproveUpdate, postData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          console.log(responseData);

          if (responseData.Status === false) {
            alert(responseData.Message);
          } else {
            alert(responseData.Message);
          }
      });
    }
  };

  const [value, setValue] = React.useState("");

  return (
    <>
      <Container>
        <div className={classes.root}>
          <div className="headLogo">
            <h4>Customer Approval / Disapproval</h4>
            <img src={headLogo} alt="headLogo" />
          </div>

          <Paper className={classes.paper}>
            <Radio
              checked={value === "approve"}
              onChange={(e) => setValue(e.target.value)}
              value="approve"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
              // onChange={(e) => setValue(e.target.value)}
            />
            <label for="approve">Approve</label>

            <Radio
              checked={value === "disapprove"}
              onChange={(e) => setValue(e.target.value)}
              value="disapprove"
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
            />
            <label for="approve">Disapprove</label>

            {value === "approve" ? (
              <div>
                <button
                  type="button"
                  onClick={() => approve()}
                  className="btnCreate"
                  variant="contained"
                  style={{ margin: "10px 5px" }}
                >
                  Approve
                </button>
              </div>
            ) : value === "disapprove" ? (
              <div>
                <TextField
                  id="textfield"
                  className={classes.textField}
                  type="text"
                  label="Disapprove Reason ?"
                  variant="outlined"
                  onChange={handleChangeDisapprove("reason")}
                  fullWidth
                  multiline
                  error={!!errors.reason}
                  helperText={errors.reason}
                />
                <button
                  type="button"
                  onClick={() => disapprove()}
                  className="btnCreate"
                  variant="contained"
                  style={{ margin: "10px 5px" }}
                >
                  Disapprove
                </button>
              </div>
            ) : null}
          </Paper>
        </div>
      </Container>
    </>
  );
};

export default Confirm;
