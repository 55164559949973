import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import EditOrganisationForm from './form';

const EditOrganisation = (props) => {
    const [formData, setFormData] = useState([]);
    const [resultData, setResultData] = useState([]);

    
    useEffect(() => {
      const id = props.match.params.id;
      const ApiUrl = API+"organizations/"+id;
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        const fetchFastener = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData)=>{
          console.log(responseData)
            setFormData(responseData.Results) ;
            setResultData(responseData.Results.KeyManagers[0])
        });
      };
      fetchFastener();
    },[]);

    // console.log(imagePath)
    return (
        <>
          { formData && (
            <EditOrganisationForm data={formData} resultdata={resultData}/>
            )
          }
        </>
    )
}

export default EditOrganisation
