import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';


const Products = () => {

  return (
    <>
    <Navbar/>
  <h1>Welcome! Products</h1>
    <Footer/>
    </>
  )
}
export default Products;
