import { defaults } from 'chart.js';
import React,{useEffect,useState} from 'react';
import {Line} from 'react-chartjs-2';
import {API} from '../../../common';
import axios from 'axios';
function ProductLineChart (){

    const [title,setTitle] = useState([])
    const [userData,setUserData]= useState([])

    useEffect(()=>{
        const fetchData = async() =>{
            const token = localStorage.getItem("token");
            const apiUrl = API+"products/product_count_graph";
            let axiosConfig = {
                headers : {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization:token[1]
                }
            };
            var postData = {};
            const result = await axios.post(apiUrl,postData,{axiosConfig})
            .then(response => response.data)
            .then((responseData)=>{
                var data = responseData.Results
                
                let response_title=[];
                let response_data=[];
                for (const item of data){
                    response_title.push(item.part_number_customer)
                    response_data.push(item.count)
                }
                setTitle(response_title);
                setUserData(response_data);
            });
        };
        fetchData();
    },[]);



        const data = {
            labels: title,
            datasets: [
            {
                label: 'Product Count',
                data: userData ,
                interpolation: "spline",
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
                lineTension: 0,
                radius: 5
            }
        ]
        } 
        const options = {
            plugins: {
                title: {
                    display: true,
                    text: 'Most Viewed Product',
                    padding:{
                        
                        bottom:10
                    },
                    font:{
                        size:16
                    }
                }
            },
                scales : {
                    y:{
                        min:0,
                        
                        title: {
                            display: true,
                            text:'product Count',
                            padding:{
                                right:10
                            },
                                font: {
                                    size: 12,
                                    
                                },
                        }
                    },
                    x:{
                        title: {
                            display: true,
                            text: 'Customer Part Number',
                            padding:{
                                top:10,
                                bottom:10
                            }
                        }
                    }
                },
                
        }

    return(
        <>

            <Line
            height={"224"}
            data={data}
            options ={options}
            />
        </>
    );

}
export default ProductLineChart