import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CustomerLineChart from "./CustomerLineChart";
import ProductLineChart from "./ProductLineChart";
import Paper from "@material-ui/core/Paper";
import UseStyles from "./UseStyles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

export function Dashboard() {
  const classes = UseStyles();
  let UserType = JSON.parse(localStorage.getItem("data"));

  return (
    <>
      <div>
        <h1>
          {UserType.type == "admin"
            ? "Welcome To ITW Global Admin Portal"
            : UserType.type == "data_entry"
            ? "Welcome To ITW Global Data Entry Operator Portal"
            : ""}
        </h1>
      </div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid className="pl-0" item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <CustomerLineChart />
            </Paper>
          </Grid>

          <Grid className="pr-0" item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <ProductLineChart />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
