import React, { Suspense, lazy } from "react";
import { useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import {
  CustomerList,
  CreateCustomer,
  EditCustomer,
  CustomerKeyManagerApproval,
  CustomerEngineeringManagerApproval,
  EmployeeList,
  EmployeeCreate,
  EditEmployee,
  Upload,
  ProductList,
  ProductPending,
  ProductDeletePending,
  ProductDisapproved,
  ProductCsv,
  CreateProduct,
  EditProduct,
  RequestedSampleList,
  Settings,
  Fastener,
  CreateFastener,
  EditFastener,
  ApplicationArea,
  CreateApplicationArea,
  EditApplicationArea,
  LandingList,
  CreateLanding,
  EditLanding,
  Category,
  CreateCategory,
  EditCategory,
  SubCategory,
  CreateSubCategory,
  EditSubCategory,
  Organisation,
  CreateOrganisation,
  EditOrganisation,
  OrganisationDetails,
  PainPointList,
  CreatePainPoint,
  EditPainPoint,
  ClientManagerList,
  UploadVideoList,
  CreateUploadVideo,
  EditUploadVideo,
  ContactUsList,
  ContactUsDetails,
  ProductContactUsList,
  ProductContactUsDetails,
  ProductRoleList,
  EditProductRole,
  DataEntryOperatorList,
  CreateDataEntryOperator,
  EditDataEntryOperator,
  DisapprovedReason,
} from "./pages/RouteExports";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // const user = localStorage.getItem("data");
    const user = JSON.parse(localStorage.getItem("data"));
    setUserData(user.type);
  }, []);

  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/products/list" component={ProductList} />
        <Route path="/products/productCsv" component={ProductCsv} />
        <Route path="/products/pending" component={ProductPending} />
        <Route
          path="/products/delete-pending"
          component={ProductDeletePending}
        />
        <Route path="/products/disapproved" component={ProductDisapproved} />
        <Route path="/products/create" component={CreateProduct} />
        <Route path="/products/edit/:id" component={EditProduct} />
        <Route path="/products/reason/:id" component={DisapprovedReason} />

        {userData === "admin" ? (
          <Switch>
            <Route path="/customers/list" component={CustomerList} />
            <Route path="/customers/create" component={CreateCustomer} />
            <Route path="/customers/edit/:id" component={EditCustomer} />
            {/* <Route path="/customers/key-manager/approval/:id" component={CustomerKeyManagerApproval} /> */}
            {/* <Route path="/customers/engineering-manager/approval/:id" component={CustomerEngineeringManagerApproval} /> */}
            <Route path="/employees/list" component={EmployeeList} />
            <Route path="/employees/create" component={EmployeeCreate} />
            <Route path="/employees/edit/:id" component={EditEmployee} />
            <Route path="/upload" component={Upload} />
            <Route
              path="/request-sample/list"
              component={RequestedSampleList}
            />
            <Route path="/user-profile" component={UserProfilepage} />
            <Route path="/settings" component={Settings} />
            <Route path="/fastener/list" component={Fastener} />
            <Route path="/fasteners/create" component={CreateFastener} />
            <Route path="/fasteners/edit/:id" component={EditFastener} />
            <Route path="/application-area/list" component={ApplicationArea} />
            <Route
              path="/application-area/create"
              component={CreateApplicationArea}
            />
            <Route
              path="/application-area/edit/:id"
              component={EditApplicationArea}
            />
            <Route path="/landings/list" component={LandingList} />
            <Route path="/landings/create" component={CreateLanding} />
            <Route path="/landings/edit/:id" component={EditLanding} />
            <Route path="/category/list" component={Category} />
            <Route path="/category/create" component={CreateCategory} />
            <Route path="/category/edit/:id" component={EditCategory} />
            <Route path="/sub-category/list" component={SubCategory} />
            <Route path="/sub-category/create" component={CreateSubCategory} />
            <Route path="/sub-category/edit/:id" component={EditSubCategory} />
            <Route path="/organisation/list" component={Organisation} />
            <Route path="/organisation/create" component={CreateOrganisation} />
            <Route path="/organisation/edit/:id" component={EditOrganisation} />
            <Route
              path="/organisation/details/:id"
              component={OrganisationDetails}
            />
            <Route path="/pain-point/list" component={PainPointList} />
            <Route path="/pain-point/create" component={CreatePainPoint} />
            <Route path="/pain-point/edit/:id" component={EditPainPoint} />
            <Route path="/client-manager/list" component={ClientManagerList} />
            <Route path="/upload-video/list" component={UploadVideoList} />
            <Route path="/upload-video/create" component={CreateUploadVideo} />
            <Route path="/upload-video/edit/:id" component={EditUploadVideo} />
            <Route path="/contact-us/list" component={ContactUsList} />
            <Route
              path="/contact-us/details/:id"
              component={ContactUsDetails}
            />
            <Route
              path="/product-contact-us/list"
              component={ProductContactUsList}
            />
            <Route
              path="/product-contact-us/details/:id"
              component={ProductContactUsDetails}
            />
            <Route path="/product-role/list" component={ProductRoleList} />
            <Route path="/product-role/edit/:id" component={EditProductRole} />
            <Route path="/data-entry/list" component={DataEntryOperatorList} />
            <Route
              path="/data-entry/create"
              component={CreateDataEntryOperator}
            />
            <Route
              path="/data-entry/edit/:id"
              component={EditDataEntryOperator}
            />
            <Route
              path="/product-contact-us/list"
              component={ProductContactUsList}
            />
            <Route
              path="/product-contact-us/details/:id"
              component={ProductContactUsDetails}
            />
          </Switch>
        ) : (
          ""
        )}
      </Switch>
    </Suspense>
  );
}
