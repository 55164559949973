import React,{useEffect,useState,useRef} from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import {API} from '../../../common';
import UseStyles from './UseStyles';
import TextField from '@material-ui/core/TextField'
import '../common/CSS/style.css'

const SearchForm = (props) => {

    const classes = UseStyles();

    const [search, setSearch] = React.useState({});
    
    const searchHandleChange = name => event => {
      setSearch({ ...search, [name]: event.target.value });
    };
  
    const formSubmit = async () => {
      props.onSearch(search)
    }


      return(
        <>
          <div className={classes.container}>

            <div className={classes.actionBtn}>
                {/* <button 
                  onClick={() => formSubmit()}
                  className="btnCreate"
                  variant="contained"
                >
                  Search
                </button> */}

              <NavLink className={classes.cancelBtn} to="/upload-video/create">
                <button className="btn btn-primary">
                  Create Product Video
                </button>
              </NavLink>
            </div>
          </div> 
        </>
      ); 
  }
  export default SearchForm;
