import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UseFormStyles from "./UseFormStyles";
import Paper from "@material-ui/core/Paper";
import logoBig from "../common/assets/ITW-logo-big.png";
import "../common/CSS/style.css";
import { API } from "../../../common";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import { Container } from '@material-ui/core';

const Confirm = () => {
  const classes = UseFormStyles();
  const [approveData, setApproveData] = useState([]);
  const [disapproveData, setDisapproveData] = useState([]);
  const { id } = useParams();
  console.log(id);

  const handleChangeDisapprove = (name) => (event) => {
    setDisapproveData({ ...disapproveData, [name]: event.target.value });
  };

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: localStorage.getItem("token"),
    },
  };

  const apiDisapproveUpdate = API + "products/disapproved";

  const disapprove = async () => {
    var postData = {
      id: id,
      reason: disapproveData.reason,
    };
    await axios
      .post(apiDisapproveUpdate, postData, { axiosConfig })
      .then((response) => response.data)
      .then((responseData) => {
        console.log(responseData);

        if (responseData.Status === false) {
          alert(responseData.Message);
        } else {
          alert(responseData.Message);
        }
      });
  };

  const [value, setValue] = React.useState("");

  return (
    <>
    <Container>
      <div className={classes.root}>
        <div className="text-center">
          
          <img className="mt-2" src={logoBig} alt="ITW Logo" />
          <h2 className="mt-6">Product Disapproval</h2>
        </div>
        <div class="d-flex justify-content-center mt-6">
          <Paper className={classes.paper}>
        

            {/* <Radio
              checked={value === "b"}
              onChange={(e) => setValue(e.target.value)}
              value="b"
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
              />
            <label className="h6 pt-2" for="approve">Disapproved</label> */}

            {/* {value === "a" ? ( */}
              {/* <div>
                <button
                  type="button"
                  // onClick={() => approve()}
                  className="btn btn-primary mt-4 "
                  variant="contained"
                >
                  Approve
                </button>
              </div>
                ) : value === "b" ? ( */}
              <div>
                <TextField
                  id="textfield"
                  className="mt-4"
                  type="text"
                  label="Disapproved Reason ?"
                  variant="outlined"
                  onChange={handleChangeDisapprove("reason")}
                  fullWidth
                />
                <div class="d-flex justify-content-center">
                
                    <button
                      type="button"
                      onClick={() => disapprove()}
                      className="btn btn-primary mt-4"
                      variant="contained"
                    >
                      Disapproved
                    </button>
                </div>
              </div>
            {/* ) : null} */}
          </Paper>
      
        </div>
      </div>
    </Container>
    </>
  );
};

export default Confirm;
