import React,{useState} from 'react'
import CreateProductVideoForm from './form';

const CreateApplicationArea = (props) => {

    const [formData, setFormData] = useState({});
    

    return (
        <>
            <CreateProductVideoForm data={formData}/>
        </>
    )
}

export default CreateApplicationArea
