import React,{useState,useEffect} from 'react'
import {withRouter, NavLink} from "react-router-dom";
import axios from 'axios';
import {API} from '../../../common';
import TextField from '@material-ui/core/TextField';
import UseStyles from './UseStyles';
import Paper from '@material-ui/core/Paper';
import '../common/CSS/style.css'
import Validator from "validatorjs";

const CreatePainPoint = (props) => {

    const classes = UseStyles();
    const [errors, setErrors] = useState([]);
    const [state, setState] = useState({
      name:'',
    });

    useEffect(() => {
      setState(props.data);
    }, [props.data]);


    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const validateForm = () => {
    
      let temp = { ...errors }
  
        let data = {
          name: state.name,
        };
      
        let rules;
  
        if (props.data.id)
        {
          rules = {
            name: "required",
          };
  
        }else {
          rules = {
            name: "required",
          };
        }
        
        
        let validation = new Validator(data, rules, {
          "required.name": "Pain Point Name is required.",
        });
  
        if (validation.passes()) {
          return true;
        }
        if (validation.fails()) {
          temp.name = validation.errors.first("name");

          setErrors({
            ...temp
          })
        }
  
    }


    const formSubmit = async() => {

      if(validateForm())
      {
        let axiosConfig = {
          headers : {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }
        }

          const apiAdd = API + "painpoint/pain_point_add"
          const apiUpdate = API + "painpoint/pain_point_edit"

          if (props.data.id)
          {
            const updatePainPoint = async() => {
              let postData = {
                "id"  : state.id,
                "name":state.name,
              }
              await axios.post(apiUpdate, postData, {axiosConfig}).then(response => response.data)
              .then((responseData) => {
                if(responseData.Status === false)
                {
                  alert(responseData.Message);
                }
                else {
                  alert(responseData.Message);
                  props.history.push({
                    pathname:"/pain-point/list",
                  });
                }
              })
            }
            updatePainPoint()
          }
          
        else
        {
            const addPainPoint = async() => {
                let postData = {
                "name":state.name,
            }
                await axios.post(apiAdd, postData, {axiosConfig}).then(response => response.data)
                .then((responseData) => {
                    if(responseData.Status === false)
                    {
                        alert(responseData.Message);
                    }
                    else 
                    {
                        alert(responseData.Message);
                        props.history.push({
                        pathname: "/pain-point/list",
                        });
                    }
                })
            }

            addPainPoint()
          }
      }
    }


    return (
        <>
        <div className={classes.root}>
        <Paper className={classes.paper}>
        <form className={classes.container} noValidate autoComplete="off" >
        <h2>{props.data.id == null ? 'Add Pain Point' : 'Edit Pain Point'}</h2>
            
            <TextField
                id="outlined-pain_point_name"
                label="Pain-Point Name*"
                className={classes.textField}
                value={state.name || ''}
                onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
            />



            <div class="container">
            <div class="row">
                <button 
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                >
                    {props.data.id == null ? 'Submit' : 'Submit'}
                    
                </button>
                <NavLink className={classes.cancelBtn} to="/pain-point/list">
                    <button 
                        className="btn btn-outline-primary"
                        variant="contained"
                    >
                        Cancel
                    </button>
                </NavLink>
            </div>
            </div>
        </form>
        </Paper>
        </div>
        </>
    )
}

export default withRouter(CreatePainPoint)
