import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import UseStyles from "./UseStyles";
import headLogo from '../common/assets/ITW-logo-small.png'
import Paper from '@material-ui/core/Paper';
import { NavLink} from "react-router-dom";
import Validator from "validatorjs";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import KeyManagerIndex from './KeyManagerIndex'
import {useParams} from 'react-router-dom'

export default function OrganisationDetail(props) {
    const classes = UseStyles();
    const [errors, setErrors] = useState([]);
    const [state, setState] = useState({
      key_account_manager_name:'',
      key_account_manager_email:'',
      assign_role:''
    });
    const [role, setRole] = useState({})
    const [organisation, setOrganisation] = useState({})
    
    // useEffect(() => {
    //   setState(props.data);
    // }, [props.data]);
    console.log(state)

    // console.log(props.data)
    const {id} = useParams()

    useEffect(() => {
        const id = props.match.params.id;
        const ApiUrl = API+"organizations/"+id;
        let axiosConfig = {
            headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
            }
        };
        const fetchOrganisationDetails = async() => {
            await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
            .then((responseData)=>{
                setOrganisation(responseData.Results)
                setState(responseData.Results.KeyManagers[0])
            });
        };
        fetchOrganisationDetails();
    },[]);

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const onInputChange = name => event => {
      setRole({...role, [name]: event.target.checked  });
    }

    const validateForm = () => {
    
      let temp = { ...errors }
  
        let data = {
          key_account_manager_name:state.key_account_manager_name,
          key_account_manager_email:state.key_account_manager_email,
        };
        
        let rules = {
            key_account_manager_name:"required",
            key_account_manager_email:"required|email",
        };
        
        let validation = new Validator(data,rules, {
          "required.key_account_manager_name":"Key Account Manager Name is required",
          "required.key_account_manager_email":"Key Account Manager Email is required",
        });
    
        if (validation.passes()) {
          return true;
        }
        if (validation.fails()) {
          temp.key_account_manager_name = validation.errors.first("key_account_manager_name");
          temp.key_account_manager_email = validation.errors.first("key_account_manager_email");
          setErrors({
            ...temp
          })
        }
  
    }


    const formSubmit = async() => {

      if(validateForm())
      {
        let axiosConfig = {
          headers : {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }
        }

        const apiAdd = API + "organizations/key_manager_add"
        // const apiUpdate = API + "organizations/organisation_edit"

        const addKeyManager = async() => {
            let postData = {
                "organization_id":props.match.params.id,
                "key_account_manager_name":state.key_account_manager_name,
                "key_account_manager_email":state.key_account_manager_email,
                "assign_role":role.assign_role
            }
            console.log(postData)
            await axios.post(apiAdd, postData, {axiosConfig}).then(response => response.data)
            .then((responseData) => {
                if(responseData.Status === false)
                {
                    alert(responseData.Message);
                }
                else 
                {
                    alert(responseData.Message);
                    window.location.reload()
                }
            })
        }

        addKeyManager()
      }
    }

    
  return (

    <>
        <div className="headLogo">
            <h4>{organisation.name} Organisation Details</h4>
            <img src={headLogo} alt="headLogo" />
        </div>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
                <KeyManagerIndex/>
            </Grid>
            
            <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>
                    <form className={classes.container} noValidate autoComplete="off" >
                    <h4>Add Key Manager</h4>
                    <TextField
                        id="outlined-key_account_manager_name"
                        label="Key Acoount Manager Name*"
                        className={classes.textField}
                        // value={state.key_account_manager_name || ''}
                        onChange={handleChange('key_account_manager_name')}
                        margin="normal"
                        variant="outlined"
                        error={!!errors.key_account_manager_name}
                        helperText={errors.key_account_manager_name}
                    />

                    <TextField
                        id="outlined-key_account_manager_email"
                        label="Key Account Manager Email*"
                        className={classes.textField}
                        // value={state.key_account_manager_email || ''}
                        onChange={handleChange('key_account_manager_email')}
                        margin="normal"
                        variant="outlined"
                        error={!!errors.key_account_manager_email}
                        helperText={errors.key_account_manager_email}
                    />

                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox checked={role.assign_role} onChange={onInputChange('assign_role')} value="assign_role" />
                            }
                            label="Wants To Assign Escalation Role to this User ?"
                        />
                        
                        <button
                            type="button"
                            onClick={() => formSubmit()}
                            className="btnCreate"
                            variant="contained"
                        >
                            Submit
                        </button>
                        
                        <NavLink className={classes.cancelBtn} to="/organisation/list">
                            <button 
                                className="btnCreate"
                                variant="contained"
                            >
                                Cancel
                            </button>
                        </NavLink>
                    </div>
                </form>
                </Paper>
            </Grid>
        </Grid>
    </>
  );
}
