import React, { useState, useEffect } from "react";
import { withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import { API } from "../../../common";
import TextField from "@material-ui/core/TextField";
import UseStyles from "./UseStyles";
import Paper from "@material-ui/core/Paper";
import "../common/CSS/style.css";
import Validator from "validatorjs";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import validDomain from "is-valid-domain";

const CreateFastener = (props) => {
  const classes = UseStyles();
  const [errors, setErrors] = useState([]);
  const [result, setResult] = useState({});
  const [state, setState] = useState({
    name: "",
    domain: "",
    key_account_manager_name: "",
    key_account_manager_email: "",
    engineer_manager_name: "",
    engineer_manager_email: "",
    assign_role: "",
  });
  const [role, setRole] = useState(false);

  useEffect(() => {
    setState(props.data);
    setRole(props.data.KeyManagers?.[0].assign_role);
  }, [props.data]);

  useEffect(() => {
    setResult(props.resultdata);
  }, [props.data.id && props.resultdata]);

  console.log(result);
  useEffect(() => {
    if (props.data.id) {
      setResult(props.resultdata);
    }
  }, [props.resultdata]);

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleKeyChange = (name) => (event) => {
    setResult({ ...result, [name]: event.target.value });
  };

  const onInputChange = (name) => (event) => {
    setRole({ ...role, [name]: event.target.checked });
  };

  const validateForm = () => {
    let temp = { ...errors };

    let data = {
      name: state.name,
      domain: state.domain,
      key_account_manager_name: state.key_account_manager_name,
      key_account_manager_email: state.key_account_manager_email,
      engineer_manager_name: state.engineer_manager_name,
      engineer_manager_email: state.engineer_manager_email,
    };

    let rules;

    if (props.data.id) {
      rules = {
        name: "required",
        domain: "required",
        // key_account_manager_name:"required",
        // key_account_manager_email:"required|email",
        engineer_manager_name: "required",
        engineer_manager_email: "required|email",
      };
    } else {
      rules = {
        name: "required",
        domain: "required",
        key_account_manager_name: "required",
        key_account_manager_email: "required|email",
        engineer_manager_name: "required",
        engineer_manager_email: "required|email",
      };
    }

    let validation = new Validator(data, rules, {
      "required.name": "Organisation Name is required.",
      "required.domain": "Domain is required.",
      "required.key_account_manager_name":
        "Key Account Manager Name is required",
      "required.key_account_manager_email":
        "Key Account Manager Email is required",
      "required.engineer_manager_name": "Engineer Manager Name is required",
      "required.engineer_manager_email": "Engineer Manager Email is required",
    });

    if (validation.passes()) {
      return true;
    }
    if (validation.fails()) {
      temp.name = validation.errors.first("name");
      temp.domain = validation.errors.first("domain");
      temp.key_account_manager_name = validation.errors.first(
        "key_account_manager_name"
      );
      temp.key_account_manager_email = validation.errors.first(
        "key_account_manager_email"
      );
      temp.engineer_manager_name = validation.errors.first(
        "engineer_manager_name"
      );
      temp.engineer_manager_email = validation.errors.first(
        "engineer_manager_email"
      );
      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async () => {
    const checkDomain = validDomain(state.domain);
    if (checkDomain == false) {
      window.alert("Wrong Domain");
    } else {
      if (validateForm()) {
        let axiosConfig = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        };

        const apiAdd = API + "organizations/organisation_add";
        const apiUpdate = API + "organizations/organisation_edit";

        if (props.data.id) {
          const updateOrganisation = async () => {
            let postData = {
              id: state.id,
              name: state.name,
              domain: state.domain,
              key_account_manager_name: result.name,
              key_account_manager_email: result.email,
              engineer_manager_name: state.engineer_manager_name,
              engineer_manager_email: state.engineer_manager_email,
              assign_role: role.assign_role,
            };
            await axios
              .post(apiUpdate, postData, { axiosConfig })
              .then((response) => response.data)
              .then((responseData) => {
                if (responseData.Status === false) {
                  alert(responseData.Message);
                } else {
                  alert(responseData.Message);
                  props.history.push({
                    pathname: "/organisation/list",
                  });
                }
              });
          };
          updateOrganisation();
        } else {
          const addOrganisation = async () => {
            let postData = {
              name: state.name,
              domain: state.domain,
              key_account_manager_name: state.key_account_manager_name,
              key_account_manager_email: state.key_account_manager_email,
              engineer_manager_name: state.engineer_manager_name,
              engineer_manager_email: state.engineer_manager_email,
              assign_role: role.assign_role,
            };
            await axios
              .post(apiAdd, postData, { axiosConfig })
              .then((response) => response.data)
              .then((responseData) => {
                if (responseData.Status === false) {
                  alert(responseData.Message);
                } else {
                  alert(responseData.Message);
                  props.history.push({
                    pathname: "/organisation/list",
                  });
                }
              });
          };

          addOrganisation();
        }
      }
    }
  };

  // console.log(state.KeyManager.key_account_manager_email)

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form className={classes.container} noValidate autoComplete="off">
            <h2>
              {props.data.id == null ? "Add Organisation" : "Edit Organisation"}
            </h2>

            <TextField
              id="outlined-organisation_name"
              label="Organisation Name*"
              className={classes.textField}
              value={state.name || ""}
              onChange={handleChange("name")}
              margin="normal"
              variant="outlined"
              error={!!errors.name}
              helperText={errors.name}
            />

            <TextField
              id="outlined-organisation_domain"
              label="Domain*"
              className={classes.textField}
              value={state.domain || ""}
              onChange={handleChange("domain")}
              margin="normal"
              variant="outlined"
              error={!!errors.domain}
              helperText={errors.domain}
            />

            {props.data.id ? (
              <>
                <TextField
                  id="outlined-key_account_manager_name"
                  label="Key Acoount Manager Name*"
                  className={classes.textField}
                  value={result.name || ""}
                  onChange={handleKeyChange("name")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.key_account_manager_name}
                  helperText={errors.key_account_manager_name}
                  disabled={true}
                />

                <TextField
                  id="outlined-key_account_manager_email"
                  label="Key Account Manager Email*"
                  className={classes.textField}
                  value={result.email || ""}
                  onChange={handleKeyChange("email")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.key_account_manager_email}
                  helperText={errors.key_account_manager_email}
                  disabled={true}
                />
              </>
            ) : (
              <>
                <TextField
                  id="outlined-key_account_manager_name"
                  label="Key Acoount Manager Name*"
                  className={classes.textField}
                  value={state.key_account_manager_name || ""}
                  onChange={handleChange("key_account_manager_name")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.key_account_manager_name}
                  helperText={errors.key_account_manager_name}
                />

                <TextField
                  id="outlined-key_account_manager_email"
                  label="Key Account Manager Email*"
                  className={classes.textField}
                  value={state.key_account_manager_email || ""}
                  onChange={handleChange("key_account_manager_email")}
                  margin="normal"
                  variant="outlined"
                  error={!!errors.key_account_manager_email}
                  helperText={errors.key_account_manager_email}
                />
              </>
            )}

            <TextField
              id="outlined-engineer_manager_name"
              label="Engineer Manager Name*"
              className={classes.textField}
              value={state.engineer_manager_name || ""}
              onChange={handleChange("engineer_manager_name")}
              margin="normal"
              variant="outlined"
              error={!!errors.engineer_manager_name}
              helperText={errors.engineer_manager_name}
            />
            <TextField
              id="outlined-engineer_manager_email"
              label="Engineer Manager Email*"
              className={classes.textField}
              value={state.engineer_manager_email || ""}
              onChange={handleChange("engineer_manager_email")}
              margin="normal"
              variant="outlined"
              error={!!errors.engineer_manager_email}
              helperText={errors.engineer_manager_email}
            />

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    className="ml-3"
                    checked={role}
                    onChange={onInputChange("assign_role")}
                    value="assign_role"
                  />
                }
                label="Wants To Assign Escalation Role to this User ?"
              />
              <div className="container">
                <div className="row">
                  <button
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    {props.data.id == null ? "Submit" : "Submit"}
                  </button>
                  <NavLink
                    className={classes.cancelBtn}
                    to="/organisation/list"
                  >
                    <button
                      className="btn btn-outline-primary"
                      variant="contained"
                    >
                      Cancel
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(CreateFastener);
