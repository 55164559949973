import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles(theme => ({
    searchInputs : {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '20px 0px'
      },
      btnSearch: {
        backgroundColor: '#910039',
        color: '#FFFFFF',
        margin: '2px 0px',
        padding: '8px 30px',
        border: 'none',
      },
      btnCreate : {
        backgroundColor: '#910039',
        color: '#FFFFFF',
        margin: '2px 0px',
        padding: '8px 30px',
        border: 'none',
      },
      actionIcons: {
        display: 'flex',
        justifyContent: 'space-evenly'
      },
      headLogo: {
        display:'flex',
        justifyContent:'space-between',
        marginBottom:'25px'
      }
}));

export default UseStyles
