import React, { useState, useEffect, useRef } from "react";
import { withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import { API } from "../../../common";
import TextField from "@material-ui/core/TextField";
import UseStyles from "./UseStyles";
import Paper from "@material-ui/core/Paper";
import "../common/CSS/style.css";
import Validator from "validatorjs";
import Dropzone from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Cropper from "cropperjs";
import "../utils/imagecropper.css";

const CreateApplicationArea = (props) => {
  const imageElement = useRef();
  const classes = UseStyles();
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState({
    name: "",
    application_image: "",
    fastener_category_id: "",
  });
  const [imagePath, setImagePath] = useState();
  const [fasteners, setFasteners] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setState(props.data);
  }, [props.data]);

  useEffect(() => {
    setImagePath(props.imagepath);
  }, [props.imagepath]);

  console.log(props.imagepath);

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const imageFullPath = imagePath + state.application_image;

  const validateForm = () => {
    let temp = { ...errors };

    let data = {
      name: state.name,
      application_image: state.application_image,
      fastener_category_id: state.fastener_category_id,
    };

    let rules;

    if (props.data.id) {
      rules = {
        name: "required",
        application_image: "required",
        fastener_category_id: "required",
      };
    } else {
      rules = {
        name: "required",
        application_image: "required",
        fastener_category_id: "required",
      };
    }

    let validation = new Validator(data, rules, {
      "required.name": "Application Area Name is required.",
      "required.application_image": "Application Image is required.",
      "required.fastener_category_id": "Fastener is required.",
    });

    if (validation.passes()) {
      return true;
    }
    if (validation.fails()) {
      temp.name = validation.errors.first("name");
      temp.application_image = validation.errors.first("application_image");
      temp.fastener_category_id = validation.errors.first(
        "fastener_category_id"
      );

      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async () => {
    if (validateForm()) {
      setShowLoading(true);

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      const apiAdd = API + "applicationareas/application_area_add";
      const apiUpdate = API + "applicationareas/application_area_edit";

      if (props.data.id) {
        const updateApplicationArea = async () => {
          let formData = new FormData();
          formData.append("id", state.id);
          formData.append("application_image", state.application_image);
          formData.append("name", state.name);
          formData.append("fastener_category_id", state.fastener_category_id);

          await axios
            .post(apiUpdate, formData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/application-area/list",
                });
              }
            });
        };
        updateApplicationArea();
      } else {
        const addApplicationArea = async () => {
          let formData = new FormData();
          formData.append("application_image", state.application_image);
          formData.append("name", state.name);
          formData.append("fastener_category_id", state.fastener_category_id);

          await axios
            .post(apiAdd, formData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/application-area/list",
                });
              }
            });
        };

        addApplicationArea();
      }
    }
  };

  const [imageName, SetImageName] = useState("");
  const [imageDestination, SetImageDestination] = useState("");

  const [files, setFiles] = useState([]);

  const onDropfile = (acceptedFiles) => {
    let file = acceptedFiles[0];
    SetImageName(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // reader.onload = function() {

    //     setState({ ...state, ["application_image"]: file });
    //   };

    //   reader.onerror = function() {
    //     console.log(reader.error);
    //   };

    const cropper = new Cropper(imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination(canvas.toDataURL(file.type));
      },
    });
  };

  ///////////////////// base 64 to blob //////////////////////////////////

  useEffect(() => {
    if (imageDestination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob = converterDataURItoBlob(imageDestination);
      console.log(blob);
      if (blob.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function() {
          setState({ ...state, ["application_image"]: blob });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination]);
  /////////////////////////////////////////////////////////////////

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    const fastenerPostData = [];
    const fastenerApiUrl = API + "fastenercategories";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchFasterner = async () => {
      await axios
        .post(fastenerApiUrl, fastenerPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setFasteners(responseData.Results);
        });
    };
    fetchFasterner();
  }, []);

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              encType="multipart/form-data"
              noValidate
              autoComplete="off"
            >
              <h2>
                {props.data.id == null
                  ? "Add Application Area"
                  : "Edit Application Area"}
              </h2>

              <TextField
                id="outlined-application_area_name"
                label="Application Area Name*"
                className={classes.textField}
                value={state.name || ""}
                onChange={handleChange("name")}
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
              />

              <TextField
                id="outlined-select-currency-native"
                select
                label="Select Fastener*"
                className={classes.textField}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={state.fastener_category_id || ""}
                onChange={handleChange("fastener_category_id")}
                fullWidth
                error={!!errors.fastener_category_id}
                helperText={errors.fastener_category_id}
              >
                <option value=""></option>
                {fasteners.map((fastener, index) => (
                  <option key={fastener.id} value={fastener.id}>
                    {fastener.name}
                  </option>
                ))}
              </TextField>

              <Dropzone onDrop={(acceptedFiles) => onDropfile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone ml-3 mt-2">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' Drop application area image Here or click to
                        select
                        <br />
                        (Only 1 Image file)
                        <br />
                        <span>{imageName}</span>
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {errors && errors !== null ? (
                <small className={classes.errorsmall}>
                  {errors.application_image}
                </small>
              ) : (
                ""
              )}
              {files.map((file) => (
                <>
                  <div>
                    <div className="img-container">
                      <img
                        className="img-size"
                        ref={imageElement}
                        src={file.preview}
                        alt="Source"
                        crossorigin
                      />
                    </div>
                    <img
                      src={imageDestination}
                      className="img-preview"
                      alt="Destination"
                    />
                  </div>
                </>
              ))}

              {props.data.id == null ? (
                ""
              ) : (
                <img src={imageFullPath} width="100" />
              )}

              <div className="container">
                <div className="row mt-3">
                  <button
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    {props.data.id == null ? "Submit" : "Submit"}
                  </button>
                  <NavLink
                    className={classes.cancelBtn}
                    to="/application-area/list"
                  >
                    <button
                      className="btn btn-outline-primary"
                      variant="contained"
                    >
                      Cancel
                    </button>
                  </NavLink>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default withRouter(CreateApplicationArea);
