import React from 'react';

const CapabilitesSection = (props) => {
    return(
        <>
                    <div class="col-md-4 mb-5 px-4">
                        <img className="img-fluid" src={props.imgsrc} />
                        <h4 className="mt-3">{props.heading}</h4>
                    </div>
                
</>
    )
}


export default CapabilitesSection;