/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { ErrorPage7 } from "./modules/ErrorsExamples/ErrorPage7";
import { ErrorPage8 } from "./modules/ErrorsExamples/ErrorPage8";
import { ErrorPage9 } from "./modules/ErrorsExamples/ErrorPage9";
import { ErrorPage10 } from "./modules/ErrorsExamples/ErrorPage10";
import { ErrorPage11 } from "./modules/ErrorsExamples/ErrorPage11";
import { ErrorPage12 } from "./modules/ErrorsExamples/ErrorPage12";
import { ErrorPage14 } from "./modules/ErrorsExamples/ErrorPage14";
import { ErrorPage15 } from "./modules/ErrorsExamples/ErrorPage15";
import Confirm from "./pages/Products/Confirm";
import CustomerKeyManagerApproval from "./pages/Customers/KeyManagerConfirm";
import CustomerEngineeringManagerApproval from "./pages/Customers/EngineeringManagerConfirm";
import Landing from "../app/pages/LandingPage/App";
import About from "../app/pages/LandingPage/About";
import Capabilities from "../app/pages/LandingPage/Capabilities";
import Engineering from "../app/pages/LandingPage/Engineering";
import Manufacturing from "../app/pages/LandingPage/Manufacturing";
import QualityControl from "../app/pages/LandingPage/QualityControl";
import Products from "../app/pages/LandingPage/Products";
import Contact from "../app/pages/LandingPage/Contact";
import Privacy from "./pages/LandingPage/Privacy";
import TermsConditions from "./pages/LandingPage/TermsConditions";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route path="/products/disapproved/:id" component={Confirm} />
      <Route
        path="/customers/key-manager/approval/:id"
        component={CustomerKeyManagerApproval}
      />
      <Route
        path="/customers/engineering-manager/approval/:id"
        component={CustomerEngineeringManagerApproval}
      />

      <Route path="/products/delete" component={ErrorPage7} />
      <Route path="/products/notfound" component={ErrorPage8} />
      <Route path="/products/delete/notfound" component={ErrorPage8} />
      <Route path="/products/delete/approved" component={ErrorPage9} />
      <Route path="/products/already/disapproved" component={ErrorPage9} />
      <Route path="/products/request" component={ErrorPage10} />
      <Route path="/products/already/approved" component={ErrorPage11} />
      <Route path="/products/approved/successfully" component={ErrorPage12} />
      <Route
        path="/products/edit/approved/successfully"
        component={ErrorPage14}
      />
      <Route
        path="/products/edit/disapproved/successfully"
        component={ErrorPage15}
      />
      <Route path="/" component={Landing} exact />
      <Route exact path="/about" component={About} />
      <Route exact path="/capabilities" component={Capabilities} />
      <Route exact path="/engineering" component={Engineering} />
      <Route exact path="/manufacturing" component={Manufacturing} />
      <Route exact path="/qualitycontrol" component={QualityControl} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/privacy-statement" component={Privacy} />
      <Route
        exact
        path="/terms-and-conditions-of-use"
        component={TermsConditions}
      />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
