import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles(theme => ({
      actionIcons: {
        display: 'flex',
        justifyContent: 'space-evenly'
      },
      cancelBtn: {
        marginLeft:'10px',
        backgroundColor:'#910039',
        color: '#FFFFFF',
        padding:'8px 20px',
        border:'none'
      }
}));

export default UseStyles
