import React from "react";
import ThreeSection from "./ThreeSection";
import Banner from "./Banner";
import ContactBanner from "../common/assets/Contact-banner@2x.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
const Privacy = () => {
  return (
    <>
      <Navbar />
      <Banner imgsrc={ContactBanner} heading="Privacy Statement" />

      <section className="py-5 about-section">
        <div className="container">
          <div className="">
            <p>
              Illinois Tool Works Inc. (“ITW,” “<strong>we</strong>,” “
              <strong>us,</strong>” or “<strong>our</strong>”) respects your
              privacy and is committed to following the data protection laws
              that apply to us. This Privacy Statement describes the information
              we collect, how we use and share it, and your rights with respect
              to it. This Privacy Statement applies to any information we
              collect or maintain about you, including (1) personal information
              that you provide to us on ITW.com (“<strong>Website</strong>”) and
              other websites, applications, communications, and online services
              that link to this Privacy Statement (collectively with the
              Website, the “<strong>Website and Online Services</strong>”), (2)
              information collected through your use of or interaction with the
              Website and Online Services, (3) information obtained from our
              approved partners and other reliable third-party sources, (4)
              information collected offline (e.g., if we collect you resume
              during a job fair), and (5) information collected through a call
              center.
              {/* This Privacy Statement also explains the use of cookies
              and similar technologies on the Website and Online Services (see
              PASSIVE INFORMATION COLLECTION below). */}
            </p>
            <p>
              This Privacy Statement applies only to the Website and Online
              Services that link to this Privacy Statement – not to any
              third-party sites that may be accessible from the Website and
              Online Services.
            </p>
            <p>
              After reading this Privacy Statement, which is incorporated into
              and is a part of this Website's Terms and Conditions of Use, you
              will know:
            </p>
            <ol className="px-3">
              <li>
                <a href="#Num1" data-anchor="#Num1">
                  What information we collect from or about you and how we use
                  it
                </a>
                <br />
              </li>
              <li>
                <a href="#Num2" data-anchor="#Num2">
                  What choices you have about how we use your information
                </a>
                <br />
              </li>
              <li>
                <a href="#Num3" data-anchor="#Num3">
                  How we protect your information
                </a>
                <br />
              </li>
              <li>
                <a href="#Num4" data-anchor="#Num4">
                  What we do about children visiting the Website and Online
                  Services
                </a>
                <br />
              </li>
              <li>
                <a href="#Num5" data-anchor="#Num5">
                  Additional information applicable to California residents
                </a>
                <br />
              </li>
              <li>
                <a href="#Num6" data-anchor="#Num6">
                  How you can contact us
                </a>
                <br />
              </li>
              <li>
                <a href="#Num7" data-anchor="#Num7">
                  Changes in the Privacy Statement
                </a>
              </li>
            </ol>
            <p>
              <strong>
                <u>
                  <br />
                  CALIFORNIA RESIDENTS:
                </u>
              </strong>{" "}
              Section 5 and the Supplemental Notice for California Residents,
              attached to and a part of this Privacy Statement, provide
              additional information about how we collect and share your
              personal information and your rights under California law with
              respect to your personal information.
            </p>
            <p>
              <strong>
                <u>EUROPEAN ECONOMIC AREA RESIDENTS:</u>
              </strong>{" "}
              If you are a visitor from the European Economic Area, the legal
              basis for collecting and using your personal information depends
              on the personal information concerned and the context in which we
              collect it. However, the applicable legal basis for our processing
              of your personal information normally includes one of the
              following:
            </p>
            <p>
              <u>Based on your consent</u>. In some cases, at the point at which
              you provide personal information, we may ask you for your consent
              to collect and process such information. You may later withdraw
              your consent (or opt-out) by contacting us using the contact
              details provided under the How You Can Contact ITW section below.
              Please note that if you withdraw your consent it will not affect
              any processing of your personal information that has already
              occurred.
            </p>
            <p>
              <u>Performance of a contract</u>. In certain circumstances, we may
              need to process your personal information to fulfill our
              obligations under a contract to which you are subject. In that
              case, you may not be able to object to such processing, or if you
              do choose to opt-out or object to such processing, it may impact
              our ability to perform a contractual obligation that you are owed.
            </p>
            <p>
              <u>Compliance with applicable laws</u>. In some circumstances, we
              may need to process your personal information to comply with an
              applicable law. In that case, you will likely not be permitted to
              object to such processing, but you will usually have the right to
              access or review the relevant personal information unless it would
              impede our legal obligations.
            </p>
            <p>
              <u>ITW’s legitimate interests</u>. We may process your personal
              information based on our legitimate interests (or those of any
              third party) to the extent such interests are not overridden by
              your data protection interests or fundamental rights and freedoms.
              In such cases, you have the right to object to the relevant
              processing of your personal information by contacting us as
              described under the How You Can Contact ITW section below.
            </p>
            <p>
              If you have questions about or need further information concerning
              the legal basis on which we collect and use your personal
              information, please contact as described under the How you can
              contact ITW section below.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <h2>
              <strong>
                <br />
                <a id="Num1"></a>1. Information We Collect From You and How We
                Use It
              </strong>
            </h2>
            <p>
              <strong>
                <br />
                YOUR CONSENT
              </strong>
            </p>
            <p>
              By using, visiting, or interacting with the Website and Online
              Services or providing personal information to us through your use
              of the Website and Online Services, you are consenting to the
              collection, use, and disclosure of your personal information
              described in this Privacy Statement.
            </p>
            <p>
              If you do not consent to the collection, use, and disclosure of
              your personal information as described in this Privacy Statement,
              do not use the Website or contact us as described in Section 6
              below.
            </p>
            <p>
              <strong>ACTIVE INFORMATION COLLECTION</strong>
            </p>
            <p>
              Like many other websites, we actively collect information from our
              visitors by permitting you to communicate directly with us via
              Investor Relations “Email Notifications” forms, &nbsp;recruiting
              and employment forms, or other methods made available to you on
              our Website and Online Services. Some of the information that you
              submit may be personal information (that is, information that can
              be uniquely identified with you, such as your name, address,
              e-mail address, phone number, etc.). We use this information for
              the purposes for which you provide it and for any other purpose
              described at the time information is submitted by you, as
              otherwise set forth in this Privacy Statement, or as permitted or
              required by applicable law.
            </p>
            <p>
              We generally collect and use personal information provided to us
              on the Website and Online Services for the following purposes:
            </p>
            <ul className="list-group px-3">
              <li>
                to respond to your inquiries;
                <br />
              </li>
              <li>
                to allow individuals to apply for employment with us;
                <br />
              </li>
              <li>
                to authenticate identity for privacy-related requests;
                <br />
              </li>
              <li>
                to ensure the efficiency, reliability, and security of our
                systems;
                <br />
              </li>
              <li>
                to meet legal and regulatory requirements and to allow us to
                meet contractual requirements relating to our products and
                services;
                <br />
              </li>
              <li>
                to understand and assess the interests, wants, and changing
                needs of Website visitors with a view to improving our products
                and services and developing new ones; and
                <br />
              </li>
              <li>
                to notify Website visitors about changes to the Website and
                Online Services.
              </li>
            </ul>
            <p>
              <strong>
                <br />
                INFORMATION WE COLLECT FROM OTHER SOURCES
              </strong>
            </p>
            <p>
              We may also receive information from other sources and combine it
              with information we collect through our Website and Online
              Services. For example, we may obtain information about you from
              commercially available sources such as data aggregators and public
              databases (for example, job applicant information received from
              LinkedIn in connection with our hiring decisions). This
              information may include your name, demographic information,
              interests, and public data (such as social media and shopping
              behavior).
            </p>
            <p>
              <strong>RETENTION OF PERSONAL INFORMATION</strong>
            </p>
            <p>
              We retain the personal information collected on the Website and
              Online Services as long as necessary to provide the services,
              products, and information you request or as permitted by
              applicable law.
            </p>
            <p>
              <strong>Disclosure of Personal Information</strong>
            </p>
            <p>
              We will not sell or otherwise disclose personal information
              collected from the Website and Online Services to any third
              person, except as permitted by this Privacy Statement, as
              permitted or provided by applicable law, or with your consent.
            </p>
            <p>
              We may disclose personal information you provide to us via this
              Website and Online Services or that we collect through your use of
              the Website and Online Services as follows:
            </p>
            <ul>
              <li>
                to affiliates, business partners, service providers, and
                contractors who support our business, in which case we will
                limit the amount of information disclosed to that which is
                necessary in order for them to provide the services or products
                we have engaged them to provide. We will strive to protect
                personal information rendered accessible by contractual
                agreements requiring confidentiality and security practices that
                help keep data inaccessible to others;
                <br />
              </li>
              <li>
                to respond to law enforcement requests, where required by
                applicable laws, court orders, or governmental regulations;
                <br />
              </li>
              <li>
                in the event of a sale, merger, liquidation, dissolution,
                reorganization, assignment, or other transfer of our business to
                which the information relates, in which case we will require any
                such buyer to agree to treat it in accordance with this Privacy
                Statement and use it for the same purposes as we are entitled to
                do so under this Privacy Statement; and
                <br />
              </li>
              <li>
                to report to law enforcement agencies any activities that we, in
                good faith, believe to be unlawful, and in situations involving
                threats of physical safety of any person or when we believe that
                disclosure is reasonably necessary to protect our rights and
                property.
              </li>
            </ul>
            <p>
              We are not liable for disclosure of information obtained due to
              errors in transmission or the unauthorized intervention of third
              parties.
            </p>
            <p>
              We may provide aggregate statistics about our customers, sales,
              online traffic patterns, and related information to reputable
              third parties, but these statistics will not include any
              personally identifying information.
            </p>
            <p>
              We may transfer the information described in this Privacy
              Statement to, and process and store it in, the United States and
              other countries, some of which may have less protective data
              protection laws than the region in which you reside. Where this is
              the case, we will take appropriate measures to protect your
              information in accordance with this Privacy Statement.
              Nonetheless, your personal information may be subject to foreign
              laws and may be accessible by foreign government authorities,
              courts, law enforcement, and regulatory agencies.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <h2>
              <strong>
                <a id="Num2"></a>2. Choices You Have About How We Use Your
                Information
              </strong>
            </h2>
            <p>
              <br />
              We strive to provide you with as many choices and as much control
              as possible regarding the personal information we collect about
              you.
            </p>
            <p>
              You may send us an e-mail message at{" "}
              <a href="mailto:privacy@itw.com">privacy@itw.com</a> to request
              access to, correct, or delete any personal information that you
              have provided to us. To protect your privacy, we will only respond
              to e-mail messages sent from the e-mail account you used to set up
              your account.
            </p>
            <p>
              You have the right to opt-out of promotional communications we
              send you at any time. You can exercise this right by clicking on
              the 'unsubscribe' or 'opt-out' link in the promotional e-mails we
              send you.
            </p>
            {/* <p>
              If you do not wish for us to collect cookies, you may set your
              browser to refuse cookies, or to alert you when cookies are being
              sent. If you do so, please note that some parts of this Website
              may then be inaccessible or not function properly. Removing or
              rejecting browser cookies does not necessarily affect third-party
              Flash cookies (see “Local shared objects” above) which may be used
              by us or our partners in connection with our Website and Online
              Services. You may need to take additional steps to delete or
              disable Flash cookies.
            </p> */}
            <p>
              You can also generally opt-out of receiving personalized ads from
              third party advertisers and ad networks that honor such requests
              by visiting:{" "}
              <a href="http://www.aboutads.info/choices">
                www.aboutads.info/choices
              </a>{" "}
              (if you are in the United States),{" "}
              <a href="http://youradchoices.ca/choices/">
                http://youradchoices.ca/choices/
              </a>{" "}
              (if you are in Canada), or{" "}
              <a href="http://youronlinechoices.eu/">
                http://youronlinechoices.eu/
              </a>{" "}
              (if you are in Europe).
            </p>
            <p>
              <u>DO NOT TRACK:</u> Some web browsers may transmit “do-not-track”
              signals to websites with which the browser communicates. Our
              Website and Online Services do not currently respond to these
              “do-not-track” signals.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <h2>
              <strong>
                <a id="Num3"></a>3. How We Protect Your Information
              </strong>
            </h2>
            <p>
              <br />
              We work hard to protect your personal information. All information
              collected through the Website and Online Services is stored within
              a secured database that is not accessible to the outside world.
              Resumes are stored on file systems that are not publicly
              accessible. Our servers are protected behind both hardware and
              software firewalls. Low-level access to information is restricted
              to authorized support personnel only.
            </p>
            <p>
              The safety and security of your information also depends on you.
              Never share passwords with anyone else and remember to log off
              this Website before you leave your computer.
            </p>
            <p>
              Our Website and Online Services contain links to other websites
              operated by third parties that may be of interest to you. We
              cannot control these third-party sites, which may collect personal
              information from you. When you follow a link and leave this
              Website or Online Service, you do so at your own risk. We
              encourage you to read that third party's privacy Statement or
              contact them directly regarding their privacy practices.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <h2>
              <strong>
                <a id="Num4"></a>4. What We Do About Children Visiting This
                Website
              </strong>
            </h2>
            <p>
              <br />
              This Website is not intended for children under the age of 13. We
              will not knowingly collect information from Website and Online
              Services visitors in this age group. We encourage parents to talk
              to their children about their use of the Internet and the
              information they disclose online. If your child has submitted
              personal information and you would like to request that such
              information be deleted from our records, you may do so by writing
              us at the address in Section 6 below.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <h2>
              <strong>
                <a id="Num5"></a>5. CALIFORNIA RESIDENTS
              </strong>
            </h2>
            <p>
              <br />
              The California Consumer Privacy Act of 2018 (CCPA) gives
              California residents rights about the personal information we
              collect, use, or disclose about you (subject to certain
              exceptions):
            </p>
            <ul>
              <li>
                <strong>Right to Notice.</strong> Before or at the time we
                collect personal information from you, you have the right to
                receive notice of the personal information to be collected and
                the purposes for which we use it. The Supplemental Notice for
                California Residents attached to this Privacy Statement is
                intended to satisfy this requirement. <br />
                You also have the right to request that we disclose to you the
                categories of personal information we have collected about you
                in the preceding 12 months, along with the categories of sources
                from which the personal information was collected, the purpose
                for collecting or selling the personal information, the
                categories of third parties with whom we shared the personal
                information, and the categories of third parties with whom we
                sold the personal information.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Right of Access.</strong> You have the right to request
                that we disclose or provide you with access to the specific
                pieces of personal information we have collected about you in
                the preceding 12 months.
                <br />
              </li>
              <li>
                <strong>Right to Deletion</strong>. You have the right to
                request that we delete the personal information we collect from
                you. However, in certain situations we are not required to
                delete your personal information, such as when the information
                is necessary in order to complete the transaction for which the
                personal information was collected, to provide a good or service
                requested by you, to comply with a legal obligation, to engage
                in research, to secure our Website and Online Services, or to
                otherwise use your personal information internally in a lawful
                manner that is compatible with the context in which you provided
                the information.
                <br />
              </li>
              <li>
                <strong>
                  Right to Opt-Out of the Sale of Personal Information
                </strong>
                . We do not sell your personal information. If we sold your
                personal information to third parties, you would have the right,
                at any time, to direct us not to sell your personal information.
                <br />
              </li>
              <li>
                <strong>Right Not to Be Subject to Discrimination</strong>. You
                have the right to be free from discrimination or retaliation for
                exercising any of your rights under the CCPA as described above.
              </li>
            </ul>
            <p>
              To exercise any of the rights described above, you can call us
              toll-free at 1-844-676-0349 or email us at{" "}
              <a href="mailto:privacy@itw.com">privacy@itw.com</a>. California
              residents who are unable to review or access this Privacy
              Statement, including the Supplemental Notice for California
              Residents, due to a disability may contact{" "}
              <a href="mailto:privacy@itw.com">privacy@itw.com</a> to access
              this Privacy Statement in an alternative format.
            </p>
            <p>
              We value the security and confidentiality of your personal
              information. Therefore, if you exercise your right to notice,
              access, or deletion, we must first verify your identify to make
              sure that you are the person about whom we have collected personal
              information. We verify every request carefully and ask that you
              provide a scanned copy of a government ID along with your request
              for verification purposes.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <h2>
              <strong>
                <a id="Num6"></a>6. How You Can Contact Us
              </strong>
            </h2>
            <p>
              <br />
              If you have any questions, comments, or concerns about this
              Privacy Statement, our information practices, our Website and
              Online Services, or our service providers, contractors, and
              business partners, please contact us at the information below and
              address your request to:
            </p>
            <p>
              Illinois Tool Works Inc.
              <br />
              155 Harlem Avenue
              <br />
              Glenview, IL 60025
              <br />
              Toll-Free: 1-844-676-0349
              <br />
              <a href="mailto:privacy@itw.com">privacy@itw.com&nbsp;</a>
              <strong>&nbsp;</strong>
            </p>
            <p>&nbsp;</p>

            <p>
              <h2>
                <strong>
                  <a id="Num7"></a>7. Changes In The Privacy Statement
                </strong>
              </h2>
              <br />
              We reserve the right to update or modify this Privacy Statement,
              at any time and without prior notice, by posting the revised
              version of this Privacy Statement on this Website. If we modify
              this Privacy Statement, the modifications will only apply to
              personal information we collect after we have posted the revised
              Privacy Statement on this Website.
            </p>
            <p>&nbsp;</p>
            <table
              border="1"
              width="720"
              height="758"
              style={{ width: "751px" }}
            >
              <thead>
                <tr>
                  <th
                    colspan="3"
                    width="720"
                    style={{ width: "1060px", padding: "10px" }}
                  >
                    <p>
                      Privacy Statement
                      <br />
                      Supplemental Notice for California Residents
                      <br />
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    width="114"
                    style={{ width: "117px", padding: "10px" }}
                    scope="row"
                  >
                    <p>
                      <strong>Why?</strong>
                    </p>
                  </th>
                  <td
                    colspan="2"
                    width="606"
                    style={{ width: "943px", padding: "10px" }}
                  >
                    <p>
                      This Supplemental Notice for California Residents provides
                      certain information required under the California Consumer
                      Privacy Act of 2018 (CCPA) and applies to both our online
                      and offline activities involving personal information
                      about California residents. This Supplemental Notice is
                      incorporated into and a part of our Privacy Statement. For
                      more information about our collection, use, and disclosure
                      of information, as well as your rights under the CCPA,
                      please review our Privacy Statement.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th
                    width="114"
                    style={{ width: "117px", padding: "10px" }}
                    scope="row"
                  >
                    <p>
                      <strong>What?</strong>
                    </p>
                  </th>
                  <td
                    colspan="2"
                    width="606"
                    style={{ width: "943px", padding: "10px" }}
                  >
                    <p>
                      The personal information that we collect or disclose
                      depends on your relationship and interaction with us.
                      Please select the category that applies to your
                      relationship or interaction with us:{" "}
                      <a href="#applicants" data-anchor="#applicants">
                        Applicants
                      </a>
                      ,{" "}
                      <a
                        href="#employeescontractors"
                        data-anchor="#employeescontractors"
                      >
                        Employees and Contractors
                      </a>
                      ,{" "}
                      <a href="#websitevisitors" data-anchor="#websitevisitors">
                        Website Visitors
                      </a>
                      . If you interact with us in more than one way, please
                      review each category that applies to you.
                      <br />
                      In addition to the information described below, we collect
                      and disclose any and all personal information (regardless
                      of your relationship or interaction with us) as necessary
                      or appropriate to: comply with laws and regulations;
                      Investigate and enforce compliance with and potential
                      breaches of our policies and legal and regulatory
                      requirements; comply with civil, criminal, judicial, or
                      regulatory inquiries, investigations, subpoenas, or
                      summons; and exercise or defend our legal rights and those
                      of our employees, affiliates, customers, contractors, and
                      agents.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th
                    rowspan="3"
                    width="114"
                    style={{ width: "117px", padding: "10px" }}
                    scope="row"
                  >
                    <p>
                      <strong>Personal Information</strong>
                    </p>
                  </th>
                  <td
                    colspan="2"
                    width="606"
                    style={{ width: "943px", padding: "10px" }}
                  >
                    <p>
                      In this Supplemental Notice, “personal information” (or
                      “PI”) means any information that identifies, relates to,
                      describes, or is capable of being associated with you or
                      your household, whether directly or indirectly. Here are
                      the definitions of some of the categories of personal
                      information we collect, use, and share about California
                      consumers that are not otherwise defined in the charts
                      below:
                    </p>
                  </td>
                </tr>
                <tr>
                  <th
                    width="174"
                    style={{ width: "173px", padding: "10px" }}
                    scope="row"
                  >
                    <p>
                      <strong>Identifiers</strong>
                    </p>
                  </th>
                  <td width="432" style={{ width: "770px", padding: "10px" }}>
                    <p>
                      Identifiers, such as real name, alias, postal address,
                      unique personal identifier, online identifier, internet
                      protocol (IP) address, email address, account name, social
                      security number, driver’s license number, passport number,
                      photographs, or other similar identifiers.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th
                    width="174"
                    style={{ width: "173px", padding: "10px" }}
                    scope="row"
                  >
                    <p>
                      <strong>Network Activity Data</strong>
                    </p>
                  </th>
                  <td width="432" style={{ width: "770px", padding: "10px" }}>
                    <p>
                      Internet or other electronic network activity information,
                      such as browsing or search history, and information
                      regarding an individual’s interaction with an internet
                      website, application, or advertisement.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>
                <u>
                  <br />
                  <a id="applicants"></a>Applicants
                  <br />
                </u>
              </strong>
            </p>
            <table
              border="1"
              width="715"
              height="499"
              style={{ width: "734px" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }}>
                    <p>
                      Categories of
                      <br />
                      PI Collected
                    </p>
                  </th>
                  <th style={{ width: "724px", padding: "10px" }}>
                    <p>Purposes for Collecting or Sharing PI</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>Identifiers</p>
                  </th>
                  <td style={{ width: "724px", padding: "20px" }}>
                    <ul>
                      <li>
                        Evaluate a potential employment or contractor
                        relationship.
                      </li>
                      <li>
                        Perform background checks and verify past employment,
                        educational history, and qualifications.
                      </li>
                      <li>Determine potential compensation and benefits.</li>
                      <li>
                        Assess your fitness and physical capacity for work.
                      </li>
                      <li>Communicate with you about your application.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Medical Information, including medical history, mental or
                      physical condition, or treatment.
                    </p>
                  </th>
                  <td style={{ width: "724px", padding: "20px" }}>
                    <ul>
                      <li>
                        After an offer, to assess your fitness and physical
                        capacity for work.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Demographic Information protected under California or
                      federal law, such as race, gender, disability, and
                      religion.
                    </p>
                  </th>
                  <td style={{ width: "724px", padding: "20px" }}>
                    <ul>
                      <li>
                        Filing of state or federal government-mandated reports.
                      </li>
                      <li>Evaluating diversity and inclusion initiatives.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Job history, professional skills, and educational
                      background.
                    </p>
                  </th>
                  <td style={{ width: "724px", padding: "20px" }}>
                    <ul>
                      <li>
                        Evaluate a potential employment or contractor
                        relationship.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              <strong>
                <u>
                  <br />
                  <a id="employeescontractors"></a>Employees and Contractors
                  <br />
                </u>
              </strong>
            </p>
            <table
              border="1"
              width="715"
              height="969"
              style={{ width: "712px" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }}>
                    <p>Categories of PI Collected</p>
                  </th>
                  <th style={{ width: "509px", padding: "10px" }}>
                    <p>Purposes for Collecting or Sharing PI</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ width: "251px", padding: "20px" }} scope="row">
                    <p>Identifiers</p>
                    <ul>
                      <li>Compensation and benefits.</li>
                      <li>Management of your job performance.</li>
                      <li>
                        Communication with you/your designated emergency
                        contacts in the event of illness, absence, natural
                        disaster, etc.
                      </li>
                      <li>
                        Authentication of your identity and access to Company
                        systems, networks, databases, equipment, and facilities.
                      </li>
                      <li>
                        Workforce management, including personnel planning,
                        productivity monitoring, and evaluation.
                      </li>
                      <li>Tracking and administering training.</li>
                      <li>
                        Monitoring and securing Company systems, networks,
                        databases, confidential information, equipment,
                        facilities.
                      </li>
                      <li>
                        Arranging work-related travel, events, and meetings.
                      </li>
                      <li>
                        Assessing your fitness for work, and other occupational
                        medicine purposes (including work-related injury and
                        illness).
                      </li>
                    </ul>
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Health insurance information, including claims history.
                    </p>
                  </th>
                  <td style={{ width: "509px", padding: "20px" }}>
                    <ul>
                      <li>Benefits planning and administration.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>Financial information, including bank account number.</p>
                  </th>
                  <td style={{ width: "509px", padding: "20px" }}>
                    <ul>
                      <li>Compensation and benefits administration.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Medical Information, including medical history, mental or
                      physical condition, or treatment.
                    </p>
                  </th>
                  <td style={{ width: "509px", padding: "20px" }}>
                    <ul>
                      <li>
                        Assessing your fitness for work, and other occupational
                        medicine purposes (including work-related injury and
                        illness).
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Demographic Information protected under California or
                      federal law, such as race, gender, disability, and
                      religion.
                    </p>
                  </th>
                  <td style={{ width: "509px", padding: "20px" }}>
                    <ul>
                      <li>
                        Filing of state or federal government-mandated reports.
                      </li>
                      <li>Evaluating diversity and inclusion initiatives.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Job history, professional skills, and educational
                      background.
                    </p>
                  </th>
                  <td style={{ width: "509px", padding: "20px" }}>
                    <ul>
                      <li>Management of your job performance.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "251px", padding: "10px" }} scope="row">
                    <p>
                      Internet activity on Company computers or Company phones,
                      such as browsing history and search history.
                    </p>
                  </th>
                  <td style={{ width: "509px", padding: "20px" }}>
                    <ul>
                      <li>
                        Monitoring and securing Company systems, networks,
                        databases, confidential information, equipment,
                        facilities.
                      </li>
                      <li>Management of your job performance.</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              <strong>
                <u>
                  <br />
                  <a id="websitevisitors"></a>&nbsp;Website Visitors
                  <br />
                </u>
              </strong>
            </p>
            <table
              border="1"
              width="720"
              height="876"
              style={{ width: "730px" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "114px", padding: "10px" }}>
                    <p>Categories of PI Collected</p>
                  </th>
                  <th style={{ width: "107px", padding: "10px" }}>
                    <p>Sources of PI</p>
                  </th>
                  <th style={{ width: "222px", padding: "10px" }}>
                    <p>Purposes for Collecting or Sharing PI</p>
                  </th>
                  <th style={{ width: "132px", padding: "10px" }}>
                    <p>Third Parties to Whom We Disclose PI</p>
                  </th>
                  <th style={{ width: "205px", padding: "10px" }}>
                    <p>Third Parties to Whom We Sell PI</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ width: "114px", padding: "10px" }} scope="row">
                    <p>Identifiers</p>
                  </th>
                  <td style={{ width: "107px", padding: "20px" }}>
                    <ul>
                      <li>Website visitor</li>
                    </ul>
                  </td>
                  <td style={{ width: "222px", padding: "20px" }}>
                    <ul>
                      <li>
                        For our everyday business purposes such as to process
                        your requests, inquiries, or other communications.
                      </li>
                      <li>
                        Ad impressions and determining unique visitors to our
                        website.
                      </li>
                      <li>
                        To help maintain the security of our website and
                        business, and to detect security incidents and other
                        fraudulent activity.
                      </li>
                      <li>
                        To monitor and improve our website functionality and
                        personalize your website experience.
                      </li>
                      <li>
                        For marketing purposes, to deliver content and product
                        and service offerings, including targeted ads.
                      </li>
                    </ul>
                  </td>
                  <td width="135" style={{ width: "132px", padding: "10px" }}>
                    <p>N/A</p>
                  </td>
                  <td width="135" style={{ width: "205px", padding: "10px" }}>
                    <p>N/A</p>
                  </td>
                </tr>
                <tr>
                  <th
                    width="114"
                    style={{ width: "114px", padding: "10px" }}
                    scope="row"
                  >
                    <p>Network Activity Data</p>
                  </th>
                  <td width="108" style={{ width: "107px", padding: "20px" }}>
                    <ul>
                      <li>Website visitor</li>
                    </ul>
                  </td>
                  <td width="228" style={{ width: "222px", padding: "20px" }}>
                    <ul>
                      <li>
                        Ad impressions and determining unique visitors to our
                        website.
                      </li>
                      <li>
                        To help maintain the security of our website and
                        business, and to detect security incidents and other
                        fraudulent activity.
                      </li>
                      <li>
                        To monitor and improve our website functionality and
                        personalize your website experience.
                      </li>
                      <li>
                        For marketing purposes, to deliver content and product
                        and service offerings, including targeted ads.
                      </li>
                    </ul>
                  </td>
                  <td width="135" style={{ width: "132px", padding: "10px" }}>
                    <p>N/A</p>
                  </td>
                  <td width="135" style={{ width: "205px", padding: "10px" }}>
                    <p>N/A</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Privacy;
