import React from "react";
import brandLogo from "../common/assets/ITW-logo.svg";
import Facebook from "../common/assets/facebook.svg";
import Instagram from "../common/assets/instagram.svg";
import Twitter from "../common/assets/twitter.svg";
import Email from "../common/assets/email.svg";

const Footer = () => {
  const mystyle = {
    fontSize: "20px",
  };
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <>
      <div className="footer-design p-5">
        <div className="container">
          <div className="row justify-content-between footer-container">
            <div class="col-md-2 text-center">
              <img src={brandLogo} />
            </div>
            <div class="col-md-5">
              <h5 style={mystyle}>
                <strong>About Us</strong>
              </h5>
              <p>
                Our vision is clear – to reach our full potential and position
                ITW as one of the world’s best-performing, highest-quality and
                most-respected industrial companies. Learn more about what
                differentiates ITW and makes us great.
              </p>
              <a href="#">
                <img className="icon-margin" src={Facebook} />
              </a>
              <a href="#">
                <img className="icon-margin" src={Instagram} />
              </a>
              <a href="#">
                <img className="icon-margin" src={Twitter} />
              </a>
            </div>
            <div class="col-md-3">
              <h5 style={mystyle}>
                <strong>Contact Us</strong>
              </h5>
              <div className="email">
                <img className="icon-margin" src={Email} />
                mediarelations@itw.com
              </div>
              <div className="mt-2 email">
                <img className="icon-margin" src={Email} />
                investorrelations@itw.com
              </div>
              <div className="mt-2 email">
                <img className="icon-margin" src={Email} />
                careers@itw.com
              </div>
            </div>
          </div>
        </div>
        <div className="container copyright">
          <div className="row">
            <div class="col-md-12 text-center footer-text">
              <hr className="mx-auto" />© {currentYear} Illinois Tool Works Inc.
              All Rights Reserved
              <div className="footer-links">
                <a
                  href="/privacy-statement"
                  className="footer-link"
                  style={{ margin: "0 10px" }}
                >
                  Privacy Statement
                </a>
                |
                <a
                  href="/terms-and-conditions-of-use"
                  className="footer-link"
                  style={{ margin: "0 10px" }}
                >
                  Terms Of Use
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
