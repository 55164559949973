import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headRows = [
  {
    id: "fastener_category",
    numeric: false,
    disablePadding: true,
    label: "Fastener Category",
  },
  // {
  //   id: "application_area",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Application Area",
  // },
  // {
  //   id: "category",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Category",
  // },
  {
    id: "sub_category",
    numeric: true,
    disablePadding: false,
    label: "Sub Category",
  },
  { id: "part_name", numeric: true, disablePadding: false, label: "Part Name" },
  {
    id: "part_number",
    numeric: true,
    disablePadding: false,
    label: "Part Number",
  },
  { id: "material", numeric: true, disablePadding: false, label: "Material" },
  { id: "color", numeric: true, disablePadding: false, label: "Color" },
  { id: "reason", numeric: true, disablePadding: false, label: "Reason" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const DisEnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Sr.No</TableCell>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "center" : "center"}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};

export default DisEnhancedTableHead;
