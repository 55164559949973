import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { API } from "../../../common";
import axios from "axios";
import SearchForm from "./SearchForm";
import UseStyles from "./UseStyles";
import headLogo from "../common/assets/ITW-logo-small.png";
import "../common/CSS/style.css";
import EnhancedTableHead from "./deleteEnhancedTableHead";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export default function EmployeeList(props) {
  const classes = UseStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showLoading, setShowLoading] = useState(true);

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState([]);

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // const user = localStorage.getItem("data");
    const user = JSON.parse(localStorage.getItem("data"));
    setUserData(user);
  }, []);

  const apiUrl = API + "products/product_delete_list_pending";

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token[1],
        },
      };
      var postData = { search: search };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((response) => response.data)
        .then((responseData) => {
          setShowLoading(false);
          setTimeout(setRows(responseData.Results), 5000);
        });
    };
    fetchData();
  }, [search]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (values) => {
    setSearch(values);
  };

  const editProduct = (id) => {
    props.history.push({
      pathname: "/products/edit/" + id,
    });
  };

  const deleteProduct = (id, index) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };

    const deleteData = API + "products/product_delete";
    const postData = {
      id: id,
      email: userData.email,
      type: userData.type,
    };
    axios
      .post(deleteData, postData, axiosConfig)
      .then((response) => response.data)
      .then((responseData) => {
        if (responseData.Status === false) {
          alert(responseData.Message);
        } else {
          alert(responseData.Message);
          var updatedData = [...rows];
          updatedData.splice(index, 1);
          setRows(updatedData);
        }
      });
  };

  return (
    <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
    >
      <div className={classes.listroot}>
        <div className="headLogo">
          <h4>Pending Product List</h4>
          <img src={headLogo} alt="headLogo" />
        </div>
        <Paper className={classes.paper}>
          <SearchForm onSearch={handleSearch} />
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    const newPage = page + 1;
                    const newIndex = index + 1;
                    const srno = newIndex + (newPage - 1) * rowsPerPage;
                    console.log(row)

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell>{srno}</TableCell>
                        <TableCell
                          component="th"
                          align="center"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.FastenerCategory.name}
                        </TableCell>

                        {/* <TableCell align="center">
                          {row.ApplicationArea.name}
                        </TableCell>

                        <TableCell align="center">
                          {row.Category.name}
                        </TableCell> */}

                        <TableCell align="center">
                          {row.SubCategory.name}
                        </TableCell>

                        <TableCell align="center">
                          {row.part_name_employee}
                        </TableCell>

                        <TableCell align="center">
                          {row.part_number_employee}
                        </TableCell>

                        <TableCell align="center">{row.material}</TableCell>

                        <TableCell align="center">{row.color}</TableCell>

                        <TableCell align="center">
                          <div className="action-btn">
                            <a
                              className={classes.editBtn}
                              onClick={() => {
                                editProduct(row.id);
                              }}
                            >
                              <EditIcon className="edit-btn" />
                            </a>
                            <a
                              className={classes.deleteBtn}
                              onClick={(e) =>
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && deleteProduct(row.id, index)
                              }
                            >
                              <DeleteIcon className="delete-btn" />
                            </a>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </LoadingOverlay>
  );
}
