import React from 'react';


const Banner = (props) => {

  return (
    <>
        <div className="container-fluid px-0">
            <div className="row gx-0">
                <div class="col-md-12 mx-auto">
                    <div class="position-relative">
                        <div class="banner">
                            <img className="img-fluid" src={props.imgsrc}/> 
                        </div>
                        <div className="row position-absolute gx-0">
                            <div className="col-md-12 banner-content ms-auto ps-8">
                                <h2 className="ps-8"><strong>{props.heading}</strong></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
export default Banner;
