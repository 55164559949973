import React from 'react'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const headRows = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email",
    },
    {
        id: "type",
        numeric: false,
        disablePadding: true,
        label: "Type",
    },

    {
        id: "action", 
        numeric: true, 
        disablePadding: true, 
        label: "Action" 
    },
];


const EnhancedTableHead = (props) => {

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

return (
    <>
    <TableHead>
        <TableRow>
        <TableCell >
            Sr.No
        </TableCell>
            {headRows.map((row) => (
            <TableCell
                key={row.id}
                align={row.numeric ? "left" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
            >
                <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
                >
                {row.label}
                </TableSortLabel>
            </TableCell>
            ))}
        </TableRow>
    </TableHead> 
    </>
    )
}

export default EnhancedTableHead