import React from 'react';
import Banner from "./Banner";
import Team from "./Team";
import AboutUsBg from "../common/assets/About-Us-banner-2x.jpg";

import EScottSanti from "../common/assets/E. Scott Santi@2x.jpg";
import ChristopherAOHerlihy from "../common/assets/Christopher A. OHerlihy@2x.jpg";
import AxelRJBeck from "../common/assets/Axel R.J. Beck@2x.jpg";
import TKennethEscoe from "../common/assets/T. Kenneth Escoe@2x.jpg";
import NormanDFinchJr from "../common/assets/Norman D. Finch Jr.@2x.jpg";
import JohnRHartnett from "../common/assets/John R. Hartnett@2x.jpg";
import MichaelMLarsen from "../common/assets/Michael M. Larsen@2x.jpg";
import MaryKLawler from "../common/assets/Mary K. Lawler@2x.jpg";
import LeiZhangSchlitz from "../common/assets/Lei Zhang Schlitz@2x.jpg";
import SharonASzafranski from "../common/assets/Sharon A. Szafranski@2x.jpg";
import MichaelRZimmerman from "../common/assets/Michael R. Zimmerman@2x.jpg";
import Navbar from './Navbar';
import Footer from './Footer';

const About = () => {

  return (
    <>
    <Navbar/>
      <Banner 
            imgsrc={AboutUsBg}
            heading="Discover ITW"
        />
        <section className="py-5 about-section">
          <div className ="container">
            <p>Leading with our values – trust, integrity, respect, shared risk and simplicity – our leadership team fosters 
            the ITW Business Model and champions our decentralized, entrepreneurial culture.<br/><br/>
            With their unique expertise and diverse backgrounds, our leaders drive collaboration, 
            embrace autonomy and deliver great results in order to position ITW as one of the world’s best-performing, highest-quality and most-respected companies.</p>
          </div>
        </section>
        <section className="Team">
          <div className="container">
            <div className="row">
              
                  <Team 
                      imgsrc={EScottSanti}
                      member="E. Scott Santi"
                      designation="Chairman & Chief Executive Officer"
                  />
                  <Team 
                      imgsrc={ChristopherAOHerlihy}
                      member="Christopher A. O'Herlihy"
                      designation="Vice Chairman"
                  />
                  <Team 
                      imgsrc={AxelRJBeck}
                      member="Axel R.J. Beck"
                      designation="Chairman & Chief Executive Officer"
                  />
                  <Team 
                      imgsrc={TKennethEscoe}
                      member="T. Kenneth Escoe"
                      designation="Executive Vice President"
                  />
                  <Team 
                      imgsrc={NormanDFinchJr}
                      member="Norman D. Finch Jr."
                      designation="Senior Vice President, General Counsel & Secretary"
                  />
                  <Team 
                      imgsrc={JohnRHartnett}
                      member="John R. Hartnett"
                      designation="Executive Vice President"
                  />
                  <Team 
                      imgsrc={MichaelMLarsen}
                      member="Michael M. Larsen"
                      designation="Senior Vice President & Chief Financial Officer"
                  />
                  <Team 
                      imgsrc={MaryKLawler}
                      member="Mary K. Lawler"
                      designation="Executive Vice President"
                  />
                  <Team 
                      imgsrc={LeiZhangSchlitz}
                      member="Lei Zhang Schlitz"
                      designation="Executive Vice President"
                  />
                  <Team 
                      imgsrc={SharonASzafranski}
                      member="Sharon A. Szafranski"
                      designation="Executive Vice President"
                  />
                  <Team 
                      imgsrc={MichaelRZimmerman}
                      member="Michael R. Zimmerman"
                      designation="Executive Vice President"
                  />
            </div>
          </div>
        </section>
    <Footer/>
    </>
  )
}
export default About;
