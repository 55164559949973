import React,{useState,useEffect} from 'react'
import {withRouter, NavLink} from "react-router-dom";
import axios from 'axios';
import {API} from '../../../common';
import TextField from '@material-ui/core/TextField';
import UseStyles from './UseStyles';
import Paper from '@material-ui/core/Paper';
import '../common/CSS/style.css'
import Validator from "validatorjs";
import headLogo from "../common/assets/ITW-logo-small.png";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const CreateProductRole = (props) => {
  const [showLoading, setShowLoading] = useState(false);
    const classes = UseStyles();
    const [errors, setErrors] = useState([]);
    const [state, setState] = useState({
      name:'',
      email:'',
      type:''
    });

    useEffect(() => {
      setState(props.data);
    }, [props.data]);


    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const validateForm = () => {
    
      let temp = { ...errors }
  
        let data = {
          name: state.name,
          email:state.email,
          
        };
      
        let rules;
  
        if (props.data.id)
        {
          rules = {
            name: "required",
            email: "required",
            
          };
  
        }else {
          rules = {
            name: "required",
            email: "required",
            
          };
        }
        
        
        let validation = new Validator(data, rules, {
          "required.name": " Name is required.",
          "required.email": " Email is required.",
          
        });
  
        if (validation.passes()) {
          return true;
        }
        if (validation.fails()) {
          temp.name = validation.errors.first("name");
          temp.email = validation.errors.first("email");
        

          setErrors({
            ...temp
          })
        }
  
    }


    const formSubmit = async() => {

      if(validateForm())
      {
        let axiosConfig = {
          headers : {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }
        }

          const apiUpdate = API + "productrole/product_role_edit"

          if (props.data.id)
          {
            const updateProductRole = async() => {
              let postData = {
                "id"  : state.id,
                "name":state.name,
                "email":state.email,
              }
              await axios.post(apiUpdate, postData, {axiosConfig}).then(response => response.data)
              .then((responseData) => {
                if(responseData.Status === false)
                {
                  alert(responseData.Message);
                }
                else {
                  alert(responseData.Message);
                  props.history.push({
                    pathname:"/product-role/list",
                  });
                }
              })
            }
            updateProductRole()
          }
      }
    }


    return (
        <>
  <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
      <div className={classes.listroot}>
          <div className="headLogo">
            <h4>{props.data.id == null ? "Add Product" : "Edit Product Role"}</h4>
            <img src={headLogo} alt="headLogo" />
          </div>
        <Paper className={classes.paper}>
        <form className={classes.container} noValidate autoComplete="off" >
        {/* <h2>{props.data.id == null ? 'Add Role' : 'Edit Role'}</h2> */}
                <TextField
                    id="outlined-type"
                    label="Type"
                    value= {state.type}
                    className={classes.textField}
                    margin="normal"
                    InputProps={{
                    readOnly: true,
                    }}
                    variant="outlined"
                />
            <TextField
                id="outlined-name"
                label="Name*"
                className={classes.textField}
                value={state.name || ''}
                onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
            />



            <TextField
                id="outlined-email"
                label="Email*"
                className={classes.textField}
                value={state.email || ''}
                onChange={handleChange('email')}
                margin="normal"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
            />


            <div>
                <button 
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                >
                    {props.data.id == null ? 'Submit' : 'Submit'}
                    
                </button>
                <NavLink className={classes.cancelBtn} to="/product-role/list">
                    <button 
                        className="btn btn-outline-primary"
                        variant="contained"
                    >
                        Cancel
                    </button>
                </NavLink>
            </div>
        </form>
        </Paper>
        </div>
      
  </LoadingOverlay>
        </>
    )
}

export default withRouter(CreateProductRole)
