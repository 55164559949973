import React from 'react'
import { NavLink } from 'react-router-dom'
import UseStyles from './UseStyles';
import '../common/CSS/style.css'

const SearchForm = (props) => {

    const classes = UseStyles();

      return(
        <>
          <div className={classes.container}>
            <div className={classes.actionBtn}>
              <NavLink className={classes.cancelBtn} to="/fasteners/create">
                <button className="btn btn-primary">
                  Create Fastener
                </button>
              </NavLink>
            </div>
          </div> 
        </>
      ); 
  }
  export default SearchForm;
