import React, { useState, useEffect, useRef } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import headLogo from "../common/assets/ITW-logo-small.png";
import UseStyles from "./UseStyles";
import axios from "axios";
import { API } from "../../../common";
import Dropzone from "react-dropzone";
import Cropper from "cropperjs";
import "./imagecropper.css";
import Grid from "@material-ui/core/Grid";
import Validator from "validatorjs";
import "../common/CSS/style.css";
import ReactPlayer from "react-player";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

const CreateProduct = (props) => {
  const imageElement = useRef();
  const imageElement1 = useRef();
  const image1Element = useRef();
  const image2Element = useRef();

  const classes = UseStyles();
  const [formData, setFormData] = useState([]);
  const [productPath, setProductPath] = useState();
  const [prodspecification, setProdSpecification] = useState([]);
  const [applicationAreaEditIds, setApplicationAreaEditIds] = useState([]);
  const [categoryEditIds, setCategoryEditIds] = useState([]);
  const [prodCategory, setProdCategory] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [productVideo, setProductVideo] = useState();
  const [productThumbnail, setProductThumbnail] = useState();

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // const user = localStorage.getItem("data");
    const user = JSON.parse(localStorage.getItem("data"));
    setUserData(user);
  }, []);

  useEffect(() => {
    setFormData(props.data);
    if (props.data.id) {
      setSelectApplicationArea(props.applicationEditData);
    }
    if (props.data.id) {
      setSelectCategory(props.categoryEditData);
    }
    setProdCategory(props.data.ProductCategories);
    console.log(props.data.ProductCategories);
    let new_specification =
      props.data.ProductSpecifications?.length > 0
        ? props.data.ProductSpecifications[0]
        : "";

    setProdSpecification(new_specification);
    // setProdCategory(categories);
    // console.log(categories);
    setProductVideo(props.data.video);
    setProductThumbnail(props.data.thumbnail);
  }, [props.data && props.applicationEditData && props.categoryEditData]);

  useEffect(() => {
    setProductPath(props.productpath);
  }, [props.productpath]);

  const [fasteners, setFasteners] = useState([]);
  const [applicationArea, setApplicationArea] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [productVideos, setProductVideos] = useState([]);
  const [selectApplicationArea, setSelectApplicationArea] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [applicationAreaIds, setApplicationAreaIds] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  useEffect(() => {
    const fastenerPostData = [];
    const fastenerApiUrl = API + "fastenercategories";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchFasterner = async () => {
      await axios
        .post(fastenerApiUrl, fastenerPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setFasteners(responseData.Results);
        });
    };
    fetchFasterner();
  }, []);

  useEffect(() => {
    const applicationAreaPostData = { id: formData.fastener_category_id };
    const applicationAreaApiUrl = API + "applicationareas";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchApplicationArea = async () => {
      await axios
        .post(applicationAreaApiUrl, applicationAreaPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          if (responseData.Status === true) {
            setApplicationArea(responseData.Results);
          }
        });
    };
    fetchApplicationArea();
  }, [formData.fastener_category_id]);

  useEffect(() => {
    const postApplicationArea = [];

    selectApplicationArea.map((data) => postApplicationArea.push(data.id));
    setApplicationAreaIds(postApplicationArea);
    const CategoryPostData = {
      fastener_category_id: formData.fastener_category_id,
      application_area_id: postApplicationArea,
    };
    const CategoryApiUrl = API + "categories";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const Category = async () => {
      await axios
        .post(CategoryApiUrl, CategoryPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          if (responseData.Status == true) {
            setCategory(responseData.Results);
          }
        });
    };
    Category();
  }, [selectApplicationArea]); //[formData.application_area_id]

  useEffect(() => {
    const postSubCategory = [];

    selectCategory.map((data) => postSubCategory.push(data.id));
    setCategoryIds(postSubCategory);
    const subCategoryPostData = {
      fastener_category_id: formData.fastener_category_id,
      application_area_id: selectApplicationArea,
      category_id: postSubCategory,
    };
    const subCategoryApiUrl = API + "subcategories/sub_category_index_web";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const subCategory = async () => {
      await axios
        .post(subCategoryApiUrl, subCategoryPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          if (responseData.Status == true) {
            setSubCategory(responseData.Results);
          }
        });
    };
    subCategory();
  }, [selectCategory]);

  useEffect(() => {
    const productVideoPostData = [];
    const productVideoApiUrl = API + "products/product_video_list";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchProductVideo = async () => {
      await axios
        .post(productVideoApiUrl, productVideoPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setProductVideos(responseData.Results);
        });
    };
    fetchProductVideo();
  }, []);

  const [productImageEmployeeName, SetProductImageEmployeeName] = useState("");
  const [imageDestination, SetImageDestination] = useState("");
  const [files, setFiles] = useState([]);

  const onDropfileProductEmployee = (acceptedFiles) => {
    let file = acceptedFiles[0];
    SetProductImageEmployeeName(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // reader.onload = function() {
    //   setFormData({ ...formData, ["product_image_employee"]: file });
    // };

    // reader.onerror = function() {
    //   //console.log(reader.error);
    // };
    const cropper = new Cropper(imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination(canvas.toDataURL(file.type));
      },
    });
  };

  ///////////////////// base 64 to blob for 1st img//////////////////////////////////
  useEffect(() => {
    if (imageDestination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob = converterDataURItoBlob(imageDestination);
      if (blob.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = function() {
          setFormData({ ...formData, ["product_image_employee"]: blob });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination]);

  /////////////////////////////////////////////////////////////////

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const [productImageCustomerName, SetProductImageCustomerName] = useState("");
  const [imageDestination1, SetImageDestination1] = useState("");
  const [filesCustomer, setFilesCustomer] = useState([]);

  const onDropfileProductCustomer = (acceptedFiles) => {
    let file = acceptedFiles[0];
    SetProductImageCustomerName(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    setFilesCustomer(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // reader.onload = function() {
    //   setFormData({ ...formData, ["product_image_customer"]: file });
    // };
    // reader.onerror = function() {
    //   //console.log(reader.error);
    // };
    const cropper = new Cropper(imageElement1.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination1(canvas.toDataURL(file.type));
      },
    });
  };

  ///////////////////// base 64 to blob for 2st img//////////////////////////////////

  useEffect(() => {
    if (imageDestination1.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination1.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination1.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination1.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination1
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob1 = converterDataURItoBlob(imageDestination1);
      if (blob1.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob1);

        reader.onload = function() {
          setFormData({ ...formData, ["product_image_customer"]: blob1 });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination1]);

  /////////////////////////////////////////////////////////////////

  ///////  start multiples images for crop ////////////////////
  //image1
  const [productImage1Name, SetProductImage1Name] = useState("");
  const [image1Destination, SetImage1Destination] = useState("");
  const [files1, setFiles1] = useState([]);

  const onDropfileProductImage1 = (acceptedFiles) => {
    setFiles1([]);
    let file = acceptedFiles[0];
    SetProductImage1Name(file.name);
    setFiles1(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    const cropper = new Cropper(image1Element.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImage1Destination(canvas.toDataURL(file.type));
      },
    });
  };
  //base 64 to blob
  useEffect(() => {
    if (image1Destination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (image1Destination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(image1Destination.split(",")[1]);
        } else {
          byteString = encodeURI(image1Destination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = image1Destination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob1 = converterDataURItoBlob(image1Destination);
      if (blob1.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob1);

        reader.onload = function() {
          setFormData({ ...formData, ["product_image1"]: blob1 });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [image1Destination]);

  useEffect(
    () => () => {
      files1.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files1]
  );

  //image2
  const [productImage2Name, SetProductImage2Name] = useState("");
  const [image2Destination, SetImage2Destination] = useState("");
  const [files2, setFiles2] = useState([]);

  const onDropfileProductImage2 = (acceptedFiles) => {
    setFiles2([]);
    let file = acceptedFiles[0];
    SetProductImage2Name(file.name);
    setFiles2(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    const cropper = new Cropper(image2Element.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImage2Destination(canvas.toDataURL(file.type));
      },
    });
  };
  //base 64 to blob
  useEffect(() => {
    if (image2Destination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (image2Destination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(image2Destination.split(",")[1]);
        } else {
          byteString = encodeURI(image2Destination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = image2Destination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob1 = converterDataURItoBlob(image2Destination);
      if (blob1.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob1);

        reader.onload = function() {
          setFormData({ ...formData, ["product_image2"]: blob1 });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [image2Destination]);

  useEffect(
    () => () => {
      files2.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files2]
  );

  //////////////////////// End multiples images for product//////////////////////////

  const thumbsCustomer = filesCustomer.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      filesCustomer.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  const handleChangeSpecification = (name) => (event) => {
    setProdSpecification({ ...prodspecification, [name]: event.target.value });
  };

  const [errors, setErrors] = useState([]);

  const application_area_id_array = applicationAreaIds.toString();
  const category_id_array = categoryIds.toString();
  // console.log("Application Area Id Array : ",applicationAreaIds)
  // console.log("Category Id Array : ",categoryIds)

  const validateForm = () => {
    //console.log(prodspecification);

    let temp = { ...errors };
    let data;
    if (props.data.id) {
      data = {
        fastener_category_id: formData.fastener_category_id,
        // application_area_id: formData.application_area_id,
        // category_id: formData.category_id,
        sub_category_id: formData.sub_category_id,
        part_name_employee: formData.part_name_employee,
        part_name_customer: formData.part_name_customer,
        part_number_employee: formData.part_number_employee,
        part_number_customer: formData.part_number_customer,
        material: formData.material,
        color: formData.color,
        product_video_id: formData.product_video_id,

        hole_size_min: prodspecification.hole_size_min,
        hole_size_max: prodspecification.hole_size_max,
        panel_thickness_min: prodspecification.panel_thickness_min,
        panel_thickness_max: prodspecification.panel_thickness_max,
        pipe_dia_min: prodspecification.pipe_dia_min,
        pipe_dia_max: prodspecification.pipe_dia_max,
        screw_size_min: prodspecification.screw_size_min,
        screw_size_min_type: prodspecification.screw_size_min_type,
        screw_size_max: prodspecification.screw_size_max,
        screw_size_max_type: prodspecification.screw_size_max_type,
        dog_house_thickness_min: prodspecification.dog_house_thickness_min,
        dog_house_thickness_max: prodspecification.dog_house_thickness_max,
        stand_off_min: prodspecification.stand_off_min,
        stand_off_max: prodspecification.stand_off_max,
      };
    } else {
      data = {
        fastener_category_id: formData.fastener_category_id,
        // application_area_id: applicationAreaIds.toString(),
        // category_id: categoryIds.toString(),
        sub_category_id: formData.sub_category_id,
        part_name_employee: formData.part_name_employee,
        part_name_customer: formData.part_name_customer,
        part_number_employee: formData.part_number_employee,
        part_number_customer: formData.part_number_customer,
        material: formData.material,
        color: formData.color,
        product_video_id: formData.product_video_id,

        hole_size_min: prodspecification.hole_size_min,
        hole_size_max: prodspecification.hole_size_max,
        panel_thickness_min: prodspecification.panel_thickness_min,
        panel_thickness_max: prodspecification.panel_thickness_max,
        pipe_dia_min: prodspecification.pipe_dia_min,
        pipe_dia_max: prodspecification.pipe_dia_max,
        screw_size_min: prodspecification.screw_size_min,
        screw_size_min_type: prodspecification.screw_size_min_type,
        screw_size_max: prodspecification.screw_size_max,
        screw_size_max_type: prodspecification.screw_size_max_type,
        dog_house_thickness_min: prodspecification.dog_house_thickness_min,
        dog_house_thickness_max: prodspecification.dog_house_thickness_max,
        stand_off_min: prodspecification.stand_off_min,
        stand_off_max: prodspecification.stand_off_max,

        product_image_employee: formData.product_image_employee,
        product_image_customer: formData.product_image_customer,
        // product_image1: formData.product_image1,
        // product_image2: formData.product_image2,
      };
    }
    let rules;

    if (props.data.id) {
      rules = {
        fastener_category_id: "required",
        // application_area_id: "required",
        // category_id: "required",
        sub_category_id: "required",
        part_name_employee: "required",
        part_name_customer: "required",
        part_number_employee: "required",
        part_number_customer: "required",
        material: "required",
        color: "required",
        product_video_id: "required",
      };
    } else {
      rules = {
        fastener_category_id: "required",
        // application_area_id: "required",
        // category_id: "required",
        sub_category_id: "required",
        part_name_employee: "required",
        part_name_customer: "required",
        part_number_employee: "required",
        part_number_customer: "required",
        material: "required",
        color: "required",
        product_video_id: "required",

        // hole_size_min: "",
        // hole_size_max: "",
        // panel_thickness_min: "",
        // panel_thickness_max: "",
        // pipe_dia_min: "",
        // pipe_dia_max: "",
        // screw_size_min: "",
        // screw_size_max: "",
        // dog_house_thickness_min: "",
        // dog_house_thickness_max: "",
        // stand_off_min: "",
        // stand_off_max: "",

        product_image_employee: "required",
        product_image_customer: "required",
      };
    }
    let validation = new Validator(data, rules);

    if (validation.passes()) {
      return true;
    }

    if (validation.fails()) {
      temp.fastener_category_id = validation.errors.first(
        "fastener_category_id"
      );
      // temp.application_area_id = validation.errors.first("application_area_id");
      // temp.category_id = validation.errors.first("category_id");
      temp.sub_category_id = validation.errors.first("sub_category_id");
      temp.part_name_employee = validation.errors.first("part_name_employee");
      temp.part_name_customer = validation.errors.first("part_name_customer");
      temp.part_number_employee = validation.errors.first(
        "part_number_employee"
      );
      temp.part_number_customer = validation.errors.first(
        "part_number_customer"
      );
      temp.material = validation.errors.first("material");
      temp.color = validation.errors.first("color");
      temp.product_video_id = validation.errors.first("product_video_id");
      // temp.hole_size_min = validation.errors.first("hole_size_min");
      // temp.hole_size_max = validation.errors.first("hole_size_max");
      // temp.panel_thickness_min = validation.errors.first("panel_thickness_min");
      // temp.panel_thickness_max = validation.errors.first("panel_thickness_max");
      // temp.pipe_dia_min = validation.errors.first("pipe_dia_min");
      // temp.pipe_dia_max = validation.errors.first("pipe_dia_max");
      // temp.screw_size_min = validation.errors.first("screw_size_min");
      // temp.screw_size_max = validation.errors.first("screw_size_max");
      // temp.dog_house_thickness_min = validation.errors.first("dog_house_thickness_min");
      // temp.dog_house_thickness_max = validation.errors.first("dog_house_thickness_max");
      // temp.stand_off_min = validation.errors.first("stand_off_min");
      // temp.stand_off_max = validation.errors.first("stand_off_max");

      temp.product_image_employee = validation.errors.first(
        "product_image_employee"
      );
      temp.product_image_customer = validation.errors.first(
        "product_image_customer"
      );
      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async () => {
    if (validateForm()) {
      setShowLoading(true);
      let form_data = new FormData();
      form_data.append("email", userData.email);
      form_data.append("type", userData.type);
      form_data.append("fastener_category_id", formData.fastener_category_id);
      form_data.append("application_area_id", applicationAreaIds);
      form_data.append("category_id", categoryIds);
      form_data.append("sub_category_id", formData.sub_category_id);
      form_data.append("part_name_employee", formData.part_name_employee);
      form_data.append("part_name_customer", formData.part_name_customer);
      form_data.append(
        "part_number_employee",
        formData.part_number_employee.trim()
      );
      form_data.append(
        "part_number_customer",
        formData.part_number_customer.trim()
      );
      form_data.append("material", formData.material);
      form_data.append("color", formData.color);
      form_data.append("product_video_id", formData.product_video_id);

      form_data.append(
        "hole_size_min",
        prodspecification.hole_size_min || null
      );
      form_data.append(
        "hole_size_max",
        prodspecification.hole_size_max || null
      );
      form_data.append(
        "hole_size_width",
        prodspecification.hole_size_width || null
      );
      form_data.append(
        "hole_size_height",
        prodspecification.hole_size_height || null
      );
      form_data.append(
        "panel_thickness_min",
        prodspecification.panel_thickness_min || null
      );
      form_data.append(
        "panel_thickness_max",
        prodspecification.panel_thickness_max || null
      );
      form_data.append(
        "pipe_dia_min_one",
        prodspecification.pipe_dia_min_one || null
      );
      form_data.append(
        "pipe_dia_max_one",
        prodspecification.pipe_dia_max_one || null
      );
      form_data.append(
        "pipe_dia_min_two",
        prodspecification.pipe_dia_min_two || null
      );
      form_data.append(
        "pipe_dia_max_two",
        prodspecification.pipe_dia_max_two || null
      );
      form_data.append(
        "pipe_dia_min_three",
        prodspecification.pipe_dia_min_three || null
      );
      form_data.append(
        "pipe_dia_max_three",
        prodspecification.pipe_dia_max_three || null
      );
      form_data.append(
        "pipe_dia_min_four",
        prodspecification.pipe_dia_min_four || null
      );
      form_data.append(
        "pipe_dia_max_four",
        prodspecification.pipe_dia_max_four || null
      );
      form_data.append(
        "pipe_dia_min_five",
        prodspecification.pipe_dia_min_five || null
      );
      form_data.append(
        "pipe_dia_max_five",
        prodspecification.pipe_dia_max_five || null
      );
      form_data.append(
        "screw_size_min",
        prodspecification.screw_size_min || null
      );
      form_data.append(
        "screw_size_min_type",
        prodspecification.screw_size_min_type || null
      );
      form_data.append(
        "screw_size_max",
        prodspecification.screw_size_max || null
      );
      form_data.append(
        "screw_size_max_type",
        prodspecification.screw_size_max_type || null
      );
      form_data.append(
        "dog_house_thickness_min",
        prodspecification.dog_house_thickness_min || null
      );
      form_data.append(
        "dog_house_thickness_max",
        prodspecification.dog_house_thickness_max || null
      );
      form_data.append(
        "stand_off_min",
        prodspecification.stand_off_min || null
      );
      form_data.append(
        "stand_off_max",
        prodspecification.stand_off_max || null
      );
      form_data.append(
        "product_image_employee",
        formData.product_image_employee
      );
      form_data.append(
        "product_image_customer",
        formData.product_image_customer
      );
      form_data.append("product_image1", formData.product_image1);
      form_data.append("product_image2", formData.product_image2);

      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };
      const apiAddProduct = API + "products/products_add";
      const apiUpdateProduct = API + "products/product_update";
      console.log(formData);
      // console.log("Product Form Data : ",form_data)
      if (props.data.id) {
        form_data.append("id", props.data.id);

        await axios
          .post(apiUpdateProduct, form_data, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            setShowLoading(false);
            if (responseData.Status === false) {
              alert(responseData.Message);
            } else {
              alert(responseData.Message);
              props.history.push({
                pathname: "/products/list",
              });
            }
          });
      } else {
        await axios
          .post(apiAddProduct, form_data, { axiosConfig })
          .then((response) => response.data)
          .then((responseData) => {
            setShowLoading(false);
            if (responseData.Status === false) {
              alert(responseData.Message);
            } else {
              alert(responseData.Message);
              props.history.push({
                pathname: "/products/list",
              });
            }
          });
      }
    }
  };

  // Full video path
  let product_img_emp =
    formData.ProductImages?.length > 0 ? formData.ProductImages[0] : "";
  let product_img_cust =
    formData.ProductImages?.length > 0 ? formData.ProductImages[1] : "";
  let product_img_1 =
    formData.ProductImages?.length > 0 ? formData.ProductImages[2] : "";
  let product_img_2 =
    formData.ProductImages?.length > 0 ? formData.ProductImages[3] : "";

  let full_prod_img_emp = productPath + product_img_emp.product_image;
  let full_prod_img_cust = productPath + product_img_cust.product_image;
  let full_prod_img_1 = productPath + product_img_1?.product_image;
  let full_prod_img_2 = productPath + product_img_2?.product_image;
  //console.log(formData.video)
  // if(productVideo !== undefined)
  // {
  //   console.log("inside");
  // }
  //  console.log(productVideo)

  // {applicationArea.map((applicationArea, index) => (
  //   <option key={applicationArea.id} value={applicationArea.id}>
  //     {applicationArea.name}
  //   </option>
  // ))}

  const doubleValue = applicationArea.map((applicationAreas) => {
    return applicationAreas;
  });

  const data = applicationArea.map((applicationArea, index) => (
    <option key={applicationArea.id} value={applicationArea.id}>
      {applicationArea.name}
    </option>
  ));

  const onSelectApplicationArea = (selectedList, selectedItem) => {
    console.log(selectedList);
    setSelectApplicationArea(selectedList);
  };

  const onRemoveApplicationArea = (selectedList, removedItem) => {
    setSelectApplicationArea(selectedList);
  };

  const onSelectCategory = (selectedCategoryList, selectedCategoryItem) => {
    setSelectCategory(selectedCategoryList);
  };

  const onRemoveCategory = (selectedCategoryList, removedCategoryItem) => {
    setSelectCategory(selectedCategoryList);
  };

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className={classes.root}>
          <div className="headLogo">
            <h4>{props.data.id == null ? "Add Product" : "Edit Product"}</h4>
            <img src={headLogo} alt="headLogo" />
          </div>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              encType="multipart/form-data"
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-select-currency-native"
                select
                label="Select Fastener*"
                className={classes.textField}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formData.fastener_category_id || ""}
                onChange={handleChange("fastener_category_id")}
                fullWidth
                error={!!errors.fastener_category_id}
                helperText={errors.fastener_category_id}
              >
                <option value=""></option>
                {fasteners.map((fastener, index) => (
                  <option key={fastener.id} value={fastener.id}>
                    {fastener.name}
                  </option>
                ))}
              </TextField>

              {/* <TextField
                id="outlined-select-currency-native"
                select
                label="Select Application Area*"
                className={classes.textField}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formData.application_area_id || ""}
                onChange={handleChange("application_area_id")}
                fullWidth
                error={!!errors.application_area_id}
                helperText={errors.application_area_id}
              >
                <option value=""></option>
                {applicationArea.map((applicationArea, index) => (
                  <option key={applicationArea.id} value={applicationArea.id}>
                    {applicationArea.name}
                  </option>
                ))}
              </TextField> */}

              <Multiselect
                options={applicationArea}
                selectedValues={selectApplicationArea}
                onSelect={onSelectApplicationArea}
                onRemove={onRemoveApplicationArea}
                displayValue="name"
                placeholder="Select Application Area"
                style={{ margin: "10px 0px" }}
                //  value={applicationAreaEditIds || ""}
                value={applicationAreaEditIds}
              />

              {/* <TextField
                id="outlined-select-currency-native"
                select
                label="Select Category*"
                className={classes.textField}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formData.category_id || ""}
                onChange={handleChange("category_id")}
                fullWidth
                error={!!errors.category_id}
                helperText={errors.category_id}
              >
                <option value=""></option>
                {category.map((Category, index) => (
                  <option key={Category.id} value={Category.id}>
                    {Category.name}
                  </option>
                ))}
              </TextField> */}

              <Multiselect
                options={category}
                selectedValues={selectCategory}
                onSelect={onSelectCategory}
                onRemove={onRemoveCategory}
                displayValue="name"
                placeholder="Select Category"
                style={{ margin: "10px 0px" }}
              />

              <TextField
                id="outlined-select-currency-native"
                select
                label="Select Sub Category*"
                className={classes.textField}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formData.sub_category_id || ""}
                onChange={handleChange("sub_category_id")}
                fullWidth
                error={!!errors.sub_category_id}
                helperText={errors.sub_category_id}
              >
                <option value=""></option>
                {subCategory.map((subCategory, index) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
              </TextField>

              <TextField
                id="part-name-employee"
                className={classes.textField}
                label="Part Name for Employee*"
                variant="outlined"
                value={formData.part_name_employee || ""}
                onChange={handleChange("part_name_employee")}
                fullWidth
                error={!!errors.part_name_employee}
                helperText={errors.part_name_employee}
              />

              <TextField
                id="part-name-customer"
                className={classes.textField}
                label="Part Name For Customer*"
                variant="outlined"
                value={formData.part_name_customer || ""}
                onChange={handleChange("part_name_customer")}
                fullWidth
                error={!!errors.part_name_customer}
                helperText={errors.part_name_customer}
              />

              <TextField
                id="part-nunmber-employee"
                className={classes.textField}
                label="Part Number for Employee *"
                variant="outlined"
                value={formData.part_number_employee || ""}
                onChange={handleChange("part_number_employee")}
                fullWidth
                error={!!errors.part_number_employee}
                helperText={errors.part_number_employee}
              />

              <TextField
                id="part-nunmber-customer"
                className={classes.textField}
                label="Part Number for Customer*"
                variant="outlined"
                value={formData.part_number_customer || ""}
                onChange={handleChange("part_number_customer")}
                fullWidth
                error={!!errors.part_number_customer}
                helperText={errors.part_number_customer}
              />

              <TextField
                id="material"
                className={classes.textField}
                label="Material*"
                variant="outlined"
                value={formData.material || ""}
                onChange={handleChange("material")}
                fullWidth
                error={!!errors.material}
                helperText={errors.material}
              />

              <TextField
                id="color"
                className={classes.textField}
                label="Color*"
                variant="outlined"
                value={formData.color || ""}
                onChange={handleChange("color")}
                fullWidth
                error={!!errors.color}
                helperText={errors.color}
              />

              <TextField
                id="outlined-select-currency-native"
                select
                label="Select Product Video Name*"
                className={classes.textField}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formData.product_video_id || ""}
                onChange={handleChange("product_video_id")}
                fullWidth
                error={!!errors.product_video_id}
                helperText={errors.product_video_id}
              >
                <option value=""></option>
                {productVideos.map((productvideo, index) => (
                  <option key={productvideo.id} value={productvideo.id}>
                    {productvideo.name}
                  </option>
                ))}
              </TextField>

              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="hole-size"
                      className={classes.textField}
                      label="Hole Size Minimum (MM)"
                      variant="outlined"
                      type="text"
                      value={prodspecification.hole_size_min || ""}
                      onChange={handleChangeSpecification("hole_size_min")}
                      fullWidth
                      error={!!errors.hole_size_min}
                      helperText={errors.hole_size_min}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="hole-size"
                      className={classes.textField}
                      label="Hole Size Maximum (MM)"
                      variant="outlined"
                      type="text"
                      value={prodspecification.hole_size_max || ""}
                      onChange={handleChangeSpecification("hole_size_max")}
                      fullWidth
                      error={!!errors.hole_size_max}
                      helperText={errors.hole_size_max}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        id="hole_size_width"
                        className={classes.textField}
                        label="Hole Slot Size Minimum (MM)"
                        variant="outlined"
                        type="text"
                        value={prodspecification.hole_size_width || ""}
                        onChange={handleChangeSpecification("hole_size_width")}
                        fullWidth
                        error={!!errors.hole_size_width}
                        helperText={errors.hole_size_width}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="hole_size_height"
                        className={classes.textField}
                        label="Hole Slot Size Maximum (MM)"
                        variant="outlined"
                        type="text"
                        value={prodspecification.hole_size_height || ""}
                        onChange={handleChangeSpecification("hole_size_height")}
                        fullWidth
                        error={!!errors.hole_size_height}
                        helperText={errors.hole_size_height}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="panel-thickness-min"
                      className={classes.textField}
                      label="Panel Thickness Minimum (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.panel_thickness_min || ""}
                      onChange={handleChangeSpecification(
                        "panel_thickness_min"
                      )}
                      fullWidth
                      error={!!errors.panel_thickness_min}
                      helperText={errors.panel_thickness_min}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="anel-thickness-max"
                      className={classes.textField}
                      label="Panel Thickness Maximum (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.panel_thickness_max || ""}
                      onChange={handleChangeSpecification(
                        "panel_thickness_max"
                      )}
                      fullWidth
                      error={!!errors.panel_thickness_max}
                      helperText={errors.panel_thickness_max}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-min-one"
                      className={classes.textField}
                      label="Pipe Dia Minimum 1 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_min_one || ""}
                      onChange={handleChangeSpecification("pipe_dia_min_one")}
                      fullWidth
                      error={!!errors.pipe_dia_min_one}
                      helperText={errors.pipe_dia_min_one}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-max-one"
                      className={classes.textField}
                      label="Pipe Dia Maximum 1 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_max_one || ""}
                      onChange={handleChangeSpecification("pipe_dia_max_one")}
                      fullWidth
                      error={!!errors.pipe_dia_max_one}
                      helperText={errors.pipe_dia_max_one}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-min-two"
                      className={classes.textField}
                      label="Pipe Dia Minimum 2 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_min_two || ""}
                      onChange={handleChangeSpecification("pipe_dia_min_two")}
                      fullWidth
                      error={!!errors.pipe_dia_min_two}
                      helperText={errors.pipe_dia_min_two}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-max-two"
                      className={classes.textField}
                      label="Pipe Dia Maximum 2 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_max_two || ""}
                      onChange={handleChangeSpecification("pipe_dia_max_two")}
                      fullWidth
                      error={!!errors.pipe_dia_max_two}
                      helperText={errors.pipe_dia_max_two}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-min-three"
                      className={classes.textField}
                      label="Pipe Dia Minimum 3 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_min_three || ""}
                      onChange={handleChangeSpecification("pipe_dia_min_three")}
                      fullWidth
                      error={!!errors.pipe_dia_min_three}
                      helperText={errors.pipe_dia_min_three}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-max-three"
                      className={classes.textField}
                      label="Pipe Dia Maximum 3 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_max_three || ""}
                      onChange={handleChangeSpecification("pipe_dia_max_three")}
                      fullWidth
                      error={!!errors.pipe_dia_max_three}
                      helperText={errors.pipe_dia_max_three}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-min-four"
                      className={classes.textField}
                      label="Pipe Dia Minimum 4 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_min_four || ""}
                      onChange={handleChangeSpecification("pipe_dia_min_four")}
                      fullWidth
                      error={!!errors.pipe_dia_min_four}
                      helperText={errors.pipe_dia_min_four}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-max-four"
                      className={classes.textField}
                      label="Pipe Dia Maximum 4 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_max_four || ""}
                      onChange={handleChangeSpecification("pipe_dia_max_four")}
                      fullWidth
                      error={!!errors.pipe_dia_max_four}
                      helperText={errors.pipe_dia_max_four}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-min-five"
                      className={classes.textField}
                      label="Pipe Dia Minimum 5 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_min_five || ""}
                      onChange={handleChangeSpecification("pipe_dia_min_five")}
                      fullWidth
                      error={!!errors.pipe_dia_min_five}
                      helperText={errors.pipe_dia_min_five}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="pipe-dia-max-five"
                      className={classes.textField}
                      label="Pipe Dia Maximum 5 (MM)"
                      type="text"
                      variant="outlined"
                      value={prodspecification.pipe_dia_max_five || ""}
                      onChange={handleChangeSpecification("pipe_dia_max_five")}
                      fullWidth
                      error={!!errors.pipe_dia_max_five}
                      helperText={errors.pipe_dia_max_five}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      id="screw-size-min"
                      className={classes.textField}
                      label="Screw Size Minimum"
                      variant="outlined"
                      type="text"
                      value={prodspecification.screw_size_min || ""}
                      onChange={handleChangeSpecification("screw_size_min")}
                      fullWidth
                      error={!!errors.screw_size_min}
                      helperText={errors.screw_size_min}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      label="Screw Type"
                      className={classes.textField}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      value={prodspecification.screw_size_min_type || ""}
                      onChange={handleChangeSpecification(
                        "screw_size_min_type"
                      )}
                      fullWidth
                      error={!!errors.screw_size_min_type}
                      helperText={errors.screw_size_min_type}
                    >
                      <option value=""></option>
                      <option value="ST">ST</option>
                      <option value="MM">MM</option>
                      <option value="T">T</option>
                      {/* {fasteners.map((fastener, index) => (
                        <option key={fastener.id} value={fastener.id}>
                          {fastener.name}
                        </option>
                      ))} */}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="screw-size-max"
                      className={classes.textField}
                      label="Screw Size Maximum"
                      variant="outlined"
                      type="text"
                      value={prodspecification.screw_size_max || ""}
                      onChange={handleChangeSpecification("screw_size_max")}
                      fullWidth
                      error={!!errors.screw_size_max}
                      helperText={errors.screw_size_max}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      label="Screw Type"
                      className={classes.textField}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      value={prodspecification.screw_size_max_type || ""}
                      onChange={handleChangeSpecification(
                        "screw_size_max_type"
                      )}
                      fullWidth
                      error={!!errors.screw_size_max_type}
                      helperText={errors.screw_size_max_type}
                    >
                      <option value=""></option>
                      <option value="ST">ST</option>
                      <option value="MM">MM</option>
                      <option value="T">T</option>
                      {/* {fasteners.map((fastener, index) => (
                        <option key={fastener.id} value={fastener.id}>
                          {fastener.name}
                        </option>
                      ))} */}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="dog-house-thickness-min"
                      className={classes.textField}
                      label="Dog House Thickness Minimum (MM)"
                      variant="outlined"
                      type="text"
                      value={prodspecification.dog_house_thickness_min || ""}
                      onChange={handleChangeSpecification(
                        "dog_house_thickness_min"
                      )}
                      fullWidth
                      error={!!errors.dog_house_thickness_min}
                      helperText={errors.dog_house_thickness_min}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="dog-house-thickness-max"
                      className={classes.textField}
                      label="Dog House Thickness Maximum (MM)"
                      variant="outlined"
                      type="text"
                      value={prodspecification.dog_house_thickness_max || ""}
                      onChange={handleChangeSpecification(
                        "dog_house_thickness_max"
                      )}
                      fullWidth
                      error={!!errors.dog_house_thickness_max}
                      helperText={errors.dog_house_thickness_max}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="stand-off-min"
                      className={classes.textField}
                      type="text"
                      label="Stand Off Minimum (MM)"
                      variant="outlined"
                      value={prodspecification.stand_off_min || ""}
                      onChange={handleChangeSpecification("stand_off_min")}
                      fullWidth
                      error={!!errors.stand_off_min}
                      helperText={errors.stand_off_min}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="stand-off-max"
                      className={classes.textField}
                      type="text"
                      label="Stand Off Maximum (MM)"
                      variant="outlined"
                      value={prodspecification.stand_off_max || ""}
                      onChange={handleChangeSpecification("stand_off_max")}
                      fullWidth
                      error={!!errors.stand_off_max}
                      helperText={errors.stand_off_max}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <h5>
                      <b>Add Product Image For Employee</b>
                    </h5>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        onDropfileProductEmployee(acceptedFiles)
                      }
                      accept="image/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag & Drop Product Image For Employee Or click to
                              select
                              <br />
                              (Only 1 Image file)
                              <br />
                              <span>{productImageEmployeeName}</span>
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errors && errors !== null ? (
                      <small className={classes.errorsmall}>
                        {errors.product_image_employee}
                      </small>
                    ) : (
                      ""
                    )}
                    {files.map((file) => (
                      <>
                        <div>
                          <div className="img-container">
                            <img
                              className="img-size"
                              ref={imageElement}
                              src={file.preview}
                              alt="Source"
                              crossorigin
                            />
                          </div>
                          <img
                            src={imageDestination}
                            className="img-preview p-4"
                            alt="Destination"
                          />
                        </div>
                      </>
                    ))}
                    {product_img_emp.product_image &&
                    product_img_emp.product_image !== null ? (
                      <img
                        className="pb-5"
                        src={full_prod_img_emp}
                        width="300px"
                      />
                    ) : (
                      ""
                    )}
                    <br />
                    {/* <aside className={classes.thumbsContainer}>{thumbs}</aside> */}
                  </Grid>

                  <Grid item xs={6}>
                    <h5>
                      <b>Add Product Image For Customer</b>
                    </h5>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        onDropfileProductCustomer(acceptedFiles)
                      }
                      accept="image/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag & Drop Product Image For Customer Or click to
                              select
                              <br />
                              (Only 1 Image file)
                              <br />
                              <span>{productImageCustomerName}</span>
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errors && errors !== null ? (
                      <small className={classes.errorsmall}>
                        {errors.product_image_customer}
                      </small>
                    ) : (
                      ""
                    )}
                    {filesCustomer.map((file) => (
                      <>
                        <div>
                          <div className="img-container">
                            <img
                              className="img-size"
                              ref={imageElement1}
                              src={file.preview}
                              alt="Source"
                              crossorigin
                            />
                          </div>
                          <img
                            src={imageDestination1}
                            className="img-preview p-4"
                            alt="Destination"
                          />
                        </div>
                      </>
                    ))}
                    {product_img_emp.product_image &&
                    product_img_emp.product_image !== null ? (
                      <img
                        className="pb-5"
                        src={full_prod_img_cust}
                        width="300px"
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* multiple images for products */}

              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <h5>
                      <b>Add Product Image1 For Customer</b>
                    </h5>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        onDropfileProductImage1(acceptedFiles)
                      }
                      accept="image/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag & Drop Product Image For Customer Or click to
                              select
                              <br />
                              (Only 1 Image file)
                              <br />
                              <span>{productImage1Name}</span>
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errors && errors !== null ? (
                      <small className={classes.errorsmall}>
                        {errors.product_image1}
                      </small>
                    ) : (
                      ""
                    )}
                    {files1.map((file) => (
                      <>
                        <div>
                          <div className="img-container">
                            <img
                              className="img-size"
                              ref={image1Element}
                              src={file.preview}
                              alt="Source"
                              crossorigin
                            />
                          </div>
                          <img
                            src={image1Destination}
                            className="img-preview p-4"
                            alt="Destination"
                          />
                        </div>
                      </>
                    ))}
                    {product_img_1?.product_image &&
                    product_img_1?.product_image !== undefined &&
                    product_img_1?.product_image !== null ? (
                      <img
                        className="pb-5"
                        src={full_prod_img_1}
                        width="300px"
                      />
                    ) : (
                      ""
                    )}
                    <br />
                  </Grid>

                  <Grid item xs={6}>
                    <h5>
                      <b>Add Product Image2 For Customer</b>
                    </h5>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        onDropfileProductImage2(acceptedFiles)
                      }
                      accept="image/*"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag & Drop Product Image For Customer Or click to
                              select
                              <br />
                              (Only 1 Image file)
                              <br />
                              <span>{productImage2Name}</span>
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {errors && errors !== null ? (
                      <small className={classes.errorsmall}>
                        {errors.product_image2}
                      </small>
                    ) : (
                      ""
                    )}
                    {files2.map((file) => (
                      <>
                        <div>
                          <div className="img-container">
                            <img
                              className="img-size"
                              ref={image2Element}
                              src={file.preview}
                              alt="Source"
                              crossorigin
                            />
                          </div>
                          <img
                            src={image2Destination}
                            className="img-preview p-4"
                            alt="Destination"
                          />
                        </div>
                      </>
                    ))}
                    {product_img_2?.product_image &&
                    product_img_2?.product_image !== undefined &&
                    product_img_2?.product_image !== null ? (
                      <img
                        className="pb-5"
                        src={full_prod_img_2}
                        width="300px"
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <button
                type="button"
                onClick={() => formSubmit()}
                className="btnCreate"
                variant="contained"
              >
                Submit
              </button>

              <NavLink className={classes.cancelBtn} to="/products/list">
                <button className="btnCreate" variant="contained">
                  Cancel
                </button>
              </NavLink>
            </form>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default withRouter(CreateProduct);
