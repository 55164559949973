import React, { useState, useEffect, useRef } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import "../utils/imagecropper.css";

// export const ThemeContext = React.createContext();

const ImageCropper = (props) => {
  const imageElement = useRef();
  const [imageDestination, SetImageDestination] = useState("");

  useEffect(() => {
    const cropper = new Cropper(imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination(canvas.toDataURL("image/png"));
      },
    });
  }, []);

  //   console.log(imageDestination);

  return (
    <>
      <div>
        <div className="img-container">
          <img
            className="img-size"
            ref={imageElement}
            src={props.imagepath}
            alt="Source"
            crossorigin
          />
        </div>
        <img src={imageDestination} className="img-preview" alt="Destination" />
      </div>
      {/* {imageDestination && <Crop crop={imageDestination} />} */}
      {/* <ThemeContext.Provider crop={imageDestination}>
        <Crop />
      </ThemeContext.Provider> */}
    </>
  );
};
export default ImageCropper;
