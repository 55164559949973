import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import UseStyles from "./UseStyles";
import headLogo from '../common/assets/ITW-logo-small.png'
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { NavLink} from "react-router-dom";

export default function DisapprovedReason(props) {
    const classes = UseStyles();
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    const id = props.match.params.id;
    const ApiUrl = API+"products/"+id;
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
        const fetchProductDetails = async() => {
            await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
            .then((responseData)=>{
                console.log(responseData)
                setFormData(responseData.Results);
            });
        };
        fetchProductDetails();
  },[]);

// console.log(formData)
  return (

    <>
        <div className="headLogo">
            <h4>Disapproved Reason</h4>
            <img src={headLogo} alt="headLogo" />
        </div>
         
        { formData && (
            
                <Paper className={classes.paper}>
                    <List component="nav" className={classes.root} aria-label="Mailbox folders">
                        <ListItem button>
                            <ListItemText><span><h5><b>Reason :</b></h5></span>{formData.disapproved_reason}</ListItemText>
                            </ListItem>
                    </List>
                    <NavLink className={classes.cancelBtn} to="/products/disapproved">
                            <button 
                                    className="btn btn-primary"
                                    variant="contained"
                            >
                                Cancel
                            </button>
                    </NavLink>
                </Paper>
            
        
        )}
    </>
);
}
