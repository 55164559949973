import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../common";
import EditProductForm from "./form";
import { RiseLoader } from "react-spinners";

const EditProduct = (props) => {
  const [formData, setFormData] = useState([]);
  const [productPath, setProductPath] = useState();
  const [applicationEditData, setApplicationEditData] = useState([]);
  const [categoryEditData, setCategoryEditData] = useState([]);

  useEffect(() => {
    const id = props.match.params.id;
    const ApiUrl = API + "products/" + id;
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchProduct = async () => {
      await axios
        .get(ApiUrl, id, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setFormData(responseData.Results);
          // set product path
          setApplicationEditData(responseData.Application);
          setCategoryEditData(responseData.Category);
          setProductPath(responseData.ProductPath);
        });
      setShowLoading(false);
    };
    fetchProduct();
  }, []);
  // console.log(applicationEditData);
  const [showLoading, setShowLoading] = useState(true);
  return (
    <>
      {showLoading && (
        <RiseLoader sizeUnit={"px"} size={20} color={"#5578eb"} />
      )}
      {formData && (
        <EditProductForm
          data={formData}
          applicationEditData={applicationEditData}
          categoryEditData={categoryEditData}
          productpath={productPath}
        />
      )}
    </>
  );
};

export default EditProduct;
