//prod
export const API = "https://itwindiaapp.com:3001/api/";
export const BASE_URL = "https://itwindiaapp.com/";

//local
// export const API = "http://localhost:3001/api/";
// export const BASE_URL = "http://localhost:3000/";

//test
//export const API = "http://206.189.134.93:3001/api/";
//export const BASE_URL = "http://206.189.134.93:3000/";
