import React from 'react';

const Team = (props) => {
    return(
        <>
        
                    <div class="col-md-4 mb-5 px-4">
                        <img className="img-fluid" src={props.imgsrc} />
                        <h4 className="mt-3 member">{props.member}</h4>
                        <p className="member-dest">{props.designation}</p>
                    </div>
                
</>
    )
}


export default Team;