import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../common";
import EditLandingForm from "./form";
import { RiseLoader } from "react-spinners";

const EditLanding = (props) => {
  const [formData, setFormData] = useState([]);
  const [filePath, setFilePath] = useState();

  console.log(props);
  useEffect(() => {
    // const orgPostData =[];
    const id = props.match.params.id;
    // const id = 1;
    console.log(id);
    const ApiUrl = API + "landings/" + id;
    console.log(ApiUrl);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchUser = async () => {
      await axios
        .get(ApiUrl, id, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          console.log(responseData);
          setFormData(responseData.Results);
          setFilePath(responseData.FilePath);
        });
      setShowLoading(false);
    };
    fetchUser();
  }, []);

  const [showLoading, setShowLoading] = useState(true);
  console.log(formData);
  return (
    <>
      {showLoading && (
        <RiseLoader sizeUnit={"px"} size={20} color={"#5578eb"} />
      )}
      {formData && <EditLandingForm data={formData} filepath={filePath} />}
    </>
  );
};

export default EditLanding;
