import React from 'react';
import ThreeSection from "./ThreeSection";
import Banner from "./Banner";
import ContactBanner from "../common/assets/Contact-banner@2x.jpg";
import Navbar from './Navbar';
import Footer from './Footer';
const Contact = () => {

  return (
    <>
    <Navbar/>
      <Banner 
            imgsrc={ContactBanner}
            heading="Contact Us"
        />

          <section className="three-section mt-2" >
            <div className="container">
                <div className="row">
                    <ThreeSection  
                    heading="Corporate & North Sales Office"
                    paragraph="ITW India Pvt. Ltd. Level -1, Lotus Plaza (Opp. ITI) Meharauli-Gurgaon Road, Sector-14 Gurgaon, 
                    Haryana-122001 +91-124-3020604"
                    />
                    
                    <ThreeSection 
                    heading="BUSINESS SEGMENTS"
                    paragraph="ITW's seven industry-leading segments leverage the ITW Business Model to generate solid growth with best-in-class margins and returns."
                    />
                    <ThreeSection 
                    heading="ENTERPRISE STRATEGY"
                    paragraph="Our long-term enterprise strategy is focused on leveraging the performance power of the ITW Business Model."
                    />
                    
                </div>
            </div>
        </section>

        <section className="text-center  contactsection">
          <h3 className="mb-5 contactus">Please fill the form to get call back from us...</h3>

          <div className="container">
            <div className="form-floating mb-3">
              <input type="name" className="form-control form-control-lg" id="floatingInput" placeholder="Enter Your Full Name" />
              {/* <label for="floatingInput">Full Name</label> */}
            </div>
            <div class="form-floating mb-3">
              <input type="email" class="form-control form-control-lg" id="floatingPassword" placeholder="Enter Your Email" />
              {/* <label for="floatingPassword">Email</label> */}
            </div>
            <div class="form-floating mb-3">
              <input type="name" class="form-control form-control-lg" id="floatingPassword" placeholder="Enter Your Message" />
              {/* <label for="floatingPassword">Message</label> */}
            </div>
          </div>
          <a href="#" className="btn btn-default"><span>Submit</span></a>
        </section>
    <Footer/>
    </>
  )
}
export default Contact;
