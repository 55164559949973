import React, { useState, useEffect } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { API } from "../../../common";
import { RiseLoader } from "react-spinners";
import Grid from "@material-ui/core/Grid";
import LoadingOverlay from "react-loading-overlay";
import Dropzone from "react-dropzone";
import csv from "csv";
import "../common/CSS/style.css";
import UseStyles from "./UseStyles";
import CSV from "../../../_metronic/_assets/image/Products.csv";

export default function BulkUpload(props) {
  const classes = UseStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [csvdemo, setcsvdata] = useState([]);
  const [sampleCsvPath, setSampleCsvPath] = useState();

  useEffect(() => {
    setcsvdata(csvdemo);
  }, [csvdemo]);

  const apiUrl = API + "products/product_show_list";

  useEffect(() => {
    // const fetchCsvUrl = async () => {
    //   const token = localStorage.getItem("token");
    //   let axiosConfig = {
    //     headers: {
    //       "Content-Type": "application/json;charset=UTF-8",
    //       Authorization: token[1],
    //     },
    //   };
    //   var postData = {};
    //   const result = await axios
    //     .post(apiUrl, postData, axiosConfig)
    //     .then((response) => response.data)
    //     .then((responseData) => {
    //         setSampleCsvPath(responseData.ProductPath)
    //     });
    // };
    // fetchCsvUrl();
    setSampleCsvPath("");
  }, []);

  const onDropprofile = (acceptedFiles) => {
    setShowLoading(true);
    let file = acceptedFiles[0];
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        setcsvdata(data);
        formSubmit(data);
      });
    };
  };

  let UserType = JSON.parse(localStorage.getItem("data"));

  const formSubmit = async (data) => {
    console.log("showLoading" + showLoading);
    console.log("csv data");
    console.log(data);
    var post_data = {
      datafile: data,
      type: UserType.type,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const send_data = API + "products/product_csv_upload";

    await axios
      .post(send_data, post_data, { axiosConfig })
      .then((response) => response.data)
      .then((responseData) => {
        console.log(responseData);
        setShowLoading(false);
        if (responseData.Status === false) {
          alert(responseData.Message);
        } else {
          alert(responseData.Message);
          props.history.push({
            pathname: "/products/list",
          });
        }
      });
  };

  const DownloadCsvFile = sampleCsvPath + "Products.csv";
  console.log(DownloadCsvFile);

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className={classes.root}>
          <h5>
            <b>Bulk Upload</b>
          </h5>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              encType="multipart/form-data"
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <Dropzone
                      onDrop={(acceptedFiles) => onDropprofile(acceptedFiles)}
                      accept=".csv"
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' Drop CSV Here or Or click to select
                              <br />
                              (Select Only 1 file)
                              <br />
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div>
                      <h4>Instructions:</h4>
                      <ul style={{ color: "red", marginLeft: "2rem" }}>
                        <li>Don't change column sequence.</li>
                        <li>Download below sample CSV for reference.</li>
                        <li>
                          in multi application and category use separate (-)
                          hash to separate. Don't add any extra space.
                        </li>
                        <li>
                          video, application & category name should be same from
                          admin portal.
                        </li>
                        <li>
                          Don't add wrong fastner, application, category &
                          subcategory. Otherwise those row will not be imported.
                        </li>
                        <li>
                          Please add images in S3 bucket and provide the path as
                          given in sample file.
                        </li>
                      </ul>
                    </div>

                    <a href={CSV} download>
                      Click to download Sample CSV File
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <div className="mt-4">
                <button
                  type="button"
                  onClick={() => formSubmit()}
                  className="btn btn-primary"
                  variant="contained"
                >
                  Submit
                </button>
                <NavLink className={classes.cancelBtn} to="/products/list">
                  <button className="btn btn-primary" variant="contained">
                    Cancel
                  </button>
                </NavLink>
              </div>
            </form>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
}
