import React from 'react'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const headRows = [
    { id: 'first_name', numeric: false, disablePadding: true, label: 'Customer Name' },
    { id: 'organization_id', numeric: false, disablePadding: true, label: 'Company Name' },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
    // { id: 'admin_verified', numeric: false, disablePadding: false, label: 'Admin Approved' },
    { id: 'email_verified', numeric: false, disablePadding: true, label: 'Email Verified' },
    { id: 'mobile_verified', numeric: false, disablePadding: true, label: 'Mobile Verified' },  
    { id: 'key_account_manager_verified', numeric: false, disablePadding: true, label: 'KAM Verified' },  
    { id: 'enginerring_manager__verified', numeric: false, disablePadding: true, label: 'Engineering Manager Verified' },  
    { id: 'action', numeric: true, disablePadding: true, label: 'Action' },
];

  
const EnhancedTableHead = (props) => {

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <>
           <TableHead>
                <TableRow>
                    <TableCell >
                        Sr.No
                    </TableCell>
                    {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'center' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={createSortHandler(row.id)}
                        >
                        {row.label}
                        </TableSortLabel>
                    </TableCell>
                    ))}
                </TableRow> 
            </TableHead>    
        </>
    )
}

export default EnhancedTableHead
