import React,{useState} from 'react'
import CreateFastenerForm from './form';

const CreateFastenerArea = (props) => {

    const [formData, setFormData] = useState({
        name:'',
        application_image:''
    });
    

    return (
        <>
            <CreateFastenerForm data={formData}/>
        </>
    )
}

export default CreateFastenerArea
