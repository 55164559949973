import React,{useEffect,useState,useRef} from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import {API} from '../../../common';
import UseStyles from './UseStyles';
import TextField from '@material-ui/core/TextField'
// import Button from '@material-ui/core/Button';
// import SearchIcon from '@material-ui/icons/Search';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import '../common/CSS/style.css'
import { Container } from "@material-ui/core";

const SearchForm = (props) => {

    const classes = UseStyles();
    const inputLabel = useRef(null);
    const [search, setSearch] = React.useState({
      customer_name: '',
      email: '',
    });
    


    const searchHandleChange = name => event => {
      setSearch({ ...search, [name]: event.target.value });
    };
  
    const formSubmit = async () => {
      props.onSearch(search)
    }


      return(
        <>
      <Container>    
        <form className="d-flex align-items-center mb-6" noValidate autoComplete="off" >
            <TextField
              id="outlined-name"
              label="Name"
              className={classes.textField}
              value={search.customer_name}
              name="customer_name"
              onChange={searchHandleChange('customer_name')}
              margin="dense"
              variant="outlined"
            />


            <TextField
              id="outlined-email"
              label="Email"
              className={classes.textField}
              value={search.email}
              name="email"
              onChange={searchHandleChange('email')}
              margin="dense"
              variant="outlined"
            />
            
              <div className={classes.actionBtn}>
                  <button 
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    Search
                  </button>

                
              </div>
            </form>
          </Container>
        </>
      ); 
  }
  export default SearchForm;
