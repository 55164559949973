import React,{useState} from 'react'
import CreateDataEntryOperatorForm from './form';

const CreateDataEntryOperator = (props) => {

    const [formData, setFormData] = useState({
        first_name:'',
        last_name:'',
        mobile:'',
        email:'',
        type:'data_entry',
        password:'',
        confirm_password:'',
    });
    

    return (
        <>
       <CreateDataEntryOperatorForm data={formData}/>
        </>
    )
}

export default CreateDataEntryOperator