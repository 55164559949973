import { makeStyles } from "@material-ui/core/styles";

const UseStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "20px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    margin: "10px 0px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "97%",
  },
  margin: {
    margin: "15px",
  },
  actionBtn: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "20px 15px",
  },
  cancelBtn: {
    marginLeft: "10px",
  },
  thumbsContainer : {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  
  thumb : {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  
  thumbInner : {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  
  img : {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
}));

export default UseStyles;
