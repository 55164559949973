import React, { useState, useEffect } from "react";
import UseStyles from "./UseStyles";
import axios from "axios";
import { API } from "../../../common";
import { NavLink } from "react-router-dom";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const SearchForm = (props) => {
  const classes = UseStyles();

  const [search, setSearch] = useState({
    fastener_id: "",
    application_area_id: "",
    part_name: "",
    part_number_employee: "",
  });

  const [fasteners, setFasteners] = useState([]);
  const [applicationArea, setApplicationArea] = useState([]);

  useEffect(() => {
    const fastenerPostData = [];
    const fastenerApiUrl = API + "fastenercategories";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchFasterner = async () => {
      await axios
        .post(fastenerApiUrl, fastenerPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setFasteners(responseData.Results);
        });
    };
    fetchFasterner();
  }, []);

  useEffect(() => {
    const applicationAreaPostData = { id: search.fastener_id };
    const applicationAreaApiUrl = API + "applicationareas";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchApplicationArea = async () => {
      await axios
        .post(applicationAreaApiUrl, applicationAreaPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          //console.log(responseData)
          if (responseData.Status === true) {
            setApplicationArea(responseData.Results);
          }
        });
    };
    fetchApplicationArea();
  }, [search.fastener_id]);

  const searchHandleChange = (name) => (event) => {
    setSearch({
      ...search,
      [name]: event.target.value,
    });
  };

  const formSubmit = async () => {
    props.onSearch(search);
  };

  const reset = () => {
    window.location.reload();
  };

  return (
    <>
      <Container className="p-0">
        <form className="" noValidate autoComplete="off">
          <div class="d-flex align-items-center mb-2">
            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Fastener"
              className="form-control mr-1"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              onChange={searchHandleChange("fastener_id")}
              margin="dense"
            >
              <option value=""></option>
              {fasteners.map((fastener, index) => (
                <option key={fastener.id} value={fastener.id}>
                  {fastener.name}
                </option>
              ))}
            </TextField>

            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Application Area"
              className="form-control mr-1"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              onChange={searchHandleChange("application_area_id")}
              margin="dense"
            >
              <option value=""></option>
              {applicationArea.map((applicationArea, index) => (
                <option key={applicationArea.id} value={applicationArea.id}>
                  {applicationArea.name}
                </option>
              ))}
            </TextField>

            <TextField
              id="part_name"
              label="Part Name"
              className="form-control mr-1"
              value={search.part_name}
              onChange={searchHandleChange("part_name")}
              variant="outlined"
              margin="dense"
            />

            <TextField
              id="part_number_employee"
              label="Part Number"
              className="form-control mr-1"
              value={search.part_number_employee}
              onChange={searchHandleChange("part_number_employee")}
              variant="outlined"
              margin="dense"
            />

            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => formSubmit()}
              >
                Search
              </button>
            </div>

            <NavLink className="menu-link" to="/products/list">
              <button
                style={{ margin: 20 }}
                type="reset"
                className="btn btn-outline-primary"
                variant="contained"
                // onClick={() => reset()}
              >
                Reset
              </button>
            </NavLink>
          </div>
          <div className="container justify-contant-end mb-6 px-4">
            <div className="row">
              <NavLink className="menu-link mr-2" to="/products/pending">
                <button
                  variant="contained"
                  className="btn btn-outline-primary font-weight-bold"
                >
                  Pending
                </button>
              </NavLink>

              <NavLink className="menu-link mr-2" to="/products/delete-pending">
                <button
                  variant="contained"
                  className="btn btn-outline-primary font-weight-bold"
                >
                  Delete Pending
                </button>
              </NavLink>

              <NavLink className="menu-link" to="/products/disapproved">
                <button
                  variant="contained"
                  className="btn btn-outline-primary font-weight-bold"
                >
                  Disapproved
                </button>
              </NavLink>
            </div>
            <div></div>
          </div>
        </form>
      </Container>
    </>
  );
};

export default SearchForm;
