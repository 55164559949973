import React, { useState, useEffect, useRef } from "react";
import { withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import { API } from "../../../common";
import TextField from "@material-ui/core/TextField";
import UseStyles from "./UseStyles";
import Paper from "@material-ui/core/Paper";
import "../common/CSS/style.css";
import Validator from "validatorjs";
import Dropzone from "react-dropzone";
import Cropper from "cropperjs";
import "../utils/imagecropper.css";
import Grid from "@material-ui/core/Grid";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const CreateSubCategory = (props) => {
  const imageElement = useRef();
  const classes = UseStyles();
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState({
    name: "",
    subcategory_image: "",
    fastener_category_id: "",
    category_id: "",
    application_area_id: "",
  });
  const [imagePath, setImagePath] = useState();
  const [fasteners, setFasteners] = useState([]);
  const [applicationArea, setApplicationArea] = useState([]);
  const [category, setCategory] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setState(props.data);
  }, [props.data]);

  useEffect(() => {
    setImagePath(props.imagepath);
  }, [props.imagepath]);

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const validateForm = () => {
    let temp = { ...errors };

    let data = {
      name: state.name,
      subcategory_image: state.subcategory_image,
    };

    let rules;

    if (props.data.id) {
      rules = {
        name: "required",
        subcategory_image: "required",
      };
    } else {
      rules = {
        name: "required",
        subcategory_image: "required",
      };
    }

    let validation = new Validator(data, rules, {
      "required.name": "Sub Category Name is required.",
      "required.subcategory_image": "Sub Category Image is required.",
    });

    if (validation.passes()) {
      return true;
    }
    if (validation.fails()) {
      temp.name = validation.errors.first("name");
      temp.subcategory_image = validation.errors.first("subcategory_image");

      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async () => {
    if (validateForm()) {
      setShowLoading(true);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };

      const apiAdd = API + "subcategories/sub_category_add";
      const apiUpdate = API + "subcategories/sub_category_edit";

      if (props.data.id) {
        const updateSubCategory = async () => {
          setShowLoading(true);
          var formData = new FormData();
          formData.append("id", state.id);
          formData.append("subcategory_image", state.subcategory_image);
          formData.append("name", state.name);
          // console.log(formData)
          await axios
            .post(apiUpdate, formData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/sub-category/list",
                });
              }
            });
        };
        updateSubCategory();
      } else {
        const addSubCategory = async () => {
          setShowLoading(true);
          let formData = new FormData();
          formData.append("subcategory_image", state.subcategory_image);
          formData.append("name", state.name);

          await axios
            .post(apiAdd, formData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/sub-category/list",
                });
              }
            });
        };

        addSubCategory();
      }
    }
  };

  const imageFullPath = imagePath + state.subcategory_image;

  const [imageName, SetImageName] = useState("");
  const [imageDestination, SetImageDestination] = useState("");
  const [files, setFiles] = useState([]);

  const onDropfile = (acceptedFiles) => {
    let file = acceptedFiles[0];
    SetImageName(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // reader.onload = function() {
    //   setState({ ...state, ["subcategory_image"]: file });
    // };

    // reader.onerror = function() {
    //   console.log(reader.error);
    // };
    const cropper = new Cropper(imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination(canvas.toDataURL(file.type));
      },
    });
  };

  ///////////////////// base 64 to blob //////////////////////////////////

  useEffect(() => {
    if (imageDestination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob = converterDataURItoBlob(imageDestination);
      if (blob.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function() {
          setState({ ...state, ["subcategory_image"]: blob });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination]);

  /////////////////////////////////////////////////////////////////

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    const fastenerPostData = [];
    const fastenerApiUrl = API + "fastenercategories";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchFasterner = async () => {
      await axios
        .post(fastenerApiUrl, fastenerPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setFasteners(responseData.Results);
        });
    };
    fetchFasterner();
  }, []);

  useEffect(() => {
    const applicationAreaPostData = { id: state.fastener_category_id };
    const applicationAreaApiUrl = API + "applicationareas";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchApplicationArea = async () => {
      await axios
        .post(applicationAreaApiUrl, applicationAreaPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          if (responseData.Status === true) {
            setApplicationArea(responseData.Results);
          }
        });
    };
    fetchApplicationArea();
  }, [state.fastener_category_id]);

  useEffect(() => {
    const CategoryPostData = {
      fastener_category_id: state.fastener_category_id,
      application_area_id: state.application_area_id,
    };
    const CategoryApiUrl = API + "categories";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const Category = async () => {
      await axios
        .post(CategoryApiUrl, CategoryPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          console.log(responseData.Results);
          if (responseData.Status == true) {
            setCategory(responseData.Results);
          }
        });
    };
    Category();
  }, [state.application_area_id]);

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              encType="multipart/form-data"
              noValidate
              autoComplete="off"
            >
              <h2>
                {props.data.id == null
                  ? "Add Sub Category"
                  : "Edit Sub Category"}
              </h2>

              <TextField
                id="outlined-sub_category_name"
                label="Sub Category Name*"
                className={classes.textField}
                value={state.name || ""}
                onChange={handleChange("name")}
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
              />

              <Dropzone onDrop={(acceptedFiles) => onDropfile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone ml-3">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' Drop sub category image Here or click to select
                        <br />
                        (Only 1 Image file)
                        <br />
                        <span>{imageName}</span>
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {errors && errors !== null ? (
                <small className={classes.errorsmall}>
                  {errors.subcategory_image}
                </small>
              ) : (
                ""
              )}

              {files.map((file) => (
                <>
                  <div>
                    <div className="img-container">
                      <img
                        className="img-size"
                        ref={imageElement}
                        src={file.preview}
                        alt="Source"
                        crossorigin
                      />
                    </div>
                    <img
                      src={imageDestination}
                      className="img-preview"
                      alt="Destination"
                    />
                  </div>
                </>
              ))}

              {props.data.id == null ? (
                ""
              ) : (
                <img src={imageFullPath} width="100" />
              )}
              <div className="container">
                <div className="row">
                  <button
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    {props.data.id == null ? "Submit" : "Submit"}
                  </button>
                  <NavLink
                    className={classes.cancelBtn}
                    to="/sub-category/list"
                  >
                    <button
                      className="btn btn-outline-primary"
                      variant="contained"
                    >
                      Cancel
                    </button>
                  </NavLink>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default withRouter(CreateSubCategory);
