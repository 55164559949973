import React from 'react'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const headRows = [
    {
      id: "Key_manager_name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "Key_manager_email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "organisation_name",
      numeric: true,
      disablePadding: false,
      label: "Organisation Name",
    },
    {
      id: "assign_role",
      numeric: true,
      disablePadding: false,
      label: "Assign Role",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },

  ];

  
const KeyManagerEnhancedTableHead = (props) => {

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <>
           <TableHead>
      <TableRow>
      <TableCell >
          Sr.No
      </TableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? "center" : "center"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead> 
        </>
    )
}

export default KeyManagerEnhancedTableHead
