import React,{useState,useEffect} from 'react'
import axios from 'axios';
import {API} from '../../../common';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableHead from './EnhancedTableHead';
import UseStyles from './UseStyles';
import Paper from '@material-ui/core/Paper';
import SearchForm from './SearchForm';
import TableCell from '@material-ui/core/TableCell';
import {NavLink} from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Switch from '@material-ui/core/Switch';
import headLogo from '../common/assets/ITW-logo-small.png'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const DataEntryOperatorList = (props) => {

  const classes = UseStyles();
  const [showLoading, setShowLoading] = useState(true);
  const [rows, setRows] = useState([])
  const [search, setSearch] = useState([])
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const apiUrl = API+"users";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    const fetchData = async() => {
      var postData = {"user_type":'data_entry',"search":search};
      await axios.post(apiUrl,postData,{axiosConfig}).then(response => response.data)
      .then((responseData)=>{
        setShowLoading(false);
        console.log(responseData)    
          setRows( responseData.Results) ;
      });
    };
    fetchData();
  }, [search])
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }
  

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (values) => {
    setSearch(values);
    
  }

  const editDataEntryOperator = (id) =>{
    props.history.push({
      pathname: "/data-entry/edit/" + id
    });
  }

  const deleteDataEntryOperator = (id,index) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token")
      }
    };
    const apiUrlDelete = API + "users/data_entry_delete";
    const postData = { id:id };
    axios.post( apiUrlDelete,postData,{axiosConfig}).then(response => response.data)
    .then((responseData)=>{

      //console.log(responseData);
      
      if(responseData.Status===false)
        {
          alert(responseData.Message);
        }else {
          alert(responseData.Message);
          var updatedData = [...rows];
            updatedData.splice(index, 1);
            setRows(updatedData);
        }
    });
  }




  return (
    <>
     <LoadingOverlay
      active={showLoading}
      spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
     <div className={classes.root}>
     <div className="headLogo">
        <h4>Data Entry Operator</h4>
        <img src={headLogo} alt="headLogo" />
      </div>
      <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
              <div class="card-title">
                  <h3 class="card-label">Data Entry Operator List</h3>
              </div>
              <div class="card-toolbar">
              <NavLink className={classes.cancelBtn} to="/data-entry/create">
              <button 
                className="btn btn-primary"
                variant="contained"
              >
                Add Data Entry Operator
              </button>
            </NavLink>
              </div>
          </div>
          <div class="card-body pb-0">
            <SearchForm onSearch={handleSearch} />
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
              >

                <EnhancedTableHead 
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                  
                    const newPage = page+1;
                    const newIndex = index+1;
                    const srno = newIndex +((newPage-1)*rowsPerPage);
                  
                  return (
                    <TableRow               
                        key={row.id}
                      >
                        <TableCell >
                        
                          {srno}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.first_name} {row.last_name}
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        
                        <TableCell align="left">{row.mobile}</TableCell>
                        <TableCell align="center">
                          <div className="action-btn">
                            <a href onClick={ (e) => {
                              editDataEntryOperator(row.id);
                            }}>
                              <EditIcon className="edit-btn"/>
                            </a>
                            
                            <a href onClick={e =>
                            
                                window.confirm(
                                    "Are you sure you wish to delete this item?"
                                ) && deleteDataEntryOperator(row.id, index)
                                }>
                              <DeleteIcon  className="delete-btn"/>
                            </a>
                          </div>
                        </TableCell>
                      </TableRow>
                        );
                  })}

                    {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                    )}  

                </TableBody>
              </Table>
            </div>
          </div>              
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        
        </div>
      </div>
      </LoadingOverlay>
    </>
  )
}

export default DataEntryOperatorList
