import React, {useState, useEffect } from "react";
import axios from 'axios';
import {API} from '../../../common';
import EditProductVideoForm from './form';

const ProductVideoEdit = (props) => {
    const [formData, setFormData] = useState([]);
    const [imagePath, setImagePath] = useState();
    useEffect(() => {
      const id = props.match.params.id;
      const ApiUrl = API+"products/products_video_details/"+id;
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        const fetchProductVideo = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData)=>{
            setFormData(responseData.Results);
            setImagePath(responseData.ImgPath);
        });
      };
      fetchProductVideo();
    },[]);

    return (
        <>
          { formData && (
            <EditProductVideoForm data={formData} imagepath={imagePath}/>
            )
          }
        </>
    )
}

export default ProductVideoEdit
