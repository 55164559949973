import React,{useState} from 'react'
import CreateProductForm from './form';

const CreateCustomer = (props) => {

    const [formData, setFormData] = useState([]);
    const [productPath, setProductPath] = useState();

    return (
        <>
       <CreateProductForm data={formData} productpath={productPath} />
        </>
    )
}

export default CreateCustomer
