import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { API } from "../../../common";
import axios from "axios";
import SearchForm from "./SearchForm";
import headLogo from "../common/assets/ITW-logo-small.png";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "names",
    numeric: false,
    disablePadding: true,
    label: "Employee/Customer",
  },
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "email", numeric: true, disablePadding: false, label: "Email Name" },
  { id: "part", numeric: false, disablePadding: true, label: "Part Sample" },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Pending/Approved/Disapproved",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell >
            Sr.No
        </TableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? "center" : "center"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h5" id="tableTitle">
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
    
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  actionButton: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  emailVerifyBtn: {
    backgroundColor: "#00ff7f",
    color: "#FFFFFF",
    padding: "10px 20px",
    border: "none",
    outline: "none",
    borderRadius: "50px",
  },
  emailNotVerifyBtn: {
    backgroundColor: "#ffb7c5",
    color: "#FFFFFF",
    padding: "10px 20px",
    border: "none",
    outline: "none",
    borderRadius: "50px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function RequestedSampleList(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [showLoading, setShowLoading] = useState(true);
  const [rows, setRows] = useState([]);
 
  const [search, setSearch] = useState([]);
  const [status, setStatus] = React.useState("");
  const [showPopupAc, setShowPopupAc] = useState(false);

  const handleCloseAc = () => {
    setShowPopupAc(false);
  };

  const handleChange = (event, id) => {

    const apiUrlAc = `${API}requestedsample/update_requested_status`;

            const postData = {
              id: id,
              status: event.target.value,
            };

            axios
              .put(apiUrlAc, postData)
              .then((response) => {
                console.log(response);
                handleCloseAc();
                window.location.reload();
              })
              .catch((error) => setShowLoading(false));
            setStatus(event.target.value);
  };

 

  useEffect(() => {
    const apiUrl = API + "requestedsample";
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      // console.log(token[1]);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token[1],
        },
      };
      console.log(axiosConfig);
      var postData = {
        search: search,
        request_type: "email_drawing",
        user_id:""
      };
      const result = await axios
        .post(apiUrl, postData, axiosConfig)
        .then((response) => response.data)
        .then((responseData) => {
          //console.log(responseData);
          setShowLoading(false);
          setTimeout(setRows(responseData.Results), 5000);
        });
    };
    fetchData();
  }, [search]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  // function handleSelectAllClick(event) {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // }

  // function handleClick(event, name) {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

 
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (values) => {
    setSearch(values);
  };

  // const [switchs, setSwitchs] = useState([]);
  // const setDynamicSwitchState = (list) => {
  //   if (!list) {
  //     return;
  //   }
  //   const switchState = {};

  //   list.forEach((item, index) => {
  //     if (item.admin_verified == "yes") switchs["switch-" + index] = true;
  //     else switchs["switch-" + index] = false;
  //   });
  //   console.log(switchs);
  //   setSwitchs(switchs);
  // };

  

  return (
    <LoadingOverlay
    active={showLoading}
    spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
  >
    <div className={classes.root}>
      <div className="headLogo">
        <h4>Requested More Info</h4>
        <img src={headLogo} alt="headLogo" />
      </div>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <SearchForm onSearch={handleSearch} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size= "medium"
          >
            <EnhancedTableHead
             
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                 // console.log(row);
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const newPage = page+1;
                  const newIndex = index+1;
                  const srno = newIndex +((newPage-1)*rowsPerPage);

                  return (
                    <TableRow
                      hover                  
                      tabIndex={-1}
                      key={srno}
                    >
                       <TableCell >
                       
                       {srno}
                     </TableCell>
                      <TableCell
                        component="th"
                        align="center"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.User.type}
                      </TableCell>
                      <TableCell align="center" id={row.User.id}>
                        {row.User.first_name} {row.User.last_name}
                      </TableCell>
                      <TableCell align="center" id={row.User.id}>
                        {row.User.email}
                      </TableCell>
                      <TableCell align="center" id={row.Product.id}>
                        {row.Product.part_name_employee}
                      </TableCell>
                      <TableCell align="center" id={row.id}>
                        <FormControl
                          className={classes.formControl}
                          id={row.id}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
              
                            id={srno}
                            value={row.status}
                            onChange={(event) => handleChange(event, row.id)}
                          >
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Approved">Approved</MenuItem>
                            <MenuItem value="Disapproved">Disapproved</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
     
    </div>
    </LoadingOverlay>
  );
}
