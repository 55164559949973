import { makeStyles } from '@material-ui/core/styles';
const UseFormStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding:'20px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  margin:{
      margin:'15px'
  },
  actionBtn : {
    display:'flex',
    justifyContent:'space-evenly',
    padding:'20px 15px'
  },
  cancelBtn : {
    marginLeft:'10px'
  },
}));
  
export default UseFormStyles;