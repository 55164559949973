import React,{useEffect,useState,useRef} from 'react'

import axios from 'axios';
import {API} from '../../../common';
import UseStyles from './UseStyles';
import TextField from '@material-ui/core/TextField'
// import Button from '@material-ui/core/Button';
// import SearchIcon from '@material-ui/icons/Search';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import '../common/CSS/style.css'

const SearchForm = (props) => {

    const classes = UseStyles();
    const inputLabel = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [labelWidth, setLabelWidth] = React.useState(0);
    useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    const [searchValues, setSearchValues] = React.useState({
      customer_name: '',
      org_id: '',
      email: '',
    });
    
    useEffect(() => {
      const orgPostData =[]; 
      const orgApiUrl = API+"organizations/organization_list";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token")
        }
      };
      const fetchOrganization = async() => {
      await axios.post(orgApiUrl,orgPostData,{axiosConfig}).then(response => response.data)
      .then((responseData)=>{
        setShowLoading(false);
        setOrganizations( responseData.Results) ;
      });
    };
    fetchOrganization();
    },[]);

    const searchHandleChange = name => event => {
      setSearchValues({ ...searchValues, [name]: event.target.value });
    };
  
    const formSubmit = async () => {
      props.onSearch(searchValues)
    }

    // const addCustomer = () => {
    //   props.history.push({
    //     pathname: "/customers/create",
    //   });
    // };

      return(
        <>
          <div className="d-flex align-items-center mb-6" noValidate autoComplete="off" >
            <TextField
              id="outlined-name"
              label="Customer Name"
              className={clsx(classes.textField, classes.dense)}
              value={searchValues.name}
              name="customer_name"
              onChange={searchHandleChange('customer_name')}
              margin="dense"
              variant="outlined"
            />
             <FormControl variant="outlined" 
              margin="dense" 
              className={classes.formControl, clsx(classes.textField, classes.dense)}
              >
             <InputLabel ref={inputLabel} htmlFor="outlined-org">
                Company
              </InputLabel>
              <Select
              value={searchValues.org_id}
              onChange={searchHandleChange('org_id')}
              input={<OutlinedInput labelWidth={labelWidth}
              name="org_id"
              id = "outlined-org"
               />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                  {organizations.map((org,index)=> (
                    <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                  ))}

              </Select>
            </FormControl>
         
            <TextField
              id="outlined-name"
              label="Email"
              className={clsx(classes.textField, classes.dense)}
              value={searchValues.email}
              name="email"
              onChange={searchHandleChange('email')}
              margin="dense"
              variant="outlined"
            />
              <div className="mt-3">
                  <button 
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    Search
                  </button>

                
              </div>
          </div> 
        </>
      ); 
  }
  export default SearchForm;
