import React, { useState, useEffect } from "react";
import UseStyles from "./UseStyles";
import axios from 'axios';
import {API} from '../../../common';
import Button from '@material-ui/core/Button'

import '../common/CSS/style.css'
import { Container } from "@material-ui/core";

const SearchForm = (props) => {
  const classes = UseStyles();
  const [showLoading, setShowLoading] = useState([])
  const [search, setSearch] = useState({
    customer_name : '',
    email : '',
    mobile : ''
  })

  const searchHandleChange = name => event => {
    setSearch({
      ...search,
      [name] : event.target.value
    })
  }

  const formSubmit = async () => {
    props.onSearch(search)
  }

  return (
    <>
    <Container>
      <form className="employee-form-grid pt-0" noValidate autoComplete="off" >
        <div>
          <input
            type="text"
            className="form-control"
            name="customer_name"
            value={search.customer_name}
            id="employee_name"
            placeholder="Employee Name"
            onChange={searchHandleChange('customer_name')}
          />
        </div>

        <div>
          <input
            type="text"
            className="form-control"
            name="email"
            value={search.email}
            id="email_id"
            placeholder="Email Id"
            onChange={searchHandleChange('email')}
          />
        </div>

        <div>
          <input
            type="text"
            className="form-control"
            name="mobile"
            value={search.mobile}
            id="employee_number"
            placeholder="Employee Number"
            onChange={searchHandleChange('mobile')}
          />
        </div>

        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => formSubmit()}
          >
            Search
          </button>
        </div>
  
        <div>
          
        </div>
      </form>
      </Container>
    </>
  )
}

export default SearchForm
