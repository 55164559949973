import React from "react";
import { NavLink } from "react-router-dom";
import UseStyles from "./UseStyles";
import "../common/CSS/style.css";

const SearchForm = (props) => {
  const classes = UseStyles();

  return (
    <>

      <div className={classes.container}>
        <div className={classes.actionBtn}>
          <NavLink className={classes.cancelBtn} to="/landings/create">
            <button className="btn btn-primary">Create Landing</button>
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default SearchForm;
