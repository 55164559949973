import React, {useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import UseStyles from "./UseStyles";
import TextField from '@material-ui/core/TextField';
import axios from 'axios'
import { API } from '../../../common'

const TermsConnditionForm = () => {

    const classes = UseStyles();
    const[terms, setTerms] = useState('');

    const handleChange = name => event => {
        setTerms(event.target.value );
    };

    useEffect(() => {
        const id = 1;
        const ApiUrl = API+"termscondition";
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: localStorage.getItem("token")
          }
        };
        const fetchTermConditions = async() => {
        await axios.get(ApiUrl,id,{axiosConfig}).then(response => response.data)
        .then((responseData) => {  
            setTerms(responseData.Results.termCondition)       
        });
      };
      fetchTermConditions();
    },[1]);

    const termsSubmit = async (e) => {

        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: localStorage.getItem("token"),
            },
        };

        const apiTermsUpdate= API+"termsCondition/termsCondition_update";        
             const updateTerms =  async() => {
                var postData = {
                    "id"  : 1,
                    "termsCondition": terms,
                };
                await axios.post(apiTermsUpdate,postData,{axiosConfig}).then(response => response.data)
                .then((responseData)=>{
                    if(responseData.Status===false)
                    {
                        alert(responseData.Message);
                    } else  {
                        alert(responseData.Message);
                    }
                });
            } 
            updateTerms();
    }


    return (
        <>
          <div className={classes.settingsDiv}>
                <Paper className={classes.root}>
                    <h4><b>Update Terms and Conditions</b></h4>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows="4"
                        placeholder="Terms and Condition"                        
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={terms || ''}
                        onChange={handleChange('termsCondition')}
                    />

                    <button 
                        type="button" 
                        className="btn btn-primary"
                        onClick={() => termsSubmit()}
                    >
                        Update T&C
                    </button>
                </Paper>
            </div>  
        </>
    )
}

export default TermsConnditionForm
