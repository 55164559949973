import React, { useState, useEffect, useRef } from "react";
import { withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import { API } from "../../../common";
import TextField from "@material-ui/core/TextField";
import UseStyles from "./UseStyles";
import Paper from "@material-ui/core/Paper";
import "../common/CSS/style.css";
import Validator from "validatorjs";
import Dropzone from "react-dropzone";
// import Grid from "@material-ui/core/Grid";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Button from "@material-ui/core/Button";
import Cropper from "cropperjs";
import ImageCropper from "./imagecropper";
import ThemeContext from "./imagecropper";
// import "./imagecropper.css";
import "../utils/imagecropper.css";

const CreateFastener = (props) => {
  const imageElement = useRef();
  const classes = UseStyles();
  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    fastener_image: "",
  });
  const [imagePath, setImagePath] = useState();
  const [cropPath, setCropPath] = useState("");

  useEffect(() => {
    setState(props.data);
  }, [props.data]);

  useEffect(() => {
    setImagePath(props.imagepath);
  }, [props.imagepath]);

  // console.log(props.imagepath)
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const imageFullPath = imagePath + state.fastener_image;

  const validateForm = () => {
    let temp = { ...errors };

    let data = {
      name: state.name,
      fastener_image: state.fastener_image,
    };

    let rules;

    if (props.data.id) {
      rules = {
        name: "required",
        fastener_image: "required",
      };
    } else {
      rules = {
        name: "required",
        fastener_image: "required",
      };
    }

    let validation = new Validator(data, rules, {
      "required.name": "Fastener Name is required.",
      "required.fastener_image": "Fastener Image is required.",
    });

    if (validation.passes()) {
      return true;
    }
    if (validation.fails()) {
      temp.name = validation.errors.first("name");
      temp.fastener_image = validation.errors.first("fastener_image");

      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async () => {
    if (validateForm()) {
      setShowLoading(true);

      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };

      const apiAdd = API + "fastenercategories/fastener_add";
      const apiUpdate = API + "fastenercategories/fastener_edit";

      if (props.data.id) {
        const updateFastener = async () => {
          let formData = new FormData();
          formData.append("id", state.id);
          formData.append("fastener_image", state.fastener_image);
          formData.append("name", state.name);

          await axios
            .post(apiUpdate, formData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/fastener/list",
                });
              }
            });
        };
        updateFastener();
      } else {
        const addFastener = async () => {
          let formData = new FormData();
          formData.append("fastener_image", state.fastener_image);
          formData.append("name", state.name);

          await axios
            .post(apiAdd, formData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/fastener/list",
                });
              }
            });
        };

        addFastener();
      }
    }
  };

  const [imageName, SetImageName] = useState("");
  const [imageDestination, SetImageDestination] = useState("");
  // imageElement = React.createRef();

  const onDropfile = (acceptedFiles) => {
    let file = acceptedFiles[0];
    SetImageName(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // console.log(file);

    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // reader.onload = function() {
    //   setState({ ...state, ["fastener_image"]: file });
    // };

    // reader.onerror = function() {
    //   console.log(reader.error);
    // };

    const cropper = new Cropper(imageElement.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination(canvas.toDataURL(file.type));
        // console.log(canvas.toDataURL("image/png"));

        // setState({
        //   ...state,
        //   ["fastener_image"]: canvas.toDataURL("image/png"),
        // });
      },
    });
  };

  ///////////////////// base 64 to blob //////////////////////////////////

  useEffect(() => {
    if (imageDestination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob = converterDataURItoBlob(imageDestination);
      // blob = imageDestination;
      if (blob.size > 0) {
        let crop = blob;
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        // // console.log(blob);
        console.log(crop);
        reader.onload = function() {
          setState({ ...state, ["fastener_image"]: blob });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination]);
  /////////////////////////////////////////////////////////////////
  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <form
              className={classes.container}
              encType="multipart/form-data"
              noValidate
              autoComplete="off"
            >
              <h2>
                {props.data.id == null ? "Add Fastener" : "Edit Fastener"}
              </h2>

              <TextField
                id="outlined-fastener_name"
                label="Fastener Name*"
                className={classes.textField}
                value={state.name || ""}
                onChange={handleChange("name")}
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
              />

              {/* <div xs={12} lg={12}> */}
              <Dropzone onDrop={(acceptedFiles) => onDropfile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone ml-3 mt-2">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' Drop fastener image Here or click to select
                        <br />
                        (Only 1 Image file)
                        <br />
                        <span>{imageName}</span>
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {errors && errors !== null ? (
                <small className={classes.errorsmall}>
                  {errors.fastener_image}
                </small>
              ) : (
                ""
              )}
              {files.map((file) => (
                <>
                  <div>
                    <div className="img-container">
                      <img
                        className="img-size"
                        ref={imageElement}
                        src={file.preview}
                        alt="Source"
                        crossorigin
                      />
                    </div>
                    <img
                      src={imageDestination}
                      className="img-preview"
                      alt="Destination"
                    />
                  </div>
                </>
                // <ImageCropper
                //   src={file.preview}
                //   imagepath={file.preview}
                // ></ImageCropper>
              ))}
              {/* <ImageCropper src={file.preview}></ImageCropper> */}
              {/* <aside className={classes.thumbsContainer}>{thumbs}</aside> */}

              {props.data.id == null ? (
                ""
              ) : (
                <img src={imageFullPath} width="100" />
              )}
              {/* </div> */}
              <br />

              <div className="container">
                <div className="row mt-3">
                  <button
                    type="button"
                    onClick={() => formSubmit()}
                    className="btn btn-primary"
                    variant="contained"
                  >
                    {props.data.id == null ? "Submit" : "Submit"}
                  </button>
                  <NavLink className={classes.cancelBtn} to="/fastener/list">
                    <button
                      className="btn btn-outline-primary"
                      variant="contained"
                    >
                      Cancel
                    </button>
                  </NavLink>
                </div>
              </div>
            </form>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default withRouter(CreateFastener);
