import React from 'react';

const OurStories = (props) => {
    const mystyle = {
       fontSize:"24px"
     };
     const mystyle1 = {
        lineHeight:"25px"
      };
      const mystyle2 = {
        fontSize:"16px"
      };
    return(
        <>
        
                    <div class="col-md-6 mt-5 mb-5">
                        <div class="blog-style">
                            <div class="row justify-content-between"> 
                                <div class="col-5">
                                    <img src={props.imgsrc} />
                                </div>
                                <div class="col-7 align-self-center">
                                    <h4 style={mystyle}>{props.heading}</h4>
                                    <p style={mystyle1} className="mt-4">{props.paragraph}</p>
                                    <a style={mystyle2}href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                
</>
    )
}


export default OurStories;