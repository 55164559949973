import React,{useState} from 'react'
import CreateApplicationAreaForm from './form';

const CreateApplicationArea = (props) => {

    const [formData, setFormData] = useState({
        name:'',
        application_image:''
    });
    

    return (
        <>
            <CreateApplicationAreaForm data={formData}/>
        </>
    )
}

export default CreateApplicationArea
