import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding:'15px 0px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding:'20px'
  },
  root: {
    width: '90%',
    padding:'40px 0px'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign:'center'
  },
  btnCreate : {
    backgroundColor: '#910039',
    color: '#FFFFFF',
    margin: '5px 0px',
    padding: '8px 30px',
    border: 'none',
  },

  textField : {
      margin: '10px 0px'
  },
  errorsmall : {
    color:'red',
    float:'left'
  },
  btnGroup : {
    display: 'flex',
    justifyContent:'space-around'
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  nextPrexAction : {
    margintop:'20px'
  },
  input: {
    display: 'none',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  actionButton: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  emailVerifyBtn: {
    backgroundColor: "#00ff7f",
    color: "#FFFFFF",
    padding: "10px 20px",
    border: "none",
    outline: "none",
    borderRadius: "50px",
  },
  emailNotVerifyBtn: {
    backgroundColor: "#ffb7c5",
    color: "#FFFFFF",
    padding: "10px 20px",
    border: "none",
    outline: "none",
    borderRadius: "50px",
  },
  editBtn: {
    border: "none",
    backgroundColor: "white",
  },
  deleteBtn: {
    border: "none",
    backgroundColor: "white",
  },
    listroot: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  cancelBtn : {
    marginLeft:'10px'
  },
  thumbsContainer : {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  
  thumb : {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  
  thumbInner : {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  
  img : {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
}));

export default UseStyles
