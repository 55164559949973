import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 2),
    },
    settingsDiv: {
      margin: '20px 0px'
    }
}));

export default UseStyles
