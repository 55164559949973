import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import { Container } from "@material-ui/core";
import { API } from "../../../common";
import axios from "axios";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { NavLink, withRouter } from "react-router-dom";
import headLogo from "../common/assets/ITW-logo-small.png";
import UseFormStyles from "./UseFormStyles";
import Validator from "validatorjs";
import "../common/CSS/style.css";
import PhoneInput from "react-phone-number-input";

const EditEmployee = (props) => {
  const [errors, setErrors] = useState([]);
  const inputLabel = useRef(null);
  const [country, setCountry] = useState();
  const countries = ["IN", "US"];
  const classes = UseFormStyles();
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    setValues(props.data);
  }, [props.data]);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeMobile = (name) => (event) => {
    setValues({ ...values, [name]: event });
  };

  const validateForm = () => {
    let temp = { ...errors };

    let data = {
      first_name: values.first_name,
      last_name: values.last_name,
      mobile: values.mobile,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
    };

    let rules;

    if (props.data.id) {
      rules = {
        first_name: "required",
        last_name: "required",
        mobile: "required|min:10",
        email: "required|email",
      };
    } else {
      rules = {
        first_name: "required",
        last_name: "required",
        mobile: "required|min:10",
        email: "required|email",
        password: "required",
        confirm_password: "required|same:password",
      };
    }

    let validation = new Validator(data, rules, {
      "required.first_name": "First name is required.",
      "required.last_name": "Last name is required.",
      "required.mobile": "Mobile number is required.",
      "required.email": "Email is required.",
      "required.password": "Password is required.",
      "required.confirm_password": "Confirm password is required.",
    });

    if (validation.passes()) {
      return true;
    }
    if (validation.fails()) {
      temp.first_name = validation.errors.first("first_name");
      temp.last_name = validation.errors.first("last_name");
      temp.mobile = validation.errors.first("mobile");
      temp.email = validation.errors.first("email");
      temp.password = validation.errors.first("password");
      temp.confirm_password = validation.errors.first("confirm_password");

      setErrors({
        ...temp,
      });
    }
  };

  const formSubmit = async (e) => {
    if (validateForm()) {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      };
      const apiAdd = API + "users/user_add";
      const apiUpdate = API + "users/user_update";

      if (props.data.id) {
        const updateEmployee = async () => {
          var postData = {
            id: values.id,
            first_name: values.first_name,
            last_name: values.last_name,
            mobile: values.mobile,
            email: values.email,
            password: values.password,
            country_id: "1",
            organization_id: "1",
          };
          await axios
            .post(apiUpdate, postData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/employees/list",
                });
              }
            });
        };
        updateEmployee();
      } else {
        const insertEmployee = async () => {
          var postData = {
            first_name: values.first_name,
            last_name: values.last_name,
            mobile: values.mobile,
            email: values.email,
            password: values.password,
            confirm_password: values.confirm_password,
            country_id: "1",
            organization_id: "1",
            user_type: "employee",
            admin_verified: "yes",
          };
          await axios
            .post(apiAdd, postData, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              //setShowLoading(false);
              //setRows( responseData.results) ;
              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/employees/list",
                });
              }
            });
        };
        insertEmployee();
      }
    }
  };

  var name = "";
  if (typeof props.data.id === "undefined" || props.data.id === null) {
    name = " Add Employee";
  } else {
    name = " Edit Employee";
  }

  return (
    <>
      <div className={classes.root}>
        <div className="headLogo">
          <h4>{name}</h4>
          <img src={headLogo} alt="headLogo" />
        </div>

        <Paper className={classes.paper}>
          <form className={classes.container} noValidate autoComplete="off">
            <h2>{name}</h2>
            <TextField
              id="outlined-first_name"
              label="First Name*"
              className={classes.textField}
              value={values.first_name || ""}
              onChange={handleChange("first_name")}
              margin="normal"
              variant="outlined"
              error={!!errors.first_name}
              helperText={errors.first_name}
            />

            <TextField
              id="outlined-last_name"
              label="Last Name*"
              className={classes.textField}
              value={values.last_name || ""}
              onChange={handleChange("last_name")}
              margin="normal"
              variant="outlined"
              error={!!errors.last_name}
              helperText={errors.last_name}
            />

            {/* <TextField
                id="outlined-mobile"
                label="Mobile Number*"
                value={values.mobile || ''}
                onChange={handleChange('mobile')}
                type="number"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                error={!!errors.mobile}
                helperText={errors.mobile}
            /> */}

            <div class="mt-6 mb-3 input-group ml-3 mr-3">
              {/* <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  +91
                </span>
              </div> */}
              <PhoneInput
                placeholder="Enter phone number (Start with country code)*"
                countries={countries}
                country={country}
                containerStyle={{ width: "100%" }}
                // inputProps={{
                //   required: true,
                //   className: classes.phoneInput, // Apply styles to the container
                // }}
                className="phone-input"
                // country="IN"
                // className="form-control mobileInput"
                margin="normal"
                variant="outlined"
                value={values.mobile || ""}
                onChange={handleChangeMobile("mobile")}
                // error={!!errors.mobile}
                // helperText={errors.mobile}
              />
              {errors && errors !== null ? (
                <small className={classes.errorsmall}>{errors.mobile}</small>
              ) : (
                ""
              )}
            </div>

            {/* <div class="mt-6 mb-3 input-group ml-3 mr-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  +91
                </span>
              </div>
              <Input
                country="IN"
                className="form-control mobileInput"
                margin="normal"
                variant="outlined"
                value={values.mobile || ""}
                onChange={handleChangeMobile("mobile")}
                // error={!!errors.mobile}
                // helperText={errors.mobile}
              />
              {errors && errors !== null ? (
                <small className={classes.errorsmall}>{errors.mobile}</small>
              ) : (
                ""
              )}
            </div> */}

            <TextField
              id="outlined-email"
              label="Email*"
              className={classes.textField}
              value={values.email || ""}
              onChange={handleChange("email")}
              margin="normal"
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email}
            />

            <TextField
              id="outlined-password-input"
              label="Password*"
              className={classes.textField}
              type="password"
              autoComplete="password"
              onChange={handleChange("password")}
              margin="normal"
              variant="outlined"
              // value={values.password || ''}
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              id="outlined-password-input"
              label="Confirm Password*"
              className={classes.textField}
              type="password"
              autoComplete="confirm_password"
              onChange={handleChange("confirm_password")}
              margin="normal"
              variant="outlined"
              // value={values.confirm_password || ''}
              error={!!errors.confirm_password}
              helperText={errors.confirm_password}
            />

            <div className={classes.actionBtn}>
              <button
                // disabled={!validateForm()}
                type="button"
                onClick={() => formSubmit()}
                className="btn btn-primary"
                variant="contained"
              >
                Submit
              </button>

              <NavLink className={classes.cancelBtn} to="/employees/list">
                <button className="btn btn-outline-primary" variant="contained">
                  Cancel
                </button>
              </NavLink>
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(EditEmployee);
