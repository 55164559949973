import React,{useState} from 'react'

import UseStyles from './UseStyles';
import TextField from '@material-ui/core/TextField'
import '../common/CSS/style.css'



const SearchForm = (props) => {

    const classes = UseStyles();
    const [search, setSearch] = React.useState({
      name:'',
    });

    console.log(props.name)
    const searchHandleChange = name => event => {
      setSearch({ ...search, [name]: event.target.value });
    };
  
    const formSubmit = async () => {
      props.onSearch(search)
    }

      return(
        <>
          <div className={classes.container}>
          <TextField
              id="pain_point_name"
              label="Pain Point Name"
              className={classes.textField}
              value={search.name}
              onChange={searchHandleChange('name')}
              variant="outlined"
              margin="dense"
            />
            
            <div className="align-self-center">
            <button 
                  onClick={() => formSubmit()}
                  className="btn btn-primary"
                  variant="contained"
                  style={{margin:'0px 10px'}}
                >
                  Search
                </button>
                
              
            </div>
          </div> 
        </>
      ); 
  }
  export default SearchForm;
