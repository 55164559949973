import React, { useState, useEffect, useRef } from "react";
import { withRouter, NavLink } from "react-router-dom";
import headLogo from "../common/assets/ITW-logo-small.png";
import Paper from "@material-ui/core/Paper";
import UseStyles from "./UseStyles";
import TextField from "@material-ui/core/TextField";
import Dropzone from "react-dropzone";
import axios from "axios";
import Cropper from "cropperjs";
import "../utils/imagecropper.css";
import { API } from "../../../common";
import ReactPlayer from "react-player";
import { RiseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Validator from "validatorjs";

const CreateLanding = (props) => {
  const imageElement = useRef();
  const imageElement1 = useRef();
  console.log(props);
  const classes = UseStyles();
  // const [formData, setFormData] = useState([]);
  const [productPath, setProductPath] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [filePath, setFilePath] = useState();
  // const [product, setProduct] = useState([{
  //     product_id:''
  // }])
  const [product, setProduct] = useState([]);

  const mediaType = ["video", "image", "pdf"];

  const [errors, setErrors] = useState([]);

  const [fileName, setFileName] = useState("");

  const [thumbnail, setThumbnail] = useState("");

  const [formData, setFormData] = useState({
    type: "",
    names: "",
    name: "",
    thumbnail: "",
  });

  useEffect(() => {
    setFormData(props.data);
  }, [props.data]);

  useEffect(() => {
    setFilePath(props.filepath);
  }, [props.filepath]);

  const [files, setFiles] = useState([]);
  const [imageDestination, SetImageDestination] = useState("");

  // if (formData.type == "image") {
  //   console.log("image");
  // } else {
  //   console.log("pdf");
  // }
  const onDropfile = (acceptedFiles) => {
    if (formData.type == "image") {
      let file = acceptedFiles[0];

      setFileName(file.name);
      // let reader = new FileReader();
      // reader.readAsDataURL(file);

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      const cropper = new Cropper(imageElement.current, {
        zoomable: false,
        scalable: false,
        aspectRatio: 1,
        crop: () => {
          const canvas = cropper.getCroppedCanvas();
          SetImageDestination(canvas.toDataURL(file.type));
        },
      });
    } else {
      let file = acceptedFiles[0];

      setFileName(file.name);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      reader.onload = function() {
        setFormData({ ...formData, ["name"]: file });
      };

      reader.onerror = function() {
        console.log(reader.error);
      };
    }
  };

  useEffect(() => {
    if (imageDestination.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob = converterDataURItoBlob(imageDestination);
      if (blob.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function() {
          setFormData({ ...formData, ["name"]: blob });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination]);

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const [filethumb, setFilethumb] = useState([]);
  const [imageDestination1, SetImageDestination1] = useState("");

  const onDropThumbnail = (acceptedFiles) => {
    let file = acceptedFiles[0];

    setThumbnail(file.name);
    // let reader = new FileReader();
    // reader.readAsDataURL(file);

    setFilethumb(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    const cropper = new Cropper(imageElement1.current, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        SetImageDestination1(canvas.toDataURL(file.type));
      },
    });
  };

  useEffect(() => {
    if (imageDestination1.length > 1) {
      function converterDataURItoBlob(imageDestination) {
        let byteString;
        let mimeString;
        let ia;

        if (imageDestination1.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(imageDestination1.split(",")[1]);
        } else {
          byteString = encodeURI(imageDestination1.split(",")[1]);
        }
        // separate out the mime component
        mimeString = imageDestination1
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      }

      let blob1 = converterDataURItoBlob(imageDestination1);
      if (blob1.size > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(blob1);

        reader.onload = function() {
          setFormData({ ...formData, ["thumbnail"]: blob1 });
        };
        reader.onerror = function() {
          console.log(reader.error);
        };
      }
    }
  }, [imageDestination1]);

  const thumb = filethumb.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      filethumb.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [filethumb]
  );

  const handleChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  const validateForm = () => {
    let temp = { ...errors };

    let data = {
      type: formData.type,
      name: formData.name,
      names: formData.names,
      thumbnail: formData.thumbnail,
    };

    let rules;

    if (props.data.id) {
      rules = {
        type: "required",
        name: "required",
        names: "required",
        // thumbnail: "required",
      };
    } else {
      rules = {
        type: "required",
        name: "required",
        names: "required",
        // thumbnail: "required",
      };
    }

    let validation = new Validator(data, rules, {
      "required.type": "Please Select Type",
      "required.name": "Image/Video/PDF is required.",
      "required.names": "Name is required.",
      // "required.thumbnail": "Thumbnail is required.",
    });

    if (validation.passes()) {
      return true;
    }
    if (validation.fails()) {
      temp.type = validation.errors.first("type");
      temp.name = validation.errors.first("name");
      temp.names = validation.errors.first("names");
      // temp.thumbnail = validation.errors.first("thumbnail");

      setErrors({
        ...temp,
      });

      //alert(temp.first_name);
    }
  };

  const formSubmit = async () => {
    if (validateForm()) {
      setShowLoading(true);

      let form_data = new FormData();
      form_data.append("id", formData.id);
      form_data.append("type", formData.type);
      form_data.append("name", formData.name);
      form_data.append("thumbnail", formData.thumbnail);
      form_data.append("names", formData.names);

      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      };

      const apiCreateLanding = API + "landings/landing_create";
      const apiUpdateLanding = API + "landings/landing_update";

      if (props.data.id) {
        const updateLanding = async () => {
          await axios
            .post(apiUpdateLanding, form_data, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);

              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/landings/list",
                });
              }
            });
        };
        updateLanding();
      } else {
        const createLanding = async () => {
          await axios
            .post(apiCreateLanding, form_data, { axiosConfig })
            .then((response) => response.data)
            .then((responseData) => {
              setShowLoading(false);

              if (responseData.Status === false) {
                alert(responseData.Message);
              } else {
                alert(responseData.Message);
                props.history.push({
                  pathname: "/landings/list",
                });
              }
            });
        };
        createLanding();
      }
    }
  };

  let file_url = filePath + formData.name;
  let thumbnail_url = filePath + formData.thumbnail;
  // console.log(file_url)

  useEffect(() => {
    const productPostData = [];
    const productApiUrl = API + "products/product_show_list";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchProduct = async () => {
      await axios
        .post(productApiUrl, productPostData, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          setProduct(responseData.Results);
        });
    };
    fetchProduct();
  }, []);

  var name = "";
  if (typeof props.data.id === "undefined" || props.data.id === null) {
    name = " Add Landing Page";
  } else {
    name = " Edit Landing Page";
  }

  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner={<RiseLoader sizeUnit={"px"} size={80} color={"#910039"} />}
      >
        <div className="headLogo">
          <h4>{name}</h4>
          <img src={headLogo} alt="headLogo" />
        </div>

        <div className={classes.settingsDiv}>
          <Paper className={classes.root}>
            <h4>
              <b>{name}</b>
            </h4>

            <br></br>
            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Type*"
              className={classes.textField}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              value={formData.type || ""}
              onChange={handleChange("type")}
              fullWidth
              error={!!errors.type}
              helperText={errors.type}
            >
              <option value=""></option>
              {mediaType.map((mtype, index) => (
                <option key={mtype} value={mtype}>
                  {mtype}
                </option>
              ))}
            </TextField>
            <br></br>
            <br></br>
            <br></br>

            <TextField
              id="outlined-category_name"
              label="Name*"
              className={classes.textField}
              value={formData.names || ""}
              onChange={handleChange("names")}
              margin="normal"
              variant="outlined"
              error={!!errors.names}
              helperText={errors.names}
              margin="normal"
            />
            {/* <TextField
              id="outlined-select-currency-native"
              select
              label="Select Employee Product Part Name*"
              className={classes.textField}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              value={formData.product_id || ""}
              onChange={handleChange("product_id")}
              fullWidth
              error={!!errors.product_id}
              helperText={errors.product_id}
            >
              <option value=""></option>
              {product.map((product, index) => (
                <option key={product.id} value={product.id}>
                  {product.part_name_employee}
                </option>
              ))}
            </TextField> */}
            <br></br>
            <br></br>
            <br></br>

            <h2 className="ml-3">
              Add{" "}
              {formData.type == "video"
                ? "video"
                : formData.type == "image"
                ? "image"
                : "pdf"}
            </h2>
            <Dropzone
              onDrop={(acceptedFiles) => onDropfile(acceptedFiles)}
              accept="video/*,image/*,.pdf"
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone ml-3">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' Drop File Here or Or click to select
                      <br />
                      <span>{fileName}</span>
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            {errors && errors !== null ? (
              <small className={classes.errorsmall}>{errors.name}</small>
            ) : (
              ""
            )}
            {formData.type && formData.type === "image" ? (
              <div className={classes.thumbsContainer}>
                {files.map((file) => (
                  <>
                    <div>
                      <div className="img-container">
                        <img
                          className="img-size"
                          ref={imageElement}
                          src={file.preview}
                          alt="Source"
                          crossorigin
                        />
                      </div>
                      <img
                        src={imageDestination}
                        className="img-preview"
                        alt="Destination"
                      />
                    </div>
                  </>
                ))}
              </div>
            ) : (
              ""
            )}
            <br></br>

            {/* if type is video */}

            {formData.type && formData.type === "video" ? (
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url={file_url || ""}
                  width="100%"
                  height="100%"
                  controls={true}
                  className="react-player"
                />
              </div>
            ) : (
              ""
            )}

            {/* if type is pdf */}

            {formData.type && formData.type == "pdf" && props.data.id ? (
              <div>
                <a href={file_url} target="_blank">
                  Click here to open PDF
                </a>
              </div>
            ) : (
              ""
            )}

            {/* if type is image */}

            {formData.type && formData.type == "image" ? (
              <div>
                {props.data.id == null ? (
                  ""
                ) : (
                  <img src={file_url} width="300px" />
                )}
              </div>
            ) : (
              ""
            )}

            <br></br>

            {formData.type && formData.type === "video" ? (
              <div>
                <h2>Add Thumbnail</h2>

                <Dropzone
                  onDrop={(acceptedFiles) => onDropThumbnail(acceptedFiles)}
                  accept="image/*"
                  maxFiles={1}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' Drop File Here or Or click to select
                          <br />
                          <span>{thumbnail}</span>
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {errors && errors !== null ? (
                  <small className={classes.errorsmall}>
                    {errors.thumbnail}
                  </small>
                ) : (
                  ""
                )}
                <aside className={classes.thumbsContainer}>
                  {filethumb.map((file) => (
                    <>
                      <div>
                        <div className="img-container">
                          <img
                            className="img-size"
                            ref={imageElement1}
                            src={file.preview}
                            alt="Source"
                            crossorigin
                          />
                        </div>
                        <img
                          src={imageDestination1}
                          className="img-preview"
                          alt="Destination"
                        />
                      </div>
                    </>
                  ))}
                </aside>

                <div>
                  {props.data.id == null ? (
                    ""
                  ) : (
                    <img src={thumbnail_url} width="300px" />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="container">
              <div className="row">
                <button
                  type="button"
                  onClick={() => formSubmit()}
                  className="btn btn-primary"
                  variant="contained"
                >
                  Submit
                </button>
                <NavLink className={classes.cancelBtn} to="/landings/list">
                  <button
                    className="btn btn-outline-primary"
                    variant="contained"
                  >
                    Cancel
                  </button>
                </NavLink>
              </div>
            </div>
          </Paper>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default withRouter(CreateLanding);
