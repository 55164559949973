/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import ListIcon from "@material-ui/icons/List";
import { ErrorPage13 } from "../../../../../app/modules/ErrorsExamples/ErrorPage13";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  let UserType = JSON.parse(localStorage.getItem("data"));
  // console.log(UserType.type)

  if (UserType.type == "data_entry") {
    return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/products/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/products/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-list-ul"></i>
              </span>
              <span className="menu-text">Product List</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          {/* <li
            className={`menu-item ${getMenuItemActive(
              "/request-sample/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/request-sample/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Requested More Info</span>
            </NavLink>
          </li> */}
          {/*end::1 Level*/}
        </ul>
      </>
    );
  } else {
    return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/customers/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/customers/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-list-ul"></i>
              </span>
              <span className="menu-text">Customers List</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/employees/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/employees/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-list-ul"></i>
              </span>
              <span className="menu-text">Employees List</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/data-entry/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/data-entry/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-user-friends"></i>
              </span>
              <span className="menu-text">Data Entry Operator List</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/products/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/products/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-list-ul"></i>
              </span>
              <span className="menu-text">Product List</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/organisation/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/organisation/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-building"></i>
              </span>
              <span className="menu-text">Organisation</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/fastener/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/fastener/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-cogs"></i>
              </span>
              <span className="menu-text">Fastener</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/application-area/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/application-area/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-exchange-alt"></i>
              </span>
              <span className="menu-text">Application Area</span>
            </NavLink>
          </li>

          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/category/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/category/list">
              <span className="svg-icon menu-icon">
                <i class="fab fa-slack"></i>
              </span>
              <span className="menu-text">Category</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/sub-category/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sub-category/list">
              <span className="svg-icon menu-icon">
                <i class="fab fa-slack"></i>
              </span>
              <span className="menu-text">Sub Category</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/pain-point/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/pain-point/list">
              <span className="svg-icon menu-icon">
                <i class="fab fa-product-hunt"></i>
              </span>
              <span className="menu-text">Pain Point List</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/upload-video/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/upload-video/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-file-video"></i>
              </span>
              <span className="menu-text">Upload Product Video</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          {/* <li
            className={`menu-item ${getMenuItemActive(
              "/request-sample/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/request-sample/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Requested More Info</span>
            </NavLink>
          </li> */}
          {/*end::1 Level*/}

          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/contact-us/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/contact-us/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-bullhorn"></i>
              </span>
              <span className="menu-text">Contact Us</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/product-contact-us/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/product-contact-us/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-chalkboard-teacher"></i>
              </span>
              <span className="menu-text">Product Contact Us</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/landings/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/landings/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-magic"></i>
              </span>
              <span className="menu-text">Landings / Invocations</span>
            </NavLink>
          </li>
          {/*begin::1 Level*/}

          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(
              "/product-role/list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/product-role/list">
              <span className="svg-icon menu-icon">
                <i class="fas fa-user-tie"></i>
              </span>
              <span className="menu-text">Product Role</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/settings", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/settings">
              <span className="svg-icon menu-icon">
                <i class="fas fa-cogs"></i>
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </ul>
      </>
    );
  }
}
