import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../common";
import EditCategoryForm from "./form";

const Category = (props) => {
  const [formData, setFormData] = useState([]);
  const [imagePath, setImagePath] = useState();

  console.log(formData);
  useEffect(() => {
    const id = props.match.params.id;
    console.log(id);
    const ApiUrl = API + "categories/" + id;
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    };
    const fetchCategory = async () => {
      await axios
        .get(ApiUrl, id, { axiosConfig })
        .then((response) => response.data)
        .then((responseData) => {
          console.log(responseData);
          setFormData(responseData.Results);
          setImagePath(responseData.ImgPath);
        });
    };
    fetchCategory();
  }, []);

  console.log(imagePath);
  return (
    <>
      {formData && <EditCategoryForm data={formData} imagepath={imagePath} />}
    </>
  );
};

export default Category;
