import React from "react";
import headLogo from "../../pages/common/assets/ITW-logo-big.png";

export function ErrorPage14() {
  return (
    <div className="d-flex flex-column flex-root text-center">
      <div className="my-15">
        <img src={headLogo} />
      </div>
      <div class="">
        <h3>Edit Product Approved Successfully.</h3>
      </div>
    </div>
  );
}
