import React,{useState} from 'react'
import CreateOrganisationForm from './form';

const CreateOrganisation = (props) => {

    const [formData, setFormData] = useState({
        name:'',
        domain:'',
        key_account_manager_name:'',
        key_account_manager_email:'',
        engineer_manager_name:'',
        engineer_manager_email:'',
    });
    

    return (
        <>
            <CreateOrganisationForm data={formData}/>
        </>
    )
}

export default CreateOrganisation
