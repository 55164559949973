import React, { useState, useEffect } from "react";
import UseStyles from "./UseStyles";
import "../common/CSS/style.css";
import { Container } from "@material-ui/core";

const SearchForm = (props) => {
  const classes = UseStyles();
  const [showLoading, setShowLoading] = useState([]);
  const [search, setSearch] = useState({
    customer_name: "",
    email: "",
    part_name: "",
  });

  const searchHandleChange = (name) => (event) => {
    setSearch({
      ...search,
      [name]: event.target.value,
    });
  };

  const formSubmit = async () => {
    props.onSearch(search);
  };

  const Reset = () => {
    window.location.reload();
  };

  return (
    <>
      <Container>
        <form className="employee-form-grid" noValidate autoComplete="off">
          <div>
            <input
              type="text"
              className="form-control"
              name="customer_name"
              value={search.customer_name}
              id="employee_name"
              placeholder="Customer/Employee Name"
              onChange={searchHandleChange("customer_name")}
            />
          </div>

          <div>
            <input
              type="text"
              className="form-control"
              name="email"
              value={search.email}
              id="email_id"
              placeholder="Email Id"
              onChange={searchHandleChange("email")}
            />
          </div>

          <div>
            <input
              type="text"
              className="form-control"
              name="mobile"
              value={search.part_name}
              id="part_name"
              placeholder="Part Sample"
              onChange={searchHandleChange("part_name")}
            />
          </div>

          <div className={classes.actionBtn}>
            <button
              type="button"
              className="btnSearch"
              variant="contained"
              onClick={() => formSubmit()}
            >
              SEARCH
            </button>

            <button
              style={{ margin: 20 }}
              type="reset"
              className="btnSearch"
              variant="contained"
              onClick={() => Reset()}
            >
              RESET
            </button>
          </div>
        </form>
      </Container>
    </>
  );
};

export default SearchForm;
